import { Button, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import BaseComponent from '../../../base';

import SortableTable from '../../../components/sortabletable/SortableTable';
import Backend from '../../../services/Backend';

import ViewContainer from '../../../view';
// import './ScanImportFromFile.module.css';
import { useParams } from 'react-router-dom';
import classes from './ScanImportFromFile.module.css';
const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class ScanImportFromFile extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchStore(props.params.storeId).then((store) => {
			this.setState({
				store: {
					id: store.butik._key,
					name: store.butik.namn,
				},
			});
		});
	}

	state = {
		date: null,
		store: {},
		uploadFile: {},
		loading: false,
		result: {},
		jobStatus: null,
		columns: [
			{ key: 'productId', name: 'ProductId', resposive: false, padding: '1px' },
			{ key: 'gtin', name: 'GTIN', padding: '1px' },
			{ key: 'name', name: 'Namn', resposive: false, padding: '1px' },
			{ key: 'price', name: 'Pris', resposive: false, padding: '1px' },
			{
				key: 'multipriceText',
				name: 'Multipris',
				responsive: false,
				padding: '1px',
			},
			{ key: 'volume', name: 'Volym', resposive: false, padding: '1px' },
			{
				key: 'comparePrice',
				name: 'Jämförelsepris',
				resposive: false,
				padding: '1px',
			},
			{
				key: 'compareUnit',
				name: 'Jämförelse-enhet',
				responsive: false,
				padding: '1px',
			},
			{ key: 'action', name: 'Action', resposive: false, padding: '1px' },
		],
	};

	onDateChanged = (event) => {
		this.setState({
			date: event.target.value,
		});
	};

	onFileChange = (event) => {
		console.log(event);
		const file = event.target.files.length > 0 ? event.target.files[0] : {};
		this.setState({
			uploadFile: file,
			result: {},
		});
	};

	onFileUpload() {
		this.setState({ loading: true });
		const date = this.state.date;

		if (this.state.result.importId) {
			// do actual import
			const payload = {
				storeId: this.state.store.id,
				scanDate: date,
				importId: this.state.result.importId,
				fileName: '-',
				fileContent: '-',
				previewResult: this.state.result,
			};

			Backend.uploadImportFileExecute(payload).then((result) => {
				this.setState({ jobStatus: result.data });

				const interval = setInterval(() => {
					Backend.getJobStatus(result.data.id).then((jobStatus) => {
						this.setState({ jobStatus });
						if (jobStatus.status > 1) {
							const importResult = (jobStatus.result && JSON.parse(jobStatus.result.value)) || this.state.result;
							clearInterval(interval);
							this.setState({
								loading: false,
								result: importResult,
							});
						}
					});
				}, 1000);
			});
		} else {
			// send file for preview
			const reader = new FileReader();
			reader.onloadend = () => {
				const b64 = reader.result.replace(/^data:.+;base64,/, '');
				const payload = {
					storeId: this.state.store.id,
					scanDate: date,
					fileName: this.state.uploadFile.name,
					fileContent: b64,
				};
				Backend.uploadImportFilePreview(payload).then((result) => {
					this.setState({ jobStatus: result.data });

					const interval = setInterval(() => {
						Backend.getJobStatus(result.data.id).then((jobStatus) => {
							this.setState({ jobStatus });
							if (jobStatus.status > 1) {
								// job done or failed
								var previewResult = JSON.parse(jobStatus.result.value);
								if (previewResult.productResults) {
									previewResult.productResults = previewResult.productResults.map((x) => {
										if (x.multiPrice && x.multiCount) {
											x.multipriceText = `${x.multiCount} för ${(x.multiPrice / 100).toFixed(2)} kr`;
										}
										return x;
									});
								}
								clearInterval(interval);
								this.setState({
									loading: false,
									result: JSON.parse(jobStatus.result.value),
								});
							}
						});
					}, 1000);
				});
			};
			reader.readAsDataURL(this.state.uploadFile);
		}
	}

	onCancel() {
		this.setState({
			scanDate: null,
			result: {},
		});
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) {
			return '0 Bytes';
		}

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	getValidationText() {
		if (!this.state.uploadFile.name.endsWith('.xls') && !this.state.uploadFile.name.endsWith('.xlsx')) {
			return 'Filen behöver vara excelformat';
		} else if (!this.state.date) {
			return 'Datum saknas';
		}
		return null;
	}

	getFileData() {
		if (this.state.uploadFile.name) {
			const validationText = this.getValidationText() || '';
			return (
				<div>
					<p>Filnamn: {this.state.uploadFile.name}</p>
					<p>Storlek: {this.formatBytes(this.state.uploadFile.size)}</p>
					<div className={classes.uploadContainer}>
						<Button
							variant="outlined"
							onClick={this.onFileUpload.bind(this)}
							disabled={
								this.state.loading ||
								(this.state.result.productResults && this.state.result.productResults.length > 0) ||
								this.state.result.importExecuted
							}
						>
							Förhandsgranska
						</Button>
						{this.state.result.importId && (
							<Button
								sx={{ marginLeft: '1em' }}
								className={classes.importButton}
								variant="contained"
								onClick={this.onFileUpload.bind(this)}
								disabled={
									this.state.loading ||
									(this.state.result.productResults && this.state.result.productResults.length === 0) ||
									this.state.result.importExecuted
								}
							>
								Importera
							</Button>
						)}
						{this.state.result.importId && (
							<Button
								sx={{ marginLeft: '1em' }}
								variant="outlined"
								className={classes.cancelButton}
								onClick={this.onCancel.bind(this)}
								disabled={this.state.loading || this.state.result.importExecuted}
							>
								Avbryt
							</Button>
						)}
						{this.state.loading && (
							<CircularProgress
								style={{ marginLeft: '1em' }}
								className="loadingIcon"
							/>
						)}
					</div>
					{this.state.jobStatus && this.state.jobStatus.status < 2 && (
						<div style={{ paddingTop: '1em' }}>
							<LinearProgress
								variant="determinate"
								value={this.state.jobStatus.progressPercentage}
							/>
							<p>{this.state.jobStatus.message}</p>
						</div>
					)}
					{this.state.jobStatus && this.state.jobStatus.status === 3 && (
						<div style={{ paddingTop: '1em' }}>
							<p>
								<b>⚠️ ERROR ⚠️</b>
							</p>
							<p>{this.state.jobStatus.message}</p>
						</div>
					)}
					<p className={classes.validationError}>{validationText}</p>
					<p className={classes.validationError}>{this.state.result.message}</p>
					{this.state.result.importExecuted && <h2 className={classes.executedText}>✅ Import genomförd</h2>}
					{this.state.result.productResults && this.state.result.productResults.length > 0 && (
						<SortableTable
							rowHeight={30}
							columns={this.state.columns}
							rows={this.state.result.productResults}
						/>
					)}
				</div>
			);
		}

		return null;
	}
	render() {
		return (
			<ViewContainer
				title="Scanning från fil"
				className={classes.top}
			>
				<div className={classes.storeText}>{this.state.store.name}</div>
				<TextField
					id="date"
					label="Välj datum"
					type="date"
					size="small"
					sx={{ marginTop: '2em' }}
					onChange={this.onDateChanged}
					error={!this.state.date}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<div>
					<Button
						sx={{ marginTop: '1em' }}
						variant="contained"
						component="label"
					>
						Ladda upp fil{' '}
						<input
							type="file"
							onChange={this.onFileChange}
							hidden
						/>
					</Button>
					{/* <div>
						<input
							type="file"
							onChange={this.onFileChange}
						/>
					</div> */}
					{this.getFileData()}
				</div>
			</ViewContainer>
		);
	}
}

export default withRouter(ScanImportFromFile);
