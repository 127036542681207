import Snackbar from '@mui/material/Snackbar';
import dateformat from 'dateformat';
import moment from 'moment';
import React from 'react';
import { Chart } from 'react-google-charts';
import { useParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import SortableTable from '../../../components/sortabletable/SortableTable';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};
class CrawlList extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchCrawls(100).then((data) => {
			const crawls = data.map((crawl) => {
				// Format success procentage to readable format
				const total = crawl.success + crawl.errors;
				// const progress = (crawl.categoriesCrawled + crawl.categoriesUploaded) / (crawl.totalCategories * 2);

				// const procentage = total === 0 || crawl.error ? 0 : crawl.success / total;
				const percentage = crawl.forloppProcent;
				const color = this.getProcentageColor(percentage);
				crawl.procentage = (
					<span style={{ color }}>
						<strong>{percentage.toFixed(2)}%</strong>
						{crawl.error && <div>Krash</div>}
					</span>
				);

				crawl.progress = (
					<span>
						<strong>{percentage.toFixed(2)}%</strong>
					</span>
				);
				// Format runtime string to readable format
				crawl.runtime = '';

				// Get runtime diff
				const runtime = new Date(crawl.updated) - new Date(crawl.created);
				// Calculate hours
				const totalMinutes = runtime / 1000.0 / 60.0;
				const hours = Math.floor(totalMinutes / 60.0);
				if (hours > 0) {
					crawl.runtime = `${hours} timmar `;
				}

				// Remove hours from total number of minutes
				const minutes = Math.round(totalMinutes - hours * 60);
				crawl.runtime += `${minutes} minuter`;

				// Format date
				crawl.created = dateformat(crawl.created, 'yyyy-mm-dd HH:MM:ss');
				return crawl;
			});

			const endDate = moment(data[0].created).endOf('day');
			const startDate = endDate.subtract(1, 'days').startOf('day');
			var chartCrawls = data
				.filter((c) => moment(c.created).isAfter(startDate))
				.map((c) => [c.crawlerName, moment(c.created).toDate(), moment(c.updated).toDate()]);
			this.setState({
				loading: false,
				crawls,
				chartData: {
					rows: chartCrawls,
					start: startDate.toDate(),
					end: endDate.toDate(),
				},
			});
		});
	}

	state = {
		snackbar: '',
		loading: true,
		crawls: [],
		columns: [
			{ key: 'crawlerName', name: 'Namn' },
			{ key: 'argument', name: 'Argument' },
			{ key: 'progress', name: 'Progress' },
			{ key: 'procentage', name: 'Lyckad körning' },
			{ key: 'runtime', name: 'Körtid', resposive: false },
			{ key: 'created', name: 'Skapad', resposive: false },
		],
		chartData: null,
	};

	getProcentageColor(value) {
		// value from 0 to 1
		const hue = ((value / 100) * 120).toString(10);
		return `hsl(${hue}, 100%, 30%)`;
	}

	onClick(crawl) {
		this.redirect(`/crawls/${crawl.id}`);
	}
	columns = [
		{ type: 'string', id: 'Crawl' },
		{ type: 'datetime', id: 'Start' },
		{ type: 'datetime', id: 'End' },
	];

	render() {
		return (
			<ViewContainer
				title="Körningar"
				className="crawlList padding"
			>
				<LoadingContainer enabled={this.state.loading}>
					{this.state.chartData && (
						<div>
							<h2>Körningstider över de 2 senaste dagarna</h2>
							<Chart
								chartType="Timeline"
								data={[this.columns, ...this.state.chartData.rows]}
								width="100%"
								height="600px"
								options={{
									fontName: 'Montserrat',
									hAxis: {
										format: 'yyyy-MM-dd HH:MM',
										minValue: this.state.start,
										maxValue: this.state.end,
									},
									timeline: {
										rowLabelStyle: { fontName: 'Montserrat' },
										barLabelStyle: { fontName: 'Montserrat' },
									},
								}}
							/>
						</div>
					)}
					<h2>Körningar</h2>
					<SortableTable
						columns={this.state.columns}
						rows={this.state.crawls}
						onClick={this.onClick.bind(this)}
					/>
				</LoadingContainer>

				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
			</ViewContainer>
		);
	}
}

export default withRouter(CrawlList);
