import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
// import './CategoryEdit.module.css';
import classes from './CategoryEdit.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class CategoryEdit extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchCategoriesWithParent().then((categories) => {
			const category = categories.find((c) => c._key === props.params.id);
			const catFiltered = categories
				.filter((x) => x.kategoriNiva < 3)
				.map((x) => ({
					id: x._key,
					label: `${x.namn} (nivå ${x.kategoriNiva})`,
				}));
			this.setState({
				loading: false,
				category,
				parentCategory: category.foralderKey && catFiltered.find((x) => x.id === category.foralderKey),
				categories: catFiltered,
			});
		});
	}

	state = {
		category: [],
		parentCategory: false,
		categories: [],
		loading: true,
		snackbar: '',
	};

	onSave() {
		const category = this.state.category;
		const parent = this.state.parentCategory;
		const data = {
			Key: category._key,
			Namn: category.namn,
			Kategoriniva: category.kategoriNiva,
		};

		Backend.saveCategory(data)
			.then(() => {
				if (parent.id !== category.foralderKey) {
					// parent is changed
					return Backend.updateCategoryParent(data.Key, parent.id);
				}
				return Promise.resolve();
			})
			.then(() => {
				this.redirect('/categories');
			})
			.catch(() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen av kategorin',
				});
			});
	}

	setProperty(key, value) {
		const category = this.state.category;
		category[key] = value;
		this.setState({ category });
	}

	setParentCategory(item) {
		if (item.id === this.state.category.id) {
			this.setState({
				snackbar: 'Kan inte ha sig själv som förälder',
			});

			item = null;
		}

		this.setState({
			parentCategory: item,
		});
	}

	render() {
		const category = this.state.category;
		return (
			<ViewContainer title="Ändra kategori">
				<LoadingContainer
					enabled={this.state.loading}
					className={classes.categoryEditWrapper}
				>
					<Box sx={{ height: '80vh', width: '30em' }}>
						<Box sx={{ paddingBottom: '1em' }}>
							<TextField
								fullWidth
								value={category.namn || ''}
								onChange={(evt) => this.setProperty('namn', evt.target.value)}
								label={'Namn'}
								error={!category.namn}
							/>
						</Box>
						<Box>
							<FormControl fullWidth>
								<InputLabel
									shrink
									htmlFor="select-cat-level"
									sx={{
										backgroundColor: 'white',
										paddingRight: '0.5em',
										paddingLeft: '0.5em',
										marginLeft: '-0.5em',
									}}
								>
									Kategorinivå
								</InputLabel>
								<Select
									value={category.kategoriNiva}
									onChange={(evt, index, value) => this.setProperty('kategoriNiva', evt.target.value)}
									inputProps={{
										id: 'select-cat-level',
									}}
								>
									<MenuItem value={1}>Huvudkategori (nivå 1)</MenuItem>
									<MenuItem value={2}>Underkategori (nivå 2)</MenuItem>
									<MenuItem value={3}>Produktgrupp (nivå 3)</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Box>
							<h4 className={classes.h4}>Förälderkategori</h4>
							{this.state.categories && (
								<Autocomplete
									id="combo-box-parent-cat"
									value={this.state.parentCategory}
									options={this.state.categories}
									sx={{ marginBottom: '1em' }}
									onChange={(_, item) => {
										this.setParentCategory(item);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Sök på kategori"
										/>
									)}
								/>
							)}
						</Box>
					</Box>
					<Box className="buttons">
						<Button
							variant="outlined"
							onClick={this.back.bind(this)}
						>
							Avbryt
						</Button>
						<Button
							variant="contained"
							primary="true"
							onClick={this.onSave.bind(this)}
						>
							Spara
						</Button>
					</Box>
				</LoadingContainer>

				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
			</ViewContainer>
		);
	}
}

export default withRouter(CategoryEdit);

/*
<TextField
						value={category.namn || ''}
						onChange={(evt) => this.setProperty('namn', evt.target.value)}
						label={'Namn'}
						error={!category.namn}
					/>
					<FormControl >
						<InputLabel
							shrink
							htmlFor="select-cat-level"
							sx={{
								backgroundColor: 'white',
								paddingRight: '0.5em',
								paddingLeft: '0.5em',
								marginLeft: '-0.5em',
							}}
						>
							Kategorinivå
						</InputLabel>
						<Select
							value={category.kategoriNiva}
							onChange={(evt, index, value) => this.setProperty('kategoriNiva', evt.target.value)}
							inputProps={{
								id: 'select-cat-level',
							}}
						>
							<MenuItem value={1}>Huvudkategori (nivå 1)</MenuItem>
							<MenuItem value={2}>Underkategori (nivå 2)</MenuItem>
							<MenuItem value={3}>Produktgrupp (nivå 3)</MenuItem>
						</Select>
					</FormControl>
					<h4>Förälderkategori</h4>
					{this.state.categories && (
						<Autocomplete
							id="combo-box-parent-cat"
							value={this.state.parentCategory}
							options={this.state.categories}
							sx={{ marginBottom: '1em' }}
							onChange={(_, item) => {
								this.setParentCategory(item);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Sök på kategori"
								/>
							)}
						/>
					)}
					<Box className="buttons">
						<Button
							variant="outlined"
							onClick={this.back.bind(this)}
						>
							Avbryt
						</Button>
						<Button
							variant="contained"
							primary="true"
							onClick={this.onSave.bind(this)}
						>
							Spara
						</Button>
					</Box>
					*/
