import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';

import moment from 'moment';
import { useParams, useSearchParams } from 'react-router-dom';
import BaseComponent from '../../../../base';
import DataGrid from '../../../../components/datagrid/DataGrid';
import Backend from '../../../../services/Backend';
import ViewContainer from '../../../../view';
// import './MappingList.module.css';
import { defaultTblStyle } from '../../../../TableGridCssOverride';
import classes from './MappingList.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	const searchParams = useSearchParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
			searchParams={searchParams[0]}
		/>
	);
};

class CategoryMappingList extends BaseComponent {
	constructor(props) {
		super(props);

		Backend.fetchCrawlers().then((spindlar) => {
			const options = spindlar.map((x) => ({ id: x._key, label: x.namn }));
			this.setState({
				loading: false,
				spindlar: options,
			});

			const crawlerKey = props.searchParams && props.searchParams.get('crawler');
			if (crawlerKey) {
				this.setState({
					selectedCrawler: options.find((x) => x.id === crawlerKey),
				});
				this.getCrawlerCategories(crawlerKey);
			}
		});
	}

	state = {
		loading: true,
		spindlar: [],
		butikkategorier: [],
		selectedCrawler: null,
		columns: [
			{
				id: 'butikkategori',
				name: 'Extern kategori',
				selector: (row) => row.butikkategori,
				wrap: true,
				sortable: true,
			},
			{
				id: 'kategori',
				name: 'Intern kategori',
				selector: (row) => row.kategori,
				width: '30em',
				wrap: true,
				sortable: true,
			},
			{
				id: 'niva',
				name: 'Nivå',
				selector: (row) => row.niva,
				width: '10em',
				wrap: true,
				sortable: true,
			},
			{
				id: 'harUnderkategori',
				name: 'Har Barn',
				width: '10em',
				resposive: false,
				selector: (row) => row.harUnderkategori,
				wrap: true,
				sortable: true,
			},
			{
				id: 'created',
				name: 'Skapad',
				width: '10em',
				resposive: false,
				selector: (row) => row.created,
				wrap: true,
				format: (val) => moment(val).format('YYYY-MM-DD'),
				sortable: true,
			},
		],
	};

	getCrawlerCategories(crawlerId) {
		this.setState(
			{
				loading: true,
				butikkategorier: [],
			},
			() => {
				Backend.fetchCrawlerCategories(crawlerId).then(
					(data) => {
						this.setState({
							loading: false,
							butikkategorier: data
								.sort((a, b) => (a.butikKategori.namn > b.butikKategori.namn ? 1 : -1))
								.map((x) => {
									return {
										_key: x.butikKategori._key,
										butikkategori: x.butikKategori.namn,
										kategori: x.kategori && x.kategori.namn,
										niva: x.kategori && x.kategori.kategoriNiva,
										harUnderkategori: x.butikKategori.harUnderkategori ? 'Ja' : 'Nej',
										created: x.butikKategori.Created,
									};
								}),
						});
					},
					() => {
						this.setState({
							loading: false,
						});
					},
				);
			},
		);
	}

	onClick(butikkategori) {
		this.redirect(`/categories/mapping/${butikkategori._key}`);
	}

	onCrawlerChanged(crawler) {
		this.redirect(`/categories/mapping?crawler=${(crawler && crawler.id) || ''}`);
	}

	render() {
		const count = this.state.butikkategorier.reduce((acc, current) => {
			return acc + (current.kategori ? 1 : 0);
		}, 0);

		return (
			<ViewContainer
				title="Ihopkoppade kategorier"
				className={classes.crawlerListContainer}
			>
				<div className={classes.top}>
					{this.state.spindlar && (
						<Autocomplete
							id="combo-box-crawler"
							value={this.state.selectedCrawler}
							options={this.state.spindlar}
							sx={{ minWidth: '30em' }}
							onChange={(_, item) => {
								this.onCrawlerChanged(item);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Välj spindel"
								/>
							)}
						/>
					)}

					<div className={classes.stats}>
						Ihopkopplade: <strong>{count}</strong>
						<br />
						Totalt antal: <strong>{this.state.butikkategorier.length}</strong>
					</div>
				</div>

				<DataGrid
					title={''}
					columns={this.state.columns}
					data={this.state.butikkategorier}
					onClick={this.onClick.bind(this)}
					loading={this.state.loading}
					customStyles={defaultTblStyle}
					showPagination={false}
					filterKey={['butikkategori', 'kategori']}
				/>

				{/* <LoadingContainer enabled={this.state.loading}>
          <SortableTable columns={this.state.columns} rows={this.state.butikkategorier} onClick={this.onClick.bind(this)} />
        </LoadingContainer> */}
			</ViewContainer>
		);
	}
}

export default withRouter(CategoryMappingList);
