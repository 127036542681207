import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Backend from '../../services/Backend';

function SchedulerSettings(props) {
	const [scheduleTypes, setScheduleTypes] = useState(null);
	const [selectedScheduleType, setSelectedScheduleType] = useState(null);

	useEffect(() => {
		// code to run on component mount
		setSelectedScheduleType(props.value);
		Backend.fetchProductbagScheduleTypes().then((response) => {
			setScheduleTypes(response);
		});
	}, [props.value]);

	const handleChange = (event) => {
		setSelectedScheduleType(event.target.value);
		props.onChange(event.target.value);
	};

	return (
		<>
			<FormControl sx={{ width: '100%' }}>
				<Select
					variant="standard"
					value={selectedScheduleType || 0}
					label="Schema typ"
					onChange={handleChange}
				>
					{scheduleTypes &&
						scheduleTypes.map((job) => {
							return (
								<MenuItem
									key={job.id}
									value={job.id}
								>
									{job.name}
								</MenuItem>
							);
						})}
				</Select>
			</FormControl>
		</>
	);
}

export default SchedulerSettings;
