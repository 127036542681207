import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
import Brands from './Brands';
// import './brandList.module.css';
import classes from './brandList.module.css';

class BrandList extends BaseComponent {
	state = {
		loading: false,
		brands: [],
		selectedBrand: [],
		selectedDistributor: '',
		selectedDistributorKey: [],
		distributorList: [],
		distributor: { key: null, namn: '', mySQLImportId: 0 },
		open: false,
		modalTitle: '',
		snackbar: '',
	};

	constructor(props) {
		super(props);
		this.selectBrand = this.selectBrand.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		if (_.isEmpty(this.state.brands)) {
			this.fetchBrands();
		}
		if (_.isEmpty(this.state.distributorList)) {
			this.fetchAllDistributors();
		}
	}

	componentDidUpdate() {
		if (!_.isEmpty(this.state.selectedBrands) && !_.isEmpty(this.state.selectedDistributor)) {
			this.save();
		}
	}

	fetchAllDistributors = async () => {
		Backend.fetchDistributors().then((distributors) => {
			this.setState({
				distributorList: _.orderBy(distributors, [(item) => item.namn.toLowerCase()], ['asc']),
				loading: false,
			});
		});
	};

	fetchBrands = async () => {
		Backend.fetchVarumarkenWithLeverantorer().then((brands) => {
			// brands.forEach(item => {
			//   item.selected = false;
			// });
			this.setState({
				loading: false,
				brands: _.orderBy(brands, [(item) => item.varumarkeNamn.toLowerCase()], ['asc']),
			});
		});
	};

	save = _.debounce(() => {
		const { distributor } = this.state;
		Backend.createNewDistributor(distributor).then((response) => {
			if (response.success) {
				distributor.key = response.key;
				this.setState({
					open: false,
					distributorList: [this.state.distributorList, distributor],
				});
			}
		});
	});

	selectBrand(brand) {
		this.setState({ selectedBrand: brand, modalTitle: 'Redigera varumärke' });
	}

	onNewClicked() {
		this.setState({ open: true, modalTitle: 'Skapa leverantör' });
	}

	setProperty(target, key, value) {
		target[key] = value;
		this.setState({ brand: target });
	}

	handleClose = () => {
		this.setState({ open: false });
	};

	handleSave = () => {
		this.save();
	};

	render() {
		const { selectedBrand, modalTitle, distributor } = this.state;

		return (
			<ViewContainer
				title="Varumärken"
				className="padding"
			>
				<div className={classes.buttonRight}>
					<Button
						primary="true"
						onClick={this.onNewClicked.bind(this)}
						variant="contained"
					>
						Skapa leverantör
					</Button>
				</div>
				{this.state.open ? (
					<Dialog
						title={modalTitle}
						open={this.state.open}
						onClose={this.handleClose}
					>
						<DialogTitle>Skapa Leverantör</DialogTitle>
						<DialogContent>
							<div>
								<TextField
									value={distributor.namn || ''}
									id="brandname"
									onChange={(evt) => this.setProperty(distributor, 'namn', evt.target.value)}
									label={'Leverantör'}
									errortext={selectedBrand.varumarkeNamn ? '' : 'Namnet får inte vara tomt'}
									variant="standard"
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose}>Cancel</Button>
							<Button
								disabled={_.isEmpty(distributor.namn)}
								onClick={this.handleSave}
								variant="contained"
							>
								Spara
							</Button>{' '}
						</DialogActions>
					</Dialog>
				) : null}
				<LoadingContainer
					enabled={this.state.loading}
					className={classes.brindListHolder}
				>
					{!_.isEmpty(this.state.brands) && !_.isEmpty(this.state.distributorList) ? (
						<Brands
							title="Varumärken"
							data={this.state.brands}
							distributors={this.state.distributorList}
							onSelectBrand={this.selectBrand}
						/>
					) : null}
				</LoadingContainer>
			</ViewContainer>
		);
	}
}

export default BrandList;
