import { Button } from '@mui/material';
import React from 'react';
import BaseComponent from '../../../base';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
// import './clients.module.css';
import { clientsTblStyle } from '../../../TableGridCssOverride';
import DataGrid from '../../../components/datagrid/DataGrid';
import classes from './clients.module.css';
class Clients extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchClients().then((clients) => {
			this.setState({
				loading: false,
				clients,
			});
		});
	}

	state = {
		loading: true,
		clients: [],
		columns: [
			{
				id: '_key',
				name: 'Key',
				resposive: false,
				selector: (row) => row._key,
				sortable: true,
			},
			{ id: 'namn', name: 'Namn', selector: (row) => row.namn, sortable: true },
			{
				id: 'info',
				name: 'Info',
				resposive: false,
				selector: (row) => row.info,
				sortable: true,
			},
		],
	};

	onClick(client) {
		const baseUrl = window.location.pathname;
		this.redirect(`${baseUrl}/${client._key}`);
	}

	onNewClicked() {
		this.redirect('/productmonitoring/clients/new');
	}

	render() {
		return (
			<ViewContainer
				title="Klienter"
				className={classes.padding}
			>
				<div className={classes.buttonRight}>
					<Button
						variant="contained"
						primary="true"
						onClick={this.onNewClicked.bind(this)}
					>
						Skapa ny
					</Button>
				</div>
				<DataGrid
					data={this.state.clients}
					columns={this.state.columns}
					onClick={this.onClick.bind(this)}
					customStyles={clientsTblStyle}
					defaultSortFieldId={'namn'}
					loading={this.state.loading}
				/>
				{/* <LoadingContainer enabled={this.state.loading}>
          <SortableTable columns={this.state.columns} rows={this.state.clients} onClick={(client) => this.onClick(client._key)} />
        </LoadingContainer> */}
			</ViewContainer>
		);
	}
}

export default Clients;
