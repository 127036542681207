import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import React from 'react';
import BaseComponent from '../../../base';
import ProduktGruppChips from '../../../components/categories/ProduktGruppChips';
import CrawlProductList from '../../../components/crawlproductlist/CrawlProductList';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import SearchByManufacturer from '../../../components/manufacturer/SearchManufacturer';
import MarksList from '../../../components/marks/MarksList';
import ResultList from '../../../components/result/ResultList';
import Backend from '../../../services/Backend';
import Utils from '../../../services/Utils';
import ViewContainer from '../../../view';

import { Select, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useParams } from 'react-router-dom';
// import './ProductEdit.module.css';
import classes from './ProductEdit.module.css';
/*
  withRouter is a higher order component that injects the params into the wrapped component
*/
const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class ProductEdit extends BaseComponent {
	static validationRules = {
		gtin: (product) =>
			!isNaN(product.gtin) && product.gtin && (product.gtin.length === 14 || product.gtin.length === 16),
		namn: (product) => product.namn,
		antal: (product) => !isNaN(product.antal) && product.antal > 0,
		mangd: (product) => !isNaN(product.mangd) && product.mangd > 0,
		enhet: (product) => product.enhet > 0,
		// just to make validation check for save button
		categories: (_) => null,
		manufacturer: (_) => null,
	};
	result = null;
	constructor(props) {
		super(props);
		this.debounceTime = 300;
	}
	componentDidMount() {
		this.state = Object.assign(this.state, {
			isCrawlProduct: !!this.props.params.crawlproductid,
		});

		let promise = new Promise((resolve) => resolve({}));

		if (this.state.isCrawlProduct) {
			promise = Backend.fetchCrawlProduct(this.props.params.crawlproductid);
		} else if (this.props.params.id) {
			promise = Backend.fetchProduct(this.props.params.id);
		} else if (this.props.location.query.gtin) {
			const gtin = this.props.location.query.gtin;
			promise = new Promise((resolve) =>
				resolve({
					gtin,
				}),
			);

			setTimeout(() => this.fetchValidoo(gtin));
		}

		promise.then(
			(product) => {
				Backend.fetchCategoriesWithParent().then((categories) => {
					this.setState({
						categories,
					});
				});

				if (this.state.isCrawlProduct && product.butikKey) {
					Backend.fetchStore(product.butikKey).then((store) => {
						this.setState({
							store,
						});
					});

					Backend.fetchCrawlProductCategories(product._key).then((categories) => {
						if (categories && categories.length > 0) {
							const cat = categories.sort(function (a, b) {
								return a.kategoriNiva - b.kategoriNiva;
							})[0];
							product.categories = [cat._key];
							this.setState({
								editCategories: [cat._key],
							});
						}
					});

					product.synlig = true;
				}

				if (!this.state.isCrawlProduct) {
					Backend.fetchProduktVarumarke(product.key).then((varumarke) => {
						this.setState({
							varumarke,
							editVarumarke: varumarke.namn,
						});
					});
				} else {
					this.setState({
						editVarumarke: product.varumarke,
					});
				}

				if (!this.state.isCrawlProduct) {
					this.setState({
						latestCheckedGtin: product.gtin,
						latestUniqueGtin: product.gtin,
					});
					Backend.fetchProductCrawlProducts(product.key, false).then((crawlproducts) => {
						if (crawlproducts && crawlproducts.length > 0) {
							const cps = crawlproducts.map((cp) => {
								const bp = cp.butikProdukt;
								bp.extra = cp.butik.namn;
								cp.SenastSpindlad = cp.senastSedd;
								cp.Produkt = bp;
								return cp;
							});

							this.setState({
								matchedProducts: cps.sort((a, b) => {
									return a.lastCrawled > b.lastCrawled
										? -1 // descending
										: 1;
								}),
							});
						}
					});

					Backend.fetchParentProducts(this.props.params.id).then((products) => {
						this.setState({
							parentProducts: products.map((p) => {
								p._key = p.key;
								return {
									Produkt: p,
									Varumarken: [{ namn: p.varumarke || '' }],
									SenastSpindlad: p.senastSpindlad,
								};
							}),
						});
					});

					Backend.fetchCategoriesByProdukt(product.key).then((cats) => {
						this.setState({
							editCategories: cats.map((c) => c._key),
						});
						product.categories = cats.map((c) => c._key);
					});

					Backend.fetchEgenskaperByProduktKey(product.key).then((marks) => {
						this.setState({
							selectedMarks: marks.map((x) => x.key),
						});
					});
				}

				const searchText = `${product.namn || ''} ${product.gtin || ''}`;
				this.fetchAlternatives(searchText, product.manufacturer);

				this.setState({
					choosenUnit: Utils.getUnitName(product.enhet),
					editProduct: product,
					loading: false,
				});
				if (this.state.isCrawlProduct) {
					this.checkIfGtinIsUnique();
				}
			},
			(err) => {
				console.info(err);
			},
		);
	}

	state = {
		loadingValidoo: false,
		isCrawlProduct: false,
		categories: [],
		varumarke: {},
		loading: true,
		editProduct: {},
		editVarumarke: '',
		store: { butik: {}, kedja: {} },
		stores: [],
		snackbar: '',
		productdeleted: false,
		deletesnackbar: '',
		deleteconfirmeddialog: '',
		uploadstoreimageurldialog: '',
		confirmduplicateGTINdialog: '',
		choosenUnit: null,
		matchAlternatives: [],
		matchAlternativesSearch: '',
		matchAlternativesManufacturer: null,
		allowEmptyManufacturer: false,
		page: 0,
		allResultsFetched: false,
		resultProgressVisible: false,
		matchedProducts: [],
		unmatchedProducts: [],
		latestCheckedGtin: '',
		latestUniqueGtin: '',
		intendedMatch: false,
		intendedMatchKey: null,
		selectedMarks: [],
		matchCandidates: [],
		selectedMatchCandidates: [],
		deleteImageClicked: false,
	};

	validateField(fieldName) {
		const product = this.state.editProduct;
		const allowEmptyManufacturer = this.state.allowEmptyManufacturer;
		const func =
			ProductEdit.validationRules[fieldName] ||
			(() => {
				// empty function
			});
		if (fieldName === 'manufacturer') {
			return this.state.editVarumarke || allowEmptyManufacturer;
		}

		if (fieldName === 'categories') {
			return this.state.editCategories && this.state.editCategories.length > 0;
		}

		if (fieldName === 'enhet') {
			return this.state.choosenUnit && this.state.choosenUnit !== '-';
		}

		return func(product, allowEmptyManufacturer);
	}

	validateUnitField() {
		const unit = Utils.getUnit(this.state.choosenUnit);
		return unit !== null && unit.length === 2;
	}

	isFieldsValid() {
		return (
			this.validateUnitField() &&
			Object.keys(ProductEdit.validationRules).every((x) => {
				const res = this.validateField(x);
				/* console.log('valid', x, res); */ return res;
			})
		);
	}

	fetchAlternatives(searchText, searchManufacturer, page) {
		if (this.state.isCrawlProduct) {
			this.setState(
				{
					matchAlternativesSearch: searchText,
					matchAlternativesManufacturer: searchManufacturer,
					resultProgressVisible: true,
				},
				() => {
					if (searchText || searchManufacturer) {
						Backend.fetchSearchResults(searchText, searchManufacturer, null, null, null, true, page || 1).then(
							(result) => {
								if (result.length === 0 && page) {
									this.setState({
										allResultsFetched: true,
										resultProgressVisible: false,
									});
								} else {
									let matchAlternatives = !page ? [] : this.state.matchAlternatives;
									matchAlternatives = [...matchAlternatives, ...result];
									console.log('Alternatives: ', result);
									this.setState({
										allResultsFetched: false,
										resultProgressVisible: false,
										matchAlternatives: matchAlternatives,
										page: page || 1,
									});
								}
							},
						);
					} else {
						this.setState({
							allResultsFetched: false,
							resultProgressVisible: false,
						});
					}
				},
			);
		}
	}

	fetchValidoo(gtin) {
		this.setState(
			{
				loadingValidoo: true,
			},
			() => {
				Backend.fetchValidooProduct(gtin).then(
					(product) => {
						// If request has been canceled
						if (this.state.loadingValidoo !== true) {
							this.setState({
								loadingValidoo: false,
							});
							return;
						}

						const editProduct = this.state.editProduct || {};
						this.setProductProperty('namn', product.namn || editProduct.namn);
						this.setProductProperty('manufacturer', product.varumarke);

						this.setState({
							loadingValidoo: false,
						});
					},
					() => {
						this.setState({
							loadingValidoo: false,
						});
					},
				);
			},
		);
	}

	fetchValidooImage() {
		this.setState(
			{
				loadingValidoo: true,
			},
			() => {
				Backend.fetchValidooImage(this.state.editProduct.key).then(
					(product) => {
						// If request has been canceled
						if (this.state.loadingValidoo !== true) {
							this.setState({
								loadingValidoo: false,
							});
							return;
						}
						if (product && product.success) {
							Backend.fetchProduct(this.props.params.id).then((pr) => {
								this.setState({
									editProduct: pr,
								});
							});
						}
						this.setState({
							loadingValidoo: false,
						});
					},
					() => {
						this.setState({
							loadingValidoo: false,
						});
					},
				);
			},
		);
	}

	checkIfGtinIsUnique() {
		const gtinToCheck = this.state.editProduct.gtin || '';
		if (this.isGtinStoreType()) {
			this.setState({ latestUniqueGtin: gtinToCheck });
		}
		if ((!this.state.intendedMatch && gtinToCheck && gtinToCheck.length === 14) || gtinToCheck.length === 16) {
			this.setState({ latestCheckedGtin: gtinToCheck });
			Backend.gtinExists(this.state.editProduct.gtin)
				.then((result) => {
					if (result && result === true && this.state.latestCheckedGtin === gtinToCheck) {
						this.setState({ latestUniqueGtin: '-' });
					}
				})
				.catch((errorCode) => {
					if (errorCode === 404 && this.state.latestCheckedGtin === gtinToCheck) {
						this.setState({ latestUniqueGtin: gtinToCheck });
					}
				});
		}
	}

	isGtinUnique() {
		return this.state.editProduct.gtin === this.state.latestUniqueGtin;
	}

	isGtinStoreType() {
		// store type gtins can have duplicates in other stores!
		return (
			this.state.editProduct &&
			this.state.editProduct.gtin &&
			this.state.editProduct.gtin.length === 14 &&
			this.state.editProduct.gtin.startsWith('02')
		);
	}

	getGtinErrorText() {
		if (!this.validateField('gtin')) {
			return 'Måste vara ett 14 eller 16-siffrigt nummer';
		}
		if (!this.state.intendedMatch && !this.isGtinUnique() && !this.isGtinStoreType()) {
			return 'GTIN är ej unikt';
		}
		if (!this.isGtinUnique() && this.isGtinStoreType()) {
			return 'GTIN är ett icke-unikt internt GTIN';
		}
	}

	handleProductSelected(product) {
		// TODO
		const editProduct = this.state.editProduct;
		editProduct.gtin = product.Produkt.gtin;
		const varumarke = product.Varumarken && product.Varumarken.length > 0 ? product.Varumarken[0].namn : '';
		const kategori =
			(product.ProduktGrupp && product.ProduktGrupp._key) ||
			(product.Kategori && product.Kategori._key) ||
			(product.HuvudKategori && product.HuvudKategori._key) ||
			'';
		editProduct.antal = product.Produkt.antal;
		editProduct.mangd = product.Produkt.mangd;
		editProduct.enhet = product.Produkt.enhet;
		editProduct.synlig = product.Produkt.synlig;
		this.setState({
			editProduct,
			intendedMatch: true,
			intendedMatchKey: product.Produkt._key,
			editCategories: [kategori],
			editVarumarke: varumarke,
			choosenUnit: Utils.getUnitName(editProduct.enhet),
		});
	}

	handleParentSelected(product) {
		switch (product.Produkt.produktTyp) {
			case 1:
				this.redirect(`/products/${product.Produkt._key}`);
				break;
			case 2:
				this.redirect(`/products/duplicates/${product.Produkt._key}`);
				break;
			case 3:
				this.redirect(`/products/combine/${product.Produkt._key}`);
				break;
			default: // noop
		}
	}

	onScrollToBottom() {
		if (!this.state.allResultsFetched) {
			this.debounce(this.fetchAlternatives.bind(this), this.debounceTime)(
				this.state.matchAlternativesSearch,
				this.state.matchAlternativesManufacturer,
				this.state.page + 1,
			);
		}
	}

	onCancelClick() {
		this.back();
	}

	onSaveClick() {
		const ignoreManufacturer = this.state.allowEmptyManufacturer;
		const product = this.state.editProduct;
		product.manufacturer = ignoreManufacturer ? '' : product.manufacturer;

		const unit = Utils.getUnit(this.state.choosenUnit);
		if (!this.isFieldsValid() && unit !== null && unit.length === 2) {
			this.setState({
				snackbar: 'Rödmarkerade fälten måste vara ifyllda.',
			});

			return;
		}

		if (
			this.state.isCrawlProduct &&
			!this.isGtinUnique() &&
			this.isGtinStoreType() &&
			!this.state.intendedMatch &&
			!this.state.confirmduplicateGTINdialog
		) {
			this.setState({
				confirmduplicateGTINdialog: 'GTIN är ett icke-unikt internt GTIN. Vill du fortsätta?',
			});
			return;
		} else if (this.state.isCrawlProduct && this.state.confirmduplicateGTINdialog) {
			this.setState({ confirmduplicateGTINdialog: '' });
		}

		const payload = {
			GTIN: product.gtin,
			Namn: product.namn,
			Produktinfo: product.produktinfo,
			VarumarkeNamn: ignoreManufacturer ? '' : this.state.editVarumarke,
			Varumarke: ignoreManufacturer ? '' : this.state.editVarumarke,
			Enhet: unit[0],
			Antal: product.antal,
			Mangd: product.mangd * unit[1],
			MinstaDelmangd: product.minstaDelmangd,
			Kategorier: this.state.editCategories, // todo
			IntendedMatch: this.state.intendedMatch,
			MatchProduktKey: this.state.intendedMatchKey,
			Synlig: product.synlig,
			BildUrl: product.bildUrl,
			Egenskaper: this.state.selectedMarks,
			Verifierad: product.Verifierad || moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
			VisaSomJmfPris:
				!this.state.isCrawlProduct ||
				(this.state.isCrawlProduct && !this.state.intendedMatchKey && !this.state.editProduct.produktKey)
					? null
					: product.visaSomJmfPris,
		};

		let promise;
		if (this.state.isCrawlProduct) {
			promise = Backend.saveCrawlProduct(product._key, payload);
		} else {
			promise = Backend.saveProduct(this.props.params.id, payload);
		}

		this.setState({
			loading: true,
		});

		Promise.all([
			...this.state.unmatchedProducts.map((id) => {
				return Backend.unmatch(id);
			}),
			promise,
		])
			.then((response) => {
				if (this.props.params.success) {
					this.redirect(this.props.params.success.replace('{id}', response.id));
				} else {
					this.setState({
						snackbar: 'Produkt sparad!',
					});
				}

				const product = this.state.editProduct;
				const newState = { loading: false, editProduct: product };
				product.produktBild = response[0].produktBild;
				product.produktBildThumbnail = response[0].produktBildThumbnail;
				var withCandidates = response.filter((x) => x.candidates);
				if (withCandidates.length > 0) {
					newState.matchCandidates = withCandidates[0].candidates;
					newState.intendedMatchKey = withCandidates[0].produktResult._key;
				}

				this.setState({ ...newState });
			})
			.catch(() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen av produkten',
				});
			});
	}

	onDeleteClick() {
		this.setState({
			deletesnackbar: 'Ta bort produkt "' + [this.state.editProduct.namn] + '"?',
		});
		return;
	}

	onCancelDeleteClick() {
		this.setState({ deletesnackbar: '' });
	}

	onConfirmDeleteClick() {
		let promise;
		promise = Backend.deleteProduct(this.props.params.id);
		promise
			.then((result) => {
				this.setState({
					deletesnackbar: '',
					deleteconfirmeddialog: 'Produkten är borttagen.',
					productdeleted: true,
				});
			})
			.catch((error) => {
				this.setState({
					deletesnackbar: '',
					deleteconfirmeddialog: 'Produkten kunde inte tas bort.',
					productdeleted: false,
				});
				console.log('error: ' + error);
			});
	}

	onConfirmedDeleteProductClick() {
		this.setState({ deleteconfirmeddialog: '' });
		if (this.state.productdeleted) {
			this.back();
		}
	}

	setProductProperty(property, value) {
		if (property === 'manufacturer') {
			this.setState({ editVarumarke: value });
		}

		if (property === 'categories') {
			this.setState({ editCategories: value });
		}
		const editProduct = this.state.editProduct;
		editProduct[property] = value;

		this.setState({ editProduct });
	}

	onCheckedboxChangedManufacturer(checked) {
		this.setState({
			allowEmptyManufacturer: checked.target.checked,
		});
	}

	onCheckedboxChangeVisible(checked) {
		const product = this.state.editProduct;
		product.synlig = checked.target.checked;
		this.setState({
			editProduct: product,
		});
	}

	onCheckedboxChangeJmfPris(checked) {
		this.setState((state) => ({
			editProduct: {
				...state.editProduct,
				visaSomJmfPris: checked,
			},
		}));
	}

	onSourcesClick() {
		this.redirect(`/products/${this.props.params.id}/sources`);
	}

	openProductSource(product, store) {
		if (this.hasProductSource(product, store)) {
			let sourceUrl = this.getProductSource(store.kedja.namn) || '';
			if (store.kedja.namn.toLowerCase().startsWith('ica')) {
				fetch(
					'https://handla.ica.se/api/store/v1?customerType=B2C',
					{ cache: 'force-cache' },
					{ next: { revalidate: 3600 } },
				)
					.then((response) => response.json())
					.then((data) => {
						// console.log('%c⧭', 'color: #994d75', data);
						let accountId = data.filter((x) => x['id'] === store.butik.externId)[0].accountId;
						sourceUrl = sourceUrl.replace('{productId}', product.externId.replace(/([P_]|[//d].*)/g, ''));
						sourceUrl = sourceUrl.replace('{storeId}', accountId);
						sourceUrl = sourceUrl.replace('{productName}', product.namn);
						sourceUrl = sourceUrl.replace('{productNameSlug}', Utils.slugify(product.namn));

						console.log('%c⧭', 'color: #364cd9', sourceUrl);
						if (sourceUrl) {
							window.open(sourceUrl, '_blank');
						} else {
							this.setState({
								snackbar: 'Kunde inte öppna källa',
							});
						}
					});
			} else {
				sourceUrl = sourceUrl.replace('{productId}', product.externId);
				sourceUrl = sourceUrl.replace('{storeId}', store.butik.externId);
				sourceUrl = sourceUrl.replace('{productName}', product.namn);
				sourceUrl = sourceUrl.replace('{productNameSlug}', Utils.slugify(product.namn));
				if (sourceUrl) {
					window.open(sourceUrl, '_blank');
				} else {
					this.setState({
						snackbar: 'Kunde inte öppna källa',
					});
				}
			}
		}
	}

	hasProductSource(product, store) {
		return this.state.isCrawlProduct && store.butik._key && this.getProductSource(store.kedja.namn);
	}

	getProductSource(kedjaNamn) {
		if (kedjaNamn.toLowerCase().startsWith('ica')) {
			return 'https://handlaprivatkund.ica.se/stores/{storeId}/products/{productId}/';
		}
		if (kedjaNamn.toLowerCase().startsWith('hemköp')) {
			return 'https://www.hemkop.se/produkt/--{productId}';
		}
		if (kedjaNamn.toLowerCase().startsWith('willys')) {
			return 'https://www.willys.se/produkt/--{productId}';
		}
		if (kedjaNamn.toLowerCase().startsWith('mat.se')) {
			return 'https://www.mat.se/search.html?q={productId}';
		}
		if (kedjaNamn.toLowerCase().startsWith('mathem')) {
			return 'https://www.mathem.se/sok?q={productName}';
		}
		if (kedjaNamn.toLowerCase().startsWith('rusta')) {
			return 'https://www.rusta.com/se/{productNameSlug}-{productId}.aspx';
		}

		return null;
	}

	onSelectedMatchCandidatesChange(selected) {
		this.setState({ selectedMatchCandidates: selected });
	}

	renderProduct() {
		const product = this.state.editProduct;
		if (!product) {
			return null;
		}
		const store = this.state.store;
		const allowEmptyManufacturer = this.state.allowEmptyManufacturer;
		const isVisible = this.state.editProduct.synlig;

		return (
			<>
				<Box className={classes.gtinField}>
					<TextField
						sx={{ marginBottom: '1em' }}
						value={product.gtin || ''}
						onChange={(evt) => {
							if (this.state.intendedMatch) {
								this.setState({ intendedMatch: false });
							}
							this.setProductProperty('gtin', evt.target.value);
							this.checkIfGtinIsUnique();
						}}
						label={'GTIN'}
						variant="standard"
						error={!!this.getGtinErrorText()}
						helperText={this.getGtinErrorText()}
					/>
					<Button
						className={classes.button}
						variant="outlined"
						onClick={() => this.fetchValidoo(product.gtin)}
						disabled={!this.validateField('gtin')}
					>
						Validoo
					</Button>
				</Box>
				<>
					{!!store.butik._key && (
						<Box className={classes.textfields}>
							<Box>
								<Box className={classes.gtinField}>
									<TextField
										value={store.butik.namn || ''}
										label={'Butik'}
										variant="standard"
										disabled
										sx={{ marginBottom: '1em' }}
									/>
									{this.hasProductSource(product, store) && (
										<Button
											className={classes.button}
											variant="outlined"
											onClick={() => this.openProductSource(product, store)}
										>
											Källa
										</Button>
									)}
								</Box>
							</Box>
						</Box>
					)}
				</>
				<>
					{!this.state.isCrawlProduct && (
						<Box style={{ display: 'flex' }}>
							<Box
								style={{
									width: '80px',
									height: '80px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<a
									href={(product.produktBild && product.produktBild.url) || product.bildUrl || ''}
									target="_blank"
									rel="noreferrer"
								>
									<Avatar
										style={{
											borderRadius: 'none',
											backgroundColor: 'rgba(255,255,255,0)',
											maxWidth: '80px',
											maxHeight: '80px',
											filter: 'contrast(1.25)',
											imageRendering: '-webkit-optimize-contrast',
										}}
										onError={(e) => {
											e.target.src =
												'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
										}}
										src={(product.produktBildThumbnail && product.produktBildThumbnail.url) || product.bildUrl || ''}
										size={-1}
									/>
								</a>
							</Box>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginLeft: '2em',
									alignItems: 'left',
								}}
							>
								<span>Källa: {(product.produktBildThumbnail && product.produktBildThumbnail.origin) || 'okänd'}</span>
								<div>
									<Button
										style={{ height: '2em', lineHeight: 'inherit' }}
										variant="outlined"
										primary="true"
										onClick={() => this.fetchValidooImage()}
									>
										Hämta bild
									</Button>
									{product.produktBildThumbnail && (
										<IconButton
											color={this.state.deleteImageClicked ? 'primary' : 'secondary'}
											sx={{ marginLeft: '1em' }}
											onClick={() => {
												if (!this.state.deleteImageClicked) {
													this.setState({ deleteImageClicked: true });
												} else {
													Backend.deleteProductImage(product.key).then((result) => {
														if (result.success) {
															Backend.fetchProduct(this.props.params.id).then((pr) => {
																this.setState({
																	editProduct: pr,
																	deleteImageClicked: false,
																});
															});
														}
													});
												}
											}}
										>
											<DeleteIcon />
										</IconButton>
									)}
								</div>
							</Box>
						</Box>
					)}
				</>
				<Box className={classes.textfields}>
					<TextField
						value={product.namn || ''}
						sx={{ marginBottom: '1em' }}
						fullWidth={true}
						onChange={(evt) => this.setProductProperty('namn', evt.target.value)}
						label={'Namn'}
						variant="standard"
						helperText={this.validateField('namn') ? '' : 'Namnet får inte vara tomt'}
						error={!this.validateField('namn')}
					/>
					<TextField
						value={product.produktinfo || ''}
						sx={{ marginBottom: '1em' }}
						fullWidth={true}
						variant="standard"
						onChange={(evt) => this.setProductProperty('produktinfo', evt.target.value)}
						label={'Produktinfo'}
					/>
					{this.state.isCrawlProduct && (
						<TextField
							sx={{ marginY: '1em' }}
							value={product.bildUrl || ''}
							variant="standard"
							fullWidth={true}
							onChange={(evt) => this.setProductProperty('bildUrl', evt.target.value)}
							label={'Bild url'}
						/>
					)}
					<SearchByManufacturer
						value={allowEmptyManufacturer ? '' : this.state.editVarumarke || ''}
						onChange={(value) => this.setProductProperty('manufacturer', value)}
						disabled={allowEmptyManufacturer}
						width="100%"
					/>
					{!this.validateField('manufacturer') && (
						<Typography sx={{ color: 'red' }}>Varumärke får inte vara tomt</Typography>
					)}
				</Box>

				<Box sx={{ width: '100%', marginY: '1em	' }}>
					<FormGroup sx={{ display: 'inline' }}>
						<FormControlLabel
							label="Inget varumärke"
							control={
								<Checkbox
									onChange={this.onCheckedboxChangedManufacturer.bind(this)}
									checked={allowEmptyManufacturer}
								/>
							}
						/>
						<FormControlLabel
							label="Synlig"
							control={
								<Checkbox
									onChange={this.onCheckedboxChangeVisible.bind(this)}
									checked={isVisible}
								/>
							}
						/>
						{(!this.state.isCrawlProduct ||
							(this.state.isCrawlProduct && !this.state.intendedMatchKey && !this.state.editProduct.produktKey)) && (
							<FormControlLabel
								control={
									<Checkbox
										onChange={(_, checked) => this.onCheckedboxChangeJmfPris(checked)}
										checked={this.state.editProduct.visaSomJmfPris}
									/>
								}
								label="Visa som Jmf-pris"
							/>
						)}
					</FormGroup>
				</Box>
				<br />

				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
					<Box className={classes.textfields}>
						<Box>
							{this.state.categories.length > 0 && (
								<ProduktGruppChips
									onChange={(val) => this.setProductProperty('categories', val)}
									errorText={this.validateField('categories') ? '' : 'Produktgrupp kan inte lämnas tomt'}
									value={this.state.editCategories}
									categories={this.state.categories}
								></ProduktGruppChips>
							)}
						</Box>
						<TextField
							sx={{ marginBottom: '1em' }}
							type="number"
							variant="standard"
							value={`${product.antal || ''}`}
							onChange={(evt) => this.setProductProperty('antal', evt.target.value)}
							label={'Antal'}
							helperText={this.validateField('antal') ? '' : 'Måste vara ett nummer större än 0'}
							error={!this.validateField('antal')}
						/>
						<TextField
							sx={{ marginBottom: '1em' }}
							type="number"
							variant="standard"
							value={`${product.mangd || ''}`}
							onChange={(evt) => this.setProductProperty('mangd', evt.target.value)}
							label={'Mängd'}
							error={!this.validateField('mangd')}
							helperText={this.validateField('mangd') ? '' : 'Måste vara ett nummer större än 0'}
						/>

						<Select
							label={'Enhet'}
							variant="standard"
							value={this.state.choosenUnit || '-'}
							onChange={(evt, i, choosenUnit) => {
								this.setState({ choosenUnit: evt.target.value });
							}}
							error={!this.validateUnitField()}
						>
							<MenuItem value={'-'}>-</MenuItem>
							{Utils.getUnitNames().map((name) => (
								<MenuItem
									key={name}
									value={name}
								>
									{name}
								</MenuItem>
							))}
						</Select>
					</Box>
					<Box sx={{ display: 'flex', width: '100%' }}>
						{!this.state.isCrawlProduct && (
							<MarksList
								sx={{ marginLeft: '1em' }}
								onChange={(key, selected) => {
									const marks = [...this.state.selectedMarks];
									if (selected && !marks.includes(key)) {
										marks.push(key);
									} else if (!selected && marks.includes(key)) {
										marks.splice(marks.indexOf(key), 1);
									}
									this.setState({ selectedMarks: marks });
								}}
								selectedMarks={this.state.selectedMarks}
							/>
						)}
					</Box>
				</Box>
			</>
		);
	}

	renderMatchingProducts() {
		if (!this.props.params.crawlproductid) {
			return <Box />;
		}

		return (
			<Box className={classes.textfields}>
				<h4>Sök produkter</h4>
				<TextField
					value={this.state.matchAlternativesSearch || ''}
					onChange={(evt) => this.fetchAlternatives(evt.target.value, this.state.matchAlternativesManufacturer)}
					label={'Fritext'}
					variant="standard"
				/>
				<br />
				<TextField
					value={this.state.matchAlternativesManufacturer || ''}
					onChange={(evt) => this.fetchAlternatives(this.state.matchAlternativesSearch, evt.target.value)}
					label={'Varumärke'}
					variant="standard"
				/>
				<ResultList
					products={this.state.matchAlternatives}
					progressVisible={false}
					handleProductSelected={this.handleProductSelected.bind(this)}
					emptyStateText="Inga resultat hittades"
				/>
			</Box>
		);
	}

	render() {
		const store = this.state.store;

		return (
			<ViewContainer title={this.props.params.id && !this.state.isCrawlProduct ? 'Ändra produkt' : 'Ny produkt'}>
				<Box
					sx={{
						display: { sm: 'flex-inline', md: 'flex', lg: 'flex', xl: 'flex' },
						height: { sm: 'auto', md: '100%', lg: '100%', xl: '100%' },
						flexDirection: 'row',
						width: { sm: '100%', md: '100%', lg: '100%', xl: '100%' },
						// background: { xs: 'red', md: 'green', lg: 'blue', xl: 'yellow' },
					}}
				>
					{this.state.loadingValidoo && (
						<Box className={classes.validoo}>
							<p>Hämtar data från validoo, kan ta en stund...</p>
							<Button onClick={() => this.setState({ loadingValidoo: false })}>Avbryt</Button>
						</Box>
					)}
					{/* sx={{ background: { xs: 'red', md: 'green', lg: 'blue' } }} */}
					<LoadingContainer
						enabled={this.state.loadingValidoo || this.state.loading}
						className={classes.productContainer}
					>
						<Box
							className={classes.top}
							// sx={{
							// 	background: { sm: 'red', md: 'green', lg: 'yellow', xl: 'pink' },
							// }}
						>
							{' '}
							{this.renderProduct()}
						</Box>
					</LoadingContainer>
					<Snackbar
						open={!!this.state.snackbar}
						message={this.state.snackbar}
						autoHideDuration={3000}
						onClose={() => this.setState({ snackbar: '' })}
					/>
					<Dialog
						open={!!this.state.deletesnackbar}
						onClose={() => this.setState({ snackbar: '' })}
					>
						<DialogTitle>{this.state.deletesnackbar}</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={this.onCancelDeleteClick.bind(this)}
							>
								Avbryt
							</Button>
							<Button onClick={this.onConfirmDeleteClick.bind(this)}>Ta bort</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={!!this.state.deleteconfirmeddialog}
						onClose={() => this.setState({ snackbar: '' })}
					>
						<DialogTitle>{this.state.deleteconfirmeddialog}</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={this.onConfirmedDeleteProductClick.bind(this)}
							>
								Ok
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={!!this.state.confirmduplicateGTINdialog}
						onClose={() => this.setState({ confirmduplicateGTINdialog: '' })}
					>
						<DialogTitle>{this.state.confirmduplicateGTINdialog}</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={this.onSaveClick.bind(this)}
							>
								Ok
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={!!this.state.uploadstoreimageurldialog}
						onClose={() => this.setState({ uploadstoreimageurldialog: '' })}
					>
						<DialogTitle>Ladda upp butikens bild till produkten?</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={() => {
									const product = this.state.editProduct;
									product.bildUrl = this.state.uploadstoreimageurldialog;
									this.setState({ editProduct: product, uploadstoreimageurldialog: '' });
									this.onSaveClick();
								}}
							>
								Ok
							</Button>
						</DialogActions>
					</Dialog>
					<Modal
						open={this.state.matchCandidates.length > 0 && !this.state.loading}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: '80vw',
								bgcolor: 'background.paper',
								boxShadow: 24,
								p: 4,
							}}
						>
							<CrawlProductList
								title={'Möjliga ytterligare matchningar'}
								butikProdukter={this.state.matchCandidates}
								preSelectAll={true}
								onChange={this.onSelectedMatchCandidatesChange.bind(this)}
							/>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									paddingTop: '2em',
									gap: '1em',
									alignItems: 'center',
									justifyContent: 'flex-end',
								}}
							>
								<Typography>
									Antal butikprodukter: <b>{this.state.selectedMatchCandidates.length}</b>/
									{this.state.matchCandidates.length}
								</Typography>
								<Button
									variant="outlined"
									onClick={() => {
										this.setState({ matchCandidates: [], selectedMatchCandidates: [] });
									}}
								>
									Avbryt
								</Button>

								<Button
									variant="contained"
									disabled={this.state.selectedMatchCandidates.length == 0}
									onClick={() => {
										this.setState({ loading: true });
										Backend.matchMultipleCrawlProducts(
											this.state.intendedMatchKey,
											this.state.selectedMatchCandidates,
										).then((res) => {
											let snackbar = '';
											if (res.success) {
												snackbar = `${this.state.selectedMatchCandidates.length} Butikprodukter matchade!`;
											} else {
												snackbar = 'Ett fel uppstod!';
											}
											this.setState({ loading: false, matchCandidates: [], selectedMatchCandidates: [], snackbar });
										});
									}}
								>
									Spara
								</Button>
							</Box>
						</Box>
					</Modal>
					<Box sx={{ width: '100%' }}>
						{this.state.parentProducts?.length > 0 && (
							<Box>
								<h4>Föraldraprodukter</h4>
								<ResultList
									products={this.state.parentProducts}
									progressVisible={false}
									handleProductSelected={this.handleParentSelected.bind(this)}
									emptyStateText="Inga produkter valda"
									maxHeight={this.state.parentProducts && this.state.parentProducts.length > 0 ? '35em' : '0em'}
								/>
							</Box>
						)}
						<h4>Butikprodukter ({this.state.matchedProducts.length})</h4>
						<Box
							sx={{
								overflow: 'auto',
								width: { md: '100%', lg: '100%', xl: '80%' },
								display: { sm: 'flex-inline', md: 'flex', lg: 'flex', xl: 'flex' },

								height: '60%',
								// background: { xs: 'red', md: 'lightblue', lg: 'pink', xl: 'green' },
							}}
						>
							{!store.butik._key && (
								<>
									<ResultList
										products={this.state.matchedProducts}
										progressVisible={false}
										directSource
										handleProductSelected={(product) => {
											const mp = this.state.matchedProducts.filter((x) => x.Produkt._key != product.Produkt._key);
											const up = this.state.unmatchedProducts;
											up.push(product.Produkt._key);
											this.setState({ matchedProducts: mp, unmatchedProducts: up });
										}}
										handleProductImageSelected={(product) => {
											if (product.Produkt.bildUrl) {
												this.setState({
													uploadstoreimageurldialog: product.Produkt.bildUrl,
												});
											}
										}}
										emptyStateText="Inga produkter valda"
										maxHeight={'50em'}
									/>
								</>
							)}
							{!!store.butik._key && <Box className={classes.searchProducts}> {this.renderMatchingProducts()}</Box>}
						</Box>
					</Box>
				</Box>
				<Box className="buttons">
					<Button
						variant="outlined"
						onClick={this.onCancelClick.bind(this)}
					>
						Tillbaka
					</Button>

					<Button
						variant="contained"
						disabled={
							this.state.loadingValidoo ||
							this.state.loading ||
							!this.isFieldsValid() ||
							(!this.state.intendedMatch && !this.isGtinUnique() && !this.isGtinStoreType())
						}
						onClick={this.onSaveClick.bind(this)}
					>
						Spara
					</Button>

					{!!this.props.params.id && (
						<Button
							variant="contained"
							disabled={this.state.loading}
							onClick={this.onDeleteClick.bind(this)}
						>
							Ta bort
						</Button>
					)}

					{!!this.props.params.id && (
						<Button
							variant="text"
							primary="true"
							onClick={this.onSourcesClick.bind(this)}
						>
							Butikprodukter
						</Button>
					)}
				</Box>
			</ViewContainer>
		);
	}
}

export default withRouter(ProductEdit);
