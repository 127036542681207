import React, { useEffect, useState } from 'react';
import LoadingContainer from '../../components/loading/LoadingContainer';
import Backend from '../../services/Backend';
import ViewContainer from '../../view';
// import './Home.module.css';
import { Checkbox } from '@mui/material';
import { Chart } from 'react-google-charts';
import classes from './Home.module.css';

export default function Home(props) {
	const [loading, setLoading] = useState(true);
	const [stats, setStats] = useState({});
	const [chartData, setChartData] = useState([]);
	const [spindlar, setSpindlar] = useState([]);
	const [filterSpindel, setFilterSpindel] = useState([]);

	useEffect(() => {
		Backend.fetchStats().then((x) => {
			setStats(x);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		generateChartData();
	}, [stats, filterSpindel]);

	const generateChartData = () => {
		if (!stats.produktSpindlingar) return;

		var spindelData = stats.produktSpindlingar;
		const allSpindlar = Object.getOwnPropertyNames(spindelData);
		const spindlar = allSpindlar.filter((x) => !filterSpindel.includes(x));
		var dates = new Set();
		spindlar.forEach((s) => {
			var sd = Object.getOwnPropertyNames(spindelData[s]);
			sd.forEach((x) => dates.add(x));
		});
		const data = [['Datum', ...spindlar, { role: 'annotation' }]];
		for (let date of dates) {
			var row = [date];
			for (let spindel of spindlar) {
				row.push(spindelData[spindel][date] || 0);
				var sum = 0;
				for (let i = 1; i < row.length; i++) {
					sum += row[i];
				}
			}
			row.push(sum);
			data.push(row);
		}
		setChartData(data);
		setSpindlar(allSpindlar);
	};

	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	};

	const renderProgress = (title, data) => {
		if (!data) {
			return <div />;
		}

		const all = data.all;
		const subset = data.new;

		const subsetProgress = (subset / all) * 100;
		const style = {
			width: subsetProgress + '%',
		};

		const styleAll = {
			width: 100 - subsetProgress + '%',
		};

		return (
			<div className={classes.progressContainer}>
				<h3>{title}</h3>
				Senaste veckan: {numberWithCommas(subset)} stycken
				<br />
				Totalt: {numberWithCommas(all)} stycken
				<div className={classes.progressAll}>
					<div
						className={classes.progressDone}
						style={style}
					>
						<label>{Math.round(subsetProgress)}%</label>
					</div>
					<div
						className={classes.label}
						style={styleAll}
					>
						{Math.round(100 - subsetProgress)}%
					</div>
				</div>
			</div>
		);
	};

	const options = {
		title: '',
		chartArea: { width: '80%' },
		isStacked: true,
		orientation: 'horizontal',
		fontName: 'Montserrat',
		hAxis: {
			title: '',
			minValue: 0,
		},
		vAxis: {
			title: '',
		},
	};

	return (
		<ViewContainer title="Statistik">
			<LoadingContainer
				className={classes.homeContainer}
				enabled={loading}
			>
				<div style={{ display: 'none' }}>
					{renderProgress('Priser', stats.prices)}
					{renderProgress('Produkter', stats.products)}
					{renderProgress('Produkter (spindlade)', stats.crawlProducts)}
					{renderProgress('Produkter (omatchade)', stats.unmatched)}
					{renderProgress('Bilder', stats.images)}
					{renderProgress('Butiker', stats.stores)}
				</div>
				<h1>Statistik</h1>
				<h3>Antal spindlade produkter</h3>
				<div style={{ display: 'flex' }}>
					{spindlar.map((spindel) => (
						<div
							key={spindel}
							style={{ display: 'inline-flex', alignItems: 'center' }}
						>
							<Checkbox
								defaultChecked
								onChange={(e) => {
									console.log(e);
									if (e.target.checked) {
										setFilterSpindel(filterSpindel.filter((x) => x !== spindel));
									} else {
										setFilterSpindel([...filterSpindel, spindel]);
									}
								}}
							/>
							{spindel}
						</div>
					))}
				</div>
				<div>
					{chartData.length > 0 && (
						<Chart
							chartType="BarChart"
							width="100%"
							height="800px"
							data={chartData}
							options={options}
						/>
					)}
				</div>
			</LoadingContainer>
		</ViewContainer>
	);
}
