import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { React, useDeferredValue, useEffect, useState } from 'react';
import LoadingContainer from '../../components/loading/LoadingContainer';

export default function ProductGruppChips(props) {
	const [categories, setCategories] = useState(
		props.categories
			.sort((a, b) => a.namn < b.namn)
			.map((x) => {
				x.key = x._key;
				return x;
			}),
	);
	const [allProduktGrupper, setAllProduktGrupper] = useState(
		useDeferredValue(() => {
			let allproduktgrupper = {};
			categories
				.filter((c) => c.kategoriNiva === 3 && c.foralderKey)
				.forEach((c) => {
					if (!allproduktgrupper[c.foralderKey]) {
						allproduktgrupper[c.foralderKey] = [];
					}
					allproduktgrupper[c.foralderKey].push(c);
				});
			return allproduktgrupper;
		}),
	);

	const [produktGrupper, setProduktGrupper] = useState(
		categories.sort((a, b) => a.namn < b.namn).filter((c) => c.kategoriNiva === 3 && c.foralderKey),
	);

	const [underkategorier, setUnderkategorier] = useState(
		props.categories.sort((a, b) => a.namn < b.namn).filter((c) => c.kategoriNiva === 2),
	);
	const [selectedProduktGrupp, setSelectedProduktgrupp] = useState(
		props.value ? categories.find((c) => c._key == props.value[0] && c.kategoriNiva == 3) : null,
	);

	const [selectedUnderKategori, setSelectedUnderKategori] = useState([]);

	const handleProduktgruppValue = (val, triggerOnChange = true) => {
		if (val && val.length > 0) {
			var cat = categories.find((c) => c._key == val[0]);

			setProduktGrupper(allProduktGrupper[cat.foralderKey] || []);
			setSelectedUnderKategori(categories.find((c) => c._key === cat.foralderKey));
			setSelectedProduktgrupp(cat);

			if (triggerOnChange) props.onChange([cat._key]);
		} else {
			setSelectedUnderKategori(null);
			setSelectedProduktgrupp(null);

			if (triggerOnChange) props.onChange([]);
		}
	};

	useEffect(() => {
		if (!props.value || props.value.length === 0) {
			setProduktGrupper(
				categories.sort((a, b) => a.namn < b.namn).filter((c) => c.kategoriNiva === 3 && c.foralderKey),
			);
		} else {
			console.log('%c⧭', 'color: #1d5673', props.value[0]);
			if (categories.find((c) => c._key == props.value[0] && c.kategoriNiva == 3)) {
				handleProduktgruppValue(props.value, false);
			}
		}
	}, [props.value]);

	return (
		<>
			<LoadingContainer
				enabled={categories.length == 0}
				loadingClassName={'none'}
			>
				<div style={{ marginBottom: '1em' }}>
					<TextField
						fullWidth={true}
						disabled
						type="text"
						label="Underkategori"
						variant="standard"
						value={(selectedUnderKategori && selectedUnderKategori.namn) || ''}
						InputLabelProps={{
							shrink: selectedUnderKategori && selectedUnderKategori.namn != undefined ? true : false,
						}}
					/>
				</div>
				<Autocomplete
					disablePortal
					id="combo-box-demo"
					options={produktGrupper}
					value={selectedProduktGrupp}
					onChange={(event, newValue) => {
						if (newValue != null) {
							handleProduktgruppValue([newValue._key]);
						} else {
							handleProduktgruppValue([]);
						}
					}}
					getOptionLabel={(option) => option.namn}
					groupBy={(option) => option.foralderNamn}
					fullWidth={true}
					variant="standard"
					renderInput={(params) => (
						<TextField
							variant="standard"
							error={!selectedProduktGrupp}
							{...params}
							label="Produktgrupp"
							helperText={props.errorText}
						/>
					)}
				/>
			</LoadingContainer>
		</>
	);
}
