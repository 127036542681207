import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import BaseComponent from '../../base';
import Backend from '../../services/Backend';

class SearchByManufacturer extends BaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			results: [],
		};
	}

	componentDidMount() {
		let index = 0;
		Backend.fetchBrands()
			.then((results) => {
				const unique = [...new Set(results)];
				this.setState({
					results: unique.map((x) => ({ id: index++, label: x })),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleUpdateInput(evt, option) {
		if (!evt) return;
		const val = evt.type === 'click' ? option.label : evt.target.value;
		if (this.props.onChange) {
			this.props.onChange(val);
		}
	}

	render() {
		const label = this.props.label || 'Varumärke';
		const width = this.props.width || '16em';

		return (
			<Autocomplete
				freeSolo
				disableClearable
				key={'searchManufacturer'}
				sx={{ width: width }}
				// searchText={this.props.value}
				// getOptionLabel={(x) => x.label}
				// hintText={this.props.hint ? label : null}
				// floatingLabelText={!this.props.hint ? label : null}
				options={this.state.results}
				onChange={this.handleUpdateInput.bind(this)}
				disabled={this.props.disabled}
				value={this.props.value}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						label={label}
						onChange={this.handleUpdateInput.bind(this)}
						error={!!this.props.errorText}
						helperText={this.props.errorText}
					/>
				)}
				isOptionEqualToValue={(option, value) => option.label === value.label}
			/>
		);
	}
}

export default SearchByManufacturer;
