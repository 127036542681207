import { Box, Checkbox } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { defaultTblStyle } from '../../TableGridCssOverride';
import Utils from '../../services/Utils';
import DataGrid from '../datagrid/DataGrid';

export default function CrawlProductList(props) {
	const [preSelectAll] = useState(props.preSelectAll || true);
	const [butikProdukter] = useState(props.butikProdukter || []);
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		if (preSelectAll) {
			setSelected(butikProdukter.map((x) => x.butikProdukt._key));
		} else {
			setSelected([]);
		}
	}, [butikProdukter]);

	useEffect(() => {
		if (!props.onChange) return;
		props.onChange(selected);
	}, [selected]);

	const onSelected = (key, checked) => {
		const newSelected = checked ? [...selected, key] : selected.filter((x) => x !== key);
		setSelected(newSelected);
	};

	const columns = [
		{
			id: 'key',
			name: 'Key',
			selector: (row) => row.butikProdukt._key,
			sortable: true,
			wrap: true,
		},
		{
			id: 'img',
			name: 'Bild',
			selector: (row) => row.butikProdukt.bildUrl,
			format: (row) => (
				<Box
					component="img"
					sx={{ width: '3em', height: '3em', objectFit: 'contain' }}
					src={row.butikProdukt.bildUrl}
				/>
			),
			maxWidth: '3em',
		},
		{
			id: 'namn',
			name: 'Namn',
			selector: (row) => row.butikProdukt.namn,
			sortable: true,
			wrap: true,
		},
		{
			id: 'info',
			name: 'Info',
			selector: (row) => row.butikProdukt.produktinfo,
			sortable: true,
		},
		{
			id: 'gtin',
			name: 'GTIN',
			selector: (row) => row.butikProdukt.gtin,
			sortable: true,
			wrap: true,
		},
		{
			id: 'mangd',
			name: 'Mängd',
			selector: (row) => row.butikProdukt.mangd,
			format: (row) => Utils.formatProductVolume(row.butikProdukt.mangd, row.butikProdukt.enhet),
			sortable: true,
		},
		{
			id: 'butik',
			name: 'Butik',
			selector: (row) => row.butik.namn,
			sortable: true,
			wrap: true,
			minWidth: '20em',
		},
		{
			id: 'crawltime',
			name: 'Senast sedd',
			selector: (row) => row.butikProdukt.currentPrisSpindlingDatum,
			format: (row) => moment(row.butikProdukt.currentPrisSpindlingDatum).format('YYYY-MM-DD'),
			sortable: true,
		},
		{
			id: 'select',
			name: 'Vald',
			selector: (row) => row.butikProdukt._key,
			format: (row) => (
				<Checkbox
					checked={selected.includes(row.butikProdukt._key)}
					onChange={(e) => onSelected(row.butikProdukt._key, e.target.checked)}
				/>
			),
			maxWidth: '2em',
		},
	];
	return (
		<Box>
			<DataGrid
				title={props.title || 'Butikprodukter'}
				columns={columns}
				data={butikProdukter}
				customStyles={defaultTblStyle}
				tableHeight={props.tableHeight || '50em'}
				showPagination={false}
			/>
		</Box>
	);
}
