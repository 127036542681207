import React from 'react';
import BaseComponent from '../../../base';
import Backend from '../../../services/Backend';
import Utils from '../../../services/Utils';
import ViewContainer from '../../../view';

import { Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import moment from 'moment';
import { unVerifiedTblStyle } from '../../../TableGridCssOverride';
import DataGrid from '../../../components/datagrid/DataGrid';
// import './UnverifiedProducts.module.css';

import { EditNoteSharp } from '@mui/icons-material';
import classes from './UnverifiedProducts.module.css';

class UnverifiedProducts extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	todaysDate = new Date().toISOString().split('T')[0];
	selectedDate = '';

	state = {
		page: 1,
		amount: 500,
		fromDate: sessionStorage.getItem('fromDate') || this.todaysDate,
		itemCount: 0,
		totalItemCount: 0,
		totalPageCount: 0,
		products: [],
		loading: true,
		selected: [],

		columns: [
			{
				cell: () => <EditNoteSharp fontSize="small" />,
				width: '15px',
			},
			{
				id: 'namn',
				name: 'Namn',
				responsive: false,
				selector: (row) => row.namn,
				wrap: true,
				minWidth: '15%',
				maxWidth: '20%',
				sortable: true,
			},
			{
				id: 'mangd',
				name: 'Mängd',
				responsive: false,
				grow: 0,
				selector: (row) => Utils.formatProductVolume(row.mangd, row.enhet),
				minWidth: '10%',
				maxWidth: '20%',
				sortable: true,
			},
			{
				id: 'varumarke',
				name: 'Varumärke',
				responsive: false,
				selector: (row) => row.varumarke,
				grow: 0,
				wrap: true,
				minWidth: '10%',
				maxWidth: '20%',
				sortable: true,
			},
			{
				id: 'leverantor',
				name: 'Leverantör',
				responsive: true,
				selector: (row) => row.leverantor,
				grow: 0,
				wrap: true,
				minWidth: '10%',
				maxWidth: '20%',
				sortable: true,
			},
			{
				id: 'produktGrupp',
				name: 'ProduktGrupp',
				responsive: false,
				selector: (row) => row.produktGrupp,
				grow: 0,
				wrap: true,
				minWidth: '15%',
				maxWidth: '18%',
				sortable: true,
			},
			{
				id: 'kategori',
				name: 'Kategori',
				responsive: true,
				selector: (row) => row.kategori,
				grow: 1,
				wrap: true,
				width: '20%',
				minWidth: '15%',
				maxWidth: '20%',
				sortable: true,
			},
			{
				id: 'senastSpindlad',
				name: 'Spindlad',
				responsive: true,
				selector: (row) => row.senastSpindlad,
				format: (row) => moment(row.senastSpindlad).format('YYYY-MM-DD'),
				grow: 0,
				minWidth: '8%',
				maxWidth: '10%',
				sortable: true,
				style: {
					paddingRight: '0px',
				},
			},
			{
				cell: (row) => (
					<span>
						<Button
							sx={{ fontSize: '0.75rem' }}
							size="small"
							variant="text"
							onClick={() => this.doVerifyProduct(row)}
						>
							Verifiera
						</Button>
					</span>
				),
				when: (row) => row,
				style: {
					whiteSpace: 'nowrap',
				},
			},
		],
	};

	productList = [];

	componentDidMount() {
		this.fetchProducts(this.state.page, this.state.amount, this.state.fromDate);
	}

	fetchProducts(page, amount, date) {
		this.setState({ loading: true });
		// const { amount, fromDate } = this.state;
		this.productList = [];

		const response = Backend.fetchUnverifiedProducts(page, amount, date);

		response.then(
			(data) => {
				this.productList.page = data.page;
				this.productList.totalPageCount = data.totalPageCount;
				this.productList.totalItemCount = data.totalItemCount;
				this.productList.itemCount = data.itemCount;
				this.productList.items = [];
				data.items.map((p) => {
					const crawlDate = new Date(p.SenastSpindlad);
					const getYear = crawlDate.toLocaleString('default', {
						year: 'numeric',
					});
					const getMonth = crawlDate.toLocaleString('default', {
						month: '2-digit',
					});
					const getDay = crawlDate.toLocaleString('default', {
						day: '2-digit',
					});
					const getTime = crawlDate.toLocaleTimeString();
					const d = getYear + '-' + getMonth + '-' + getDay + ' ' + getTime;
					this.productList.items.push({
						_key: p.Produkt._key,
						namn: p.Produkt.namn,
						mangd: Utils.formatProductVolume(p.Produkt.mangd, p.Produkt.enhet),
						varumarke: p.Varumarken.length > 0 ? p.Varumarken[0].namn : '--',
						leverantor: p.Leverantor.length > 0 ? p.Leverantor[0].namn : '--',
						kategori: p.Produkt.kategorier.reverse().map((k, i) => {
							if (i + 1 < 3) {
								return k.namn + ' / ';
							}
							return k.namn;
						}),
						produktGrupp: p.ProduktGrupp !== null ? p.ProduktGrupp.namn : '',
						senastSpindlad: d,
						selected: false,
					});
				});
				// k.namn + ' '
				this.setState({
					page: this.productList.page,
					totalItemCount: this.productList.totalItemCount,
					totalPageCount: this.productList.totalPageCount,
					amount: this.state.amount,
					products: this.productList.items,
					loading: false,
				});
			},
			() => {
				// Rejected
				this.setState({
					products: [],
					loading: false,
				});
				this.redirect('error');
			},
		);
	}

	doVerifyProduct(row) {
		let tmpArray = [...this.state.products];
		let index = tmpArray.indexOf(row);

		Backend.verifyProduct(row._key).then((res) => {
			if (res.success) {
				if (index !== -1) {
					tmpArray.splice(index, 1);
					this.setState({
						products: tmpArray,
					});
				}
			}
		});
	}

	doEditProduct(row) {
		this.redirect(`/products/${row._key}/`);
		// `/products/${this.props.targetKey}/`
	}

	handleRowSelect(row) {
		this.doEditProduct(row);
	}

	doVerifyMultipleProducts(keys) {
		let tmpArray = [];
		tmpArray = this.state.products;

		Backend.verifyProducts(keys).then((res) => {
			res.forEach((r) => {
				if (r.success) {
					const idx = this.state.products.findIndex((x) => x._key === r.key);
					if (idx !== -1) {
						tmpArray.splice(idx, 1);
					}
					this.setState({
						products: tmpArray,
						selected: [],
					});
				}
			});
		});
	}

	handleChange = (event) => {
		if (dayjs(event.format('YYYY-MM-DD')).isAfter('1970-01-01') && event.format('YYYY-MM-DD') !== 'Invalid Date') {
			sessionStorage.setItem('fromDate', event.format('YYYY-MM-DD'));
			this.setState({ fromDate: event.format('YYYY-MM-DD'), page: 1 }, () => {
				this.fetchProducts(this.state.page, this.state.amount, event.format('YYYY-MM-DD'));
			});
		}
	};

	render() {
		const { totalItemCount, products, columns } = this.state;
		unVerifiedTblStyle.table.style.minHeight = '70vh';
		return (
			<ViewContainer title="Overifierade produkter">
				<div className={classes.datewrapper}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale="sv"
					>
						<DatePicker
							label="Startdatum"
							id="date"
							value={dayjs(this.state.fromDate)}
							format={'YYYY-MM-DD'}
							onChange={this.handleChange}
							firstDayOfWeek={1}
							slotProps={{ textField: { variant: 'standard' } }}
						/>
					</LocalizationProvider>
				</div>

				<DataGrid
					columns={columns}
					data={products}
					onClick={this.handleRowSelect.bind(this)}
					loading={this.state.loading}
					defaultSortFieldId="namn"
					sortDirection="asc"
					customStyles={unVerifiedTblStyle}
					showPagination={true}
					paginationServer
					paginationTotalRows={totalItemCount}
					title="Overifierade produkter"
				/>
			</ViewContainer>
		);
	}
}

export default UnverifiedProducts;
