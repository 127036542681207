export const defaultTblStyle = {
	table: {
		style: {
			width: '100%',
			minHeight: '80vh',
		},
	},
	rows: {
		style: {
			minHeight: '32px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

export const combinedTblStyle = {
	table: {
		style: {
			width: '100%',
			minHeight: '80vh',
		},
	},
	rows: {
		style: {
			minHeight: '32px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

export const storesTblStyle = {
	table: {
		style: {
			width: '100%',
			minHeight: '50vh',
		},
	},
	rows: {
		style: {
			minHeight: '32px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

// Scan
export const scanTablStyle = {
	table: {
		style: {
			height: defaultTblStyle.table.style.minHeight,
		},
	},
	rows: {
		style: {
			minHeight: '52px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

// Duplicates
export const potentialDuplicatesTblStyle = {
	table: {
		style: {
			height: defaultTblStyle.table.style.minHeight,
		},
	},
	rows: {
		style: {
			minHeight: '52px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

// Unverified
export const unVerifiedTblStyle = {
	table: {
		style: {
			height: defaultTblStyle.table.style.minHeight,
		},
	},
	rows: {
		style: {
			minHeight: '52px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

// productbags
export const productbagsTblStyle = {
	table: {
		style: {
			height: defaultTblStyle.table.style.minHeight,
		},
	},
	rows: {
		style: {
			minHeight: '72px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

export const productTblStyle = {
	table: {
		style: {
			height: defaultTblStyle.table.style.minHeight,
		},
	},
	rows: {
		style: {
			minHeight: '72px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

export const clientsTblStyle = {
	table: {
		style: {
			height: defaultTblStyle.table.style.minHeight,
		},
	},
	rows: {
		style: {
			minHeight: '52px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

export const brandvariationsTblStyle = {
	table: {
		style: {
			height: '60vh',
		},
	},
	rows: {
		style: {
			minHeight: '52px', // override the row height
			fontSize: '0.9rem',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px', // override the cell padding for head cells
			paddingRight: '0px',
			fontWeight: 'bold',
			fontSize: '0.875rem',
			textWrap: 'wrap',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			width: '80px',
			minWidth: '300px',
			maxWidth: '400px',
			textWrap: 'wrap',
		},
	},
	pagination: {
		style: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			minHeight: '56px',
			borderTopStyle: 'none',
			borderTopWidth: '1px',
		},
	},
};

// Example of custom styles
export const EXAMPLE = {
	table: {
		style: {
			color: 'white',
			backgroundcolor: 'white',
		},
	},
	tableWrapper: {
		style: {
			display: 'table',
		},
	},
	responsiveWrapper: {
		style: {},
	},
	header: {
		style: {
			fontSize: '22px',
			color: 'white',
			backgroundcolor: 'white',
			minHeight: '56px',
			paddingLeft: '16px',
			paddingRight: '8px',
		},
	},
	subHeader: {
		style: {
			backgroundcolor: 'white',
			minHeight: '52px',
		},
	},
	head: {
		style: {
			color: 'white',
			fontSize: '12px',
			fontWeight: 500,
		},
	},
	headRow: {
		style: {
			backgroundcolor: 'white',
			minHeight: '52px',
			borderBottomWidth: '1px',
			borderBottomcolor: 'white',
			borderBottomStyle: 'solid',
		},
		denseStyle: {
			minHeight: '32px',
		},
	},
	headCells: {
		style: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
		draggingStyle: {
			cursor: 'move',
		},
	},
	contextMenu: {
		style: {
			backgroundcolor: 'white',
			fontSize: '18px',
			fontWeight: 400,
			color: 'white',
			paddingLeft: '16px',
			paddingRight: '8px',
			transform: 'translate3d(0, -100%, 0)',
			transitionDuration: '125ms',
			transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
			willChange: 'transform',
		},
		activeStyle: {
			transform: 'translate3d(0, 0, 0)',
		},
	},
	cells: {
		style: {
			paddingLeft: '16px',
			paddingRight: '16px',
			wordBreak: 'break-word',
		},
		draggingStyle: {},
	},
	rows: {
		style: {
			fontSize: '13px',
			fontWeight: 400,
			color: 'white',
			backgroundcolor: 'white',
			minHeight: '48px',
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomcolor: 'red',
			},
		},
		denseStyle: {
			minHeight: '32px',
		},
		selectedHighlightStyle: {
			// use nth-of-type(n) to override other nth selectors
			'&:nth-of-type(n)': {
				color: 'white',
				backgroundcolor: 'white',
				borderBottomcolor: 'red',
			},
		},
		highlightOnHoverStyle: {
			color: 'white',
			backgroundcolor: 'white',
			transitionDuration: '0.15s',
			transitionProperty: 'background-color',
			borderBottomcolor: 'white',
			outlineStyle: 'solid',
			outlineWidth: '1px',
			outlinecolor: 'red',
		},
		stripedStyle: {
			color: 'white',
			backgroundcolor: 'white',
		},
	},
	expanderRow: {
		style: {
			color: 'white',
			backgroundcolor: 'white',
		},
	},
	expanderCell: {
		style: {
			flex: '0 0 48px',
		},
	},
	expanderButton: {
		style: {
			color: 'white',
			backgroundcolor: 'white',
			borderRadius: '2px',
			transition: '0.25s',
			height: '100%',
			width: '100%',
			'&:hover:enabled': {
				cursor: 'pointer',
			},
			'&:disabled': {
				color: 'red',
			},
			'&:hover:not(:disabled)': {
				cursor: 'pointer',
				backgroundcolor: 'white',
			},
			'&:focus': {
				outline: 'none',
				backgroundcolor: 'white',
			},
			svg: {
				margin: 'auto',
			},
		},
	},
	pagination: {
		style: {
			color: 'white',
			fontSize: '13px',
			minHeight: '56px',
			backgroundcolor: 'white',
			borderTopStyle: 'solid',
			borderTopWidth: '1px',
			borderTopcolor: 'red',
		},
		pageButtonsStyle: {
			borderRadius: '50%',
			height: '40px',
			width: '40px',
			padding: '8px',
			margin: 'px',
			cursor: 'pointer',
			transition: '0.4s',
			color: 'white',
			fill: 'transparent',
			backgroundcolor: 'white',
			'&:disabled': {
				cursor: 'unset',
				color: 'white',
				fill: 'transparent',
			},
			'&:hover:not(:disabled)': {
				backgroundcolor: 'white',
			},
			'&:focus': {
				outline: 'none',
				backgroundcolor: 'white',
			},
		},
	},
	noData: {
		style: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: 'white',
			backgroundcolor: 'white',
		},
	},
	progress: {
		style: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: 'white',
			backgroundcolor: 'white',
		},
	},
};
