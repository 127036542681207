import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useParams, useSearchParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import ProduktGruppChips from '../../../components/categories/ProduktGruppChips';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import SearchByManufacturer from '../../../components/manufacturer/SearchManufacturer';
import MarksList from '../../../components/marks/MarksList';
import ResultList from '../../../components/result/ResultList';
import Backend from '../../../services/Backend';
import Utils from '../../../services/Utils';
import ViewContainer from '../../../view';
// import './ProductsDuplicates.module.css';
import { Box } from '@mui/material';
import classes from './ProductsDuplicates.module.css';

/*
  withRouter is a higher order component that injects the params into the wrapped component
*/
const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	// location.query = {};
	const [searchParams, setSearchParams] = useSearchParams();

	const newProps = { ...props, params, query: searchParams };

	return <WrappedComponent {...newProps} />;
};

class ProductsDuplicates extends BaseComponent {
	static validationRules = {
		gtin: (product) =>
			!isNaN(product.gtin) && product.gtin && (product.gtin.length === 14 || product.gtin.length === 16),
		namn: (product) => product.namn,
		antal: (product) => !isNaN(product.antal) && product.antal > 0,
		mangd: (product) => !isNaN(product.mangd) && product.mangd > 0,
		manufacturer: (_) => null,
		categories: (_) => null,
	};

	constructor(props) {
		super(props);
		this.debounceTime = 300;
		this.duplicateSubstring = '(Inkluderar GTIN';
	}

	state = {
		varumarke: {},
		editVarumarke: '',
		categories: [],
		combineProduct: { synlig: true },
		unmatchedProducts: [],
		choosenUnit: '',
		matchAlternatives: [],
		matchAlternativesSearch: '',
		matchAlternativesManufacturer: null,
		allowEmptyManufacturer: false,
		parentProducts: [],
		originalProducts: [],
		snackbar: '',
		productdeleted: false,
		deletesnackbar: '',
		deleteconfirmeddialog: '',
		mergesnackbar: '',
		savingProduct: false,
		saveButtonText: 'Spara',
		loading: true,
		page: 0,
		allResultsFetched: false,
		resultProgressVisible: false,
		selectedMarks: [],
		deleteImageClicked: false,
	};

	componentDidMount() {
		Backend.fetchStores().then((stores) => {
			Backend.fetchCategoriesWithParent().then((categories) => {
				this.setState({
					categories,
					directSource: this.props.directSource,
				});
			});

			if (this.props.params.id) {
				Backend.fetchCombinedProducts(this.props.params.id).then((products) => {
					this.setState({
						originalProducts: products.map((p) => {
							p._key = p.key;
							return {
								Produkt: p,
								Varumarken: [{ namn: p.varumarke || '' }],
								SenastSpindlad: p.senastSpindlad,
							};
						}),
					});
				});

				Backend.fetchParentProducts(this.props.params.id).then((products) => {
					this.setState({
						parentProducts: products.map((p) => {
							p._key = p.key;
							return {
								Produkt: p,
								Varumarken: [{ namn: p.varumarke || '' }],
								SenastSpindlad: p.senastSpindlad,
							};
						}),
					});
				});

				Backend.fetchProductCrawlProducts(this.props.params.id, false).then((crawlproducts) => {
					crawlproducts.forEach((cp) => {
						const bp = cp.butikProdukt;
						bp.extra = cp.butik.namn;
						cp.SenastSpindlad = cp.senastSedd;
						cp.Produkt = bp;
					});

					this.setState({
						matchedProducts: crawlproducts,
						unmatchedProducts: [],
					});
				});

				this.fetchProdukt(this.props.params.id);
			} else if (this.props.query && this.props.query.getAll('children').length > 0) {
				let children = this.props.query.getAll('children');
				if (children.constructor !== Array) {
					children = [children];
				}

				const parent = children[0];
				children = children.slice(1);

				Backend.fetchProduct(parent)
					.then((x) => {
						const payload = {
							Produkt: {
								...x,
								_key: x.key,
							},
						};
						this.handleProductSelected(payload);
					})
					.then(() =>
						children.map((id) => {
							Backend.fetchProduct(id).then((x) => {
								const payload = {
									Produkt: {
										...x,
										_key: x.key,
									},
								};
								this.handleProductSelected(payload);
							});
						}),
					)
					.then(this.setState({ loading: false, stores: stores }));
			} else {
				this.setState({
					loading: false,
				});
			}

			this.setState({ stores: stores });
		});
	}

	validateField(fieldName) {
		const product = this.state.combineProduct;
		const allowEmptyManufacturer = this.state.allowEmptyManufacturer;
		const func =
			ProductsDuplicates.validationRules[fieldName] ||
			(() => {
				// empty function
			});
		if (fieldName === 'manufacturer') {
			return !!this.state.editVarumarke || allowEmptyManufacturer;
		}

		if (fieldName === 'categories') {
			return this.state.editCategories && this.state.editCategories.length > 0;
		}

		return func(product, allowEmptyManufacturer);
	}

	validateUnitField() {
		const unit = Utils.getUnit(this.state.choosenUnit);
		return unit !== null && unit.length === 2;
	}
	validateOriginalProducts() {
		return this.state.originalProducts.length > 1;
	}

	isFieldsValid() {
		return (
			/* this.validateUnitField() &&*/ this.validateOriginalProducts() &&
			Object.keys(ProductsDuplicates.validationRules).every(this.validateField.bind(this))
		);
	}

	fetchProdukt(key) {
		Backend.fetchProduct(key).then(
			(product) => {
				if (product.produktinfo)
					product.produktinfo = product.produktinfo.substring(0, product.produktinfo.indexOf(this.duplicateSubstring));
				this.setState({
					choosenUnit: Utils.getUnitName(product.enhet),
					combineProduct: product,
				});

				Backend.fetchProduktVarumarke(product.key).then((varumarke) => {
					this.setState({
						varumarke,
						editVarumarke: varumarke.namn,
					});
				});

				Backend.fetchCategoriesByProdukt(product.key).then((cats) => {
					this.setState({
						editCategories: (cats && cats.length > 0 && [cats[0]._key]) || [],
						loading: false,
					});
				});

				Backend.fetchEgenskaperByProduktKey(product.key).then((marks) => {
					const selectedMarks = [...this.state.selectedMarks];

					marks.map((x) => {
						if (!selectedMarks.includes(x.key)) {
							selectedMarks.push(x.key);
						}
					});
					this.setState({
						selectedMarks,
					});
				});
			},
			(err) => {
				console.info(err);
			},
		);
	}

	fetchAlternatives(searchText, searchManufacturer, page) {
		if (searchText === undefined || searchText === null) {
			this.setState({
				matchAlternativesSearch: '',
				matchAlternativesManufacturer: searchManufacturer,
				resultProgressVisible: false,
			});
			return;
		}

		const matchedProductsIds = this.state.originalProducts.map((x) => x.Produkt._key);
		this.setState(
			{
				matchAlternativesSearch: searchText,
				matchAlternativesManufacturer: searchManufacturer,
				resultProgressVisible: true,
				allResultsFetched: true,
			},
			() => {
				if (searchText || searchManufacturer) {
					Backend.fetchSearchResults(
						searchText,
						searchManufacturer,
						null,
						matchedProductsIds,
						null,
						false,
						page || 1,
					).then((result) => {
						if (result.length === 0 && page) {
							this.setState({
								allResultsFetched: true,
								resultProgressVisible: false,
							});
						} else {
							let matchAlternatives = !page ? [] : this.state.matchAlternatives;
							matchAlternatives = [...matchAlternatives, ...result];
							this.setState({
								allResultsFetched: false,
								resultProgressVisible: false,
								matchAlternatives: matchAlternatives,
								page: page || 1,
							});
						}
					});
				} else {
					this.setState({
						allResultsFetched: false,
						resultProgressVisible: false,
					});
				}
			},
		);
	}

	handleProductSelected(product) {
		const originalProducts = this.state.originalProducts;
		if (!originalProducts.some((x) => x.Produkt._key.valueOf() === product.Produkt._key.valueOf())) {
			originalProducts.push(product);
			if (!this.props.params.id) {
				this.fetchProdukt(product.Produkt._key);
			}
			this.setState(
				{
					originalProducts,
				},
				() => {
					this.fetchAlternatives(this.state.matchAlternativesSearch, this.state.matchAlternativesManufacturer, 0);
				},
			);
		}
	}

	handleProductDeselected(product) {
		let originalProducts = this.state.originalProducts;
		originalProducts = originalProducts.filter((x) => x.Produkt._key !== product.Produkt._key);
		this.setState(
			{
				originalProducts,
			},
			() => {
				this.fetchAlternatives(this.state.matchAlternativesSearch, this.state.matchAlternativesManufacturer, 0);
			},
		);
	}

	handleParentSelected(product) {
		switch (product.Produkt.produktTyp) {
			case 1:
				this.redirect(`/products/${product.Produkt._key}`);
				break;
			case 2:
				this.redirect(`/products/duplicates/${product.Produkt._key}`);
				break;
			case 3:
				this.redirect(`/products/combine/${product.Produkt._key}`);
				break;
			default: // noop
		}
	}

	onScrollToBottom() {
		if (!this.state.allResultsFetched) {
			this.debounce(this.fetchAlternatives.bind(this), this.debounceTime)(
				this.state.matchAlternativesSearch,
				this.state.matchAlternativesManufacturer,
				this.state.page + 1,
			);
		}
	}

	onCancelClick() {
		this.back();
	}

	onSaveClick() {
		this.setState({
			savingProduct: true,
			saveButtonText: 'Sparar...',
		});

		const ignoreManufacturer = this.state.allowEmptyManufacturer;
		const product = this.state.combineProduct;
		const originalProductIDs = this.state.originalProducts.map((prod) => prod.Produkt._key);
		product.manufacturer = ignoreManufacturer ? '' : this.state.editVarumarke;

		const unit = Utils.getUnit(this.state.choosenUnit);
		if (!this.isFieldsValid() && unit !== null && unit.length === 2) {
			this.setState({
				snackbar: 'Rödmarkerade fälten måste vara ifyllda.',
				savingProduct: false,
				saveButtonText: 'Spara',
			});

			return;
		}

		const gtins = [...new Set(this.state.originalProducts.map((op) => op.Produkt.gtin))].join(', ');
		const produktinfo = gtins ? `${product.produktinfo} (Inkluderar GTIN: ${gtins})` : product.produktinfo;
		const payload = {
			produktTyp: 2, // duplicate
			isDuplicate: true,
			gtin: product.gtin,
			namn: product.namn,
			produktinfo: produktinfo,
			bildUrl: (product.produktBild && product.produktBild.url) || product.bildUrl || '',
			produktBild: product.produktBild,
			produktBildThumbnail: product.produktBildThumbnail,
			varumarkeNamn: product.manufacturer,
			varumarke: product.manufacturer,
			enhet: unit[0],
			antal: product.antal,
			mangd: product.mangd * unit[1],
			minstaDelmangd: product.minstaDelmangd,
			kategorier: this.state.editCategories,
			originalProductIds: originalProductIDs,
			synlig: product.synlig,
			egenskaper: this.state.selectedMarks,
			visaSomJmfPris: product.visaSomJmfPris,
		};

		Promise.all([
			...this.state.unmatchedProducts.map((id) => {
				return Backend.unmatch(id);
			}),
		])
			.then((response) => {
				Backend.saveCombineProduct(this.props.params.id, payload)
					.then((response) => {
						this.redirect(`/products/duplicates/${response.key}`);
					})
					.catch(() => {
						this.setState({
							snackbar: 'Ett fel uppstod vid sparningen av produkten',
							savingProduct: false,
							saveButtonText: 'Spara',
						});
					});
			})
			.catch(() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen av produkten',
					savingProduct: false,
					saveButtonText: 'Spara',
				});
			});
	}

	onDeleteClick() {
		this.setState({
			deletesnackbar: 'Ta bort matchad dubblett "' + [this.state.combineProduct.namn] + '"?',
		});
		return;
	}

	onCancelDeleteClick() {
		this.setState({ deletesnackbar: '' });
	}

	onConfirmDeleteClick() {
		let promise;
		promise = Backend.deleteCombineProduct(this.props.params.id);
		promise
			.then((result) => {
				this.setState({
					deletesnackbar: '',
					deleteconfirmeddialog: 'Produkten är borttagen.',
					productdeleted: true,
				});
			})
			.catch((error) => {
				this.setState({
					deletesnackbar: '',
					deleteconfirmeddialog: 'Produkten kunde inte tas bort.',
					productdeleted: false,
				});
			});
	}

	onConfirmMergeClick() {
		let promise;
		promise = Backend.mergeMatchedProduct(this.props.params.id);
		promise
			.then((result) => {
				if (result.success) {
					this.redirect(`/products/${this.props.params.id}`);
				} else {
					throw result.message;
				}
			})
			.catch((error) => {
				this.setState({
					mergesnackbar: '',
					deleteconfirmeddialog: 'Misslyckades att slå ihop produkterna: ' + error,
					productdeleted: false,
				});
			});
	}

	onConfirmedDeleteProductClick() {
		this.setState({ deleteconfirmeddialog: '' });
		if (this.state.productdeleted) {
			this.back();
		}
	}

	setProductProperty(property, value) {
		if (property === 'manufacturer') {
			this.setState({ editVarumarke: value });
		}
		if (property === 'categories') {
			this.setState({ editCategories: value });
		}

		const combineProduct = this.state.combineProduct;
		combineProduct[property] = value;

		this.setState({ combineProduct });
	}

	onCheckedboxChangedManufacturer(val) {
		this.setState({ allowEmptyManufacturer: val.target.checked });
	}

	onCheckedboxChangeVisible(val) {
		this.setState((state) => ({
			combineProduct: {
				...state.combineProduct,
				synlig: val.target.checked,
			},
		}));
	}

	onCheckedboxChangeJmfPris(checked) {
		this.setState((state) => ({
			combineProduct: {
				...state.combineProduct,
				visaSomJmfPris: checked,
			},
		}));
	}

	onSourcesClick() {
		this.redirect(`/products/${this.props.params.id}/sources`);
	}

	onMergeProductClick() {
		const text = `Slå ihop ${this.state.originalProducts.length + 1} produkter?`;
		this.setState({
			mergesnackbar: text,
		});
	}

	openProductSource(product, store) {
		if (this.hasProductSource(product, store)) {
			let sourceUrl = ProductsDuplicates.chainSourceUrls[store.chain] || '';
			sourceUrl = sourceUrl.replace('{productId}', product.crawlProductId);
			sourceUrl = sourceUrl.replace('{storeId}', store.storeId);

			if (sourceUrl) {
				window.open(sourceUrl, '_blank');
			} else {
				this.setState({
					snackbar: 'Kunde inte öppna källa',
				});
			}
		}
	}

	hasProductSource(product, store) {
		return ProductsDuplicates.chainSourceUrls[store.chain] && product.crawlProductId && store.storeId;
	}

	fetchValidooImage() {
		this.setState(
			{
				loading: true,
			},
			() => {
				Backend.fetchValidooImage(this.state.combineProduct.key).then(
					(product) => {
						if (product && product.success) {
							Backend.fetchProduct(this.props.params.id).then((pr) => {
								this.setState({
									combineProduct: pr,
								});
							});
						}
						this.setState({
							loading: false,
						});
					},
					() => {
						this.setState({
							loading: false,
						});
					},
				);
			},
		);
	}

	renderProduct() {
		const combineProduct = this.state.combineProduct;
		const originalProducts = this.state.originalProducts;
		const allowEmptyManufacturer = this.state.allowEmptyManufacturer;
		let options = [];
		Utils.getUnitNames().map((item) => {
			options.push(item);
		});

		return (
			<Box className={classes.textfields}>
				<Box className={classes.gtinField}>
					<TextField
						sx={{ paddingBottom: '1em', width: '25em' }}
						value={combineProduct.gtin || ''}
						onChange={(evt) => this.setProductProperty('gtin', evt.target.value)}
						label={'GTIN'}
						variant="standard"
						error={!this.validateField('gtin')}
						helperText={this.validateField('gtin') ? '' : 'Måste vara ett 14 eller 16-siffrigt nummer'}
					/>
					<Button
						className={classes.button}
						onClick={() => this.fetchVaxlidoo(combineProduct.gtin)}
						disabled={!this.validateField('gtin')}
						variant="outlined"
					>
						Validoo
					</Button>
				</Box>
				<Box>
					{' '}
					Inkluderade GTIN:
					{[...new Set(originalProducts.map((op) => op.Produkt.gtin))].map((g, i) => (
						<Box key={i}>{g}</Box>
					))}
				</Box>
				{this.props.params.id && (
					<Box sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
						<Box style={{ display: 'flex' }}>
							<Box
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<a
									href={combineProduct.produktBild && combineProduct.produktBild.url}
									target="_blank"
									rel="noreferrer"
								>
									<Avatar
										variant="square"
										style={{
											borderRadius: 'none',
											backgroundColor: 'rgba(255,255,255,0)',
											width: 'auto',
											height: '80px',

											objectFit: 'cover',
										}}
										onError={(e) => {
											e.target.src =
												'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
										}}
										src={combineProduct.produktBildThumbnail && combineProduct.produktBildThumbnail.url}
										size={-1}
									/>
								</a>
							</Box>
							<Box
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginLeft: '2em',
									alignItems: 'left',
								}}
							>
								<span>
									Källa:{' '}
									{(combineProduct.produktBildThumbnail && combineProduct.produktBildThumbnail.origin) || 'okänd'}
								</span>
								<div>
									<Button
										style={{ height: '2em', lineHeight: 'inherit' }}
										variant="outlined"
										primary="true"
										onClick={() => this.fetchValidooImage()}
									>
										Hämta bild
									</Button>
									{combineProduct.produktBildThumbnail && (
										<IconButton
											color={this.state.deleteImageClicked ? 'primary' : 'secondary'}
											sx={{ marginLeft: '1em' }}
											onClick={() => {
												if (!this.state.deleteImageClicked) {
													this.setState({ deleteImageClicked: true });
												} else {
													Backend.deleteProductImage(combineProduct.key).then((result) => {
														if (result.success) {
															Backend.fetchProduct(this.props.params.id).then((pr) => {
																this.setState({
																	combineProduct: pr,
																	deleteImageClicked: false,
																});
															});
														}
													});
												}
											}}
										>
											<DeleteIcon />
										</IconButton>
									)}
								</div>
							</Box>
						</Box>
					</Box>
				)}
				<TextField
					variant="standard"
					value={combineProduct.namn || ''}
					onChange={(evt) => this.setProductProperty('namn', evt.target.value)}
					label={'Namn'}
					sx={{ width: '25em' }}
					fullWidth={true}
					error={!this.validateField('namn')}
					helperText={this.validateField('namn') ? '' : 'Namnet får inte vara tomt'}
				/>
				<TextField
					variant="standard"
					value={combineProduct.produktinfo || ''}
					onChange={(evt) => this.setProductProperty('produktinfo', evt.target.value)}
					label={'Produktinfo'}
					sx={{ width: '25em' }}
					fullWidth={true}
				/>
				<SearchByManufacturer
					value={allowEmptyManufacturer ? '' : this.state.editVarumarke || ''}
					onChange={(x) => this.setProductProperty('manufacturer', x)}
					disabled={allowEmptyManufacturer}
					errorText={this.validateField('manufacturer') ? '' : 'Varumärke får inte vara tomt'}
					width="25em"
				/>

				<FormGroup sx={{ display: 'inline' }}>
					<FormControlLabel
						control={<Checkbox onClick={this.onCheckedboxChangedManufacturer.bind(this)} />}
						label="Inget varumärke"
						checked={allowEmptyManufacturer}
					/>
					<FormControlLabel
						control={
							<Checkbox
								onChange={this.onCheckedboxChangeVisible.bind(this)}
								checked={combineProduct.synlig}
							/>
						}
						label="Synlig"
					/>
					<FormControlLabel
						control={
							<Checkbox
								onChange={(_, checked) => this.onCheckedboxChangeJmfPris(checked)}
								checked={combineProduct.visaSomJmfPris}
							/>
						}
						label="Visa som Jmf-pris"
					/>
				</FormGroup>

				<Box style={{ display: 'flex' }}>
					<Box>
						{
							<ProduktGruppChips
								onChange={(val) => this.setProductProperty('categories', val)}
								errorText={this.validateField('categories') ? '' : 'Produktgrupp kan inte lämnas tomt'}
								value={this.state.editCategories}
								categories={this.state.categories}
							></ProduktGruppChips>
						}

						<TextField
							key={'numberOfItemsField'}
							type="number"
							variant="standard"
							sx={{ width: '25em' }}
							value={combineProduct.antal || ''}
							onChange={(evt) => this.setProductProperty('antal', evt.target.value)}
							label={'Antal'}
							error={!this.validateField('antal')}
							helperText={this.validateField('antal') ? '' : 'Måste vara ett nummer större än 0'}
						/>
						<br></br>
						<TextField
							key={'amountOfUnits'}
							type="number"
							variant="standard"
							sx={{ width: '25em' }}
							value={combineProduct.mangd || ''}
							onChange={(evt) => this.setProductProperty('mangd', evt.target.value)}
							label={'Mängd'}
							error={!this.validateField('mangd')}
							helperText={this.validateField('mangd') ? '' : 'Måste vara ett nummer större än 0'}
						/>
						<Box>
							<Autocomplete
								options={options}
								getOptionLabel={(option) => option}
								variant="standard"
								onChange={(event, choosenUnit) => {
									console.log('%c⧭', 'color: #735656', choosenUnit);
									this.setState({ choosenUnit });
								}}
								value={this.state.choosenUnit || null}
								renderInput={(params) => (
									<TextField
										variant="standard"
										error={!this.validateUnitField()}
										{...params}
										label="Enhet"
									/>
								)}
							/>
						</Box>
					</Box>
					<Box style={{ paddingLeft: '2em' }}>
						<MarksList
							onChange={(key, selected) => {
								const marks = [...this.state.selectedMarks];
								if (selected && !marks.includes(key)) {
									marks.push(key);
								} else if (!selected && marks.includes(key)) {
									marks.splice(marks.indexOf(key), 1);
								}
								this.setState({ selectedMarks: marks });
							}}
							selectedMarks={this.state.selectedMarks}
						/>
					</Box>
				</Box>
				{/* <h4>Butikprodukter</h4>
				<ResultList
					products={this.state.matchedProducts}
					progressVisible={false}
					handleProductSelected={(product) => {
						const mp = this.state.matchedProducts.filter((x) => x.Produkt._key != product.Produkt._key);
						const up = this.state.unmatchedProducts;
						up.push(product.Produkt._key);
						this.setState({ matchedProducts: mp, unmatchedProducts: up });
					}}
					emptyStateText="Inga produkter valda"
				/>
				<br />
				<h4>Inkluderade produkter</h4>
				<ResultList
					products={this.state.originalProducts}
					progressVisible={false}
					handleProductSelected={this.handleProductDeselected.bind(this)}
					emptyStateText="Inga produkter valda"
				/> */}
			</Box>
		);
	}

	canBeMerged() {
		return (
			!!this.props.params.id &&
			this.state.originalProducts.length > 0 &&
			this.state.originalProducts.every((x) => x.Produkt.gtin === this.state.combineProduct.gtin)
		);
	}

	renderMatchingProducts() {
		return (
			<Box>
				{this.state.parentProducts?.length > 0 && (
					<Box>
						<h4>Föraldraprodukter</h4>
						<ResultList
							products={this.state.parentProducts}
							progressVisible={false}
							handleProductSelected={this.handleParentSelected.bind(this)}
							emptyStateText="Inga produkter valda"
							maxHeight={this.state.parentProducts && this.state.parentProducts.length > 0 ? '35em' : '0em'}
						/>
					</Box>
				)}
				<Box>
					<h4>Sök produkt</h4>
					<TextField
						sx={{ width: '25em' }}
						variant="standard"
						onChange={(evt) => this.fetchAlternatives(evt.target.value, this.state.matchAlternativesManufacturer, 0)}
						label={'Fritext'}
					/>
					<br />
					<TextField
						sx={{ width: '25em' }}
						variant="standard"
						value={this.state.matchAlternativesManufacturer || ''}
						onChange={(evt) => this.fetchAlternatives(this.state.matchAlternativesSearch, evt.target.value, 0)}
						label={'Varumärke'}
					/>
					<Box sx={{ overflow: 'auto' }}>
						<ResultList
							products={this.state.matchAlternatives.filter((prod) =>
								this.state.originalProducts.every(
									(originalProd) =>
										originalProd.Produkt._key !== prod.Produkt._key &&
										this.state.combineProduct.key !== prod.Produkt._key,
								),
							)}
							progressVisible={false}
							directSource
							handleProductSelected={this.handleProductSelected.bind(this)}
							emptyStateText="Inga resultat hittades"
							handleScrollToBottom={this.onScrollToBottom.bind(this)}
							maxHeight="30em"
							// maxHeight={this.state.matchedProducts && this.state.matchedProducts.length > 0 ? '35em' : '0em'}
						/>
					</Box>
				</Box>
				<Box sx={{ overflow: 'auto' }}>
					<h4>Butikprodukter</h4>
					<ResultList
						products={this.state.matchedProducts}
						progressVisible={false}
						handleProductSelected={(product) => {
							const mp = this.state.matchedProducts.filter((x) => x.Produkt._key != product.Produkt._key);
							const up = this.state.unmatchedProducts;
							up.push(product.Produkt._key);
							this.setState({ matchedProducts: mp, unmatchedProducts: up });
						}}
						emptyStateText="Inga produkter valda"
						maxHeight="50em"
					/>
				</Box>
				<Box>
					<h4>Inkluderade produkter</h4>
					<ResultList
						products={this.state.originalProducts}
						progressVisible={false}
						handleProductSelected={this.handleProductDeselected.bind(this)}
						emptyStateText="Inga produkter valda"
						maxHeight={this.state.originalProducts && this.state.originalProducts.length > 0 ? '35em' : '0em'}
					/>
				</Box>
			</Box>
		);
	}

	render() {
		return (
			<ViewContainer title={this.props.params.id ? 'Ändra matchad dubblett ' : 'Ny matchad dubblett'}>
				<Box
					sx={{
						display: { sm: 'flex-inline', md: 'flex', lg: 'flex', xl: 'flex' },
						height: { sm: 'auto', md: '100%', lg: '100%', xl: '100%' },
						flexDirection: 'row',
						width: { sm: '100%', md: '100%', lg: '100%', xl: '100%' },
						// background: { xs: 'red', md: 'green', lg: 'blue', xl: 'yellow' },
					}}
				>
					<LoadingContainer
						enabled={this.state.categories.length == 0}
						className={classes.top}
					>
						<Box>{this.renderProduct()}</Box>
						<Box>{this.renderMatchingProducts()}</Box>
					</LoadingContainer>

					<Snackbar
						open={!!this.state.snackbar}
						message={this.state.snackbar}
						autoHideDuration={3000}
						onClose={() => this.setState({ snackbar: '' })}
					/>
					<Dialog
						open={!!this.state.deletesnackbar}
						onClose={() => this.setState({ snackbar: '' })}
					>
						<DialogTitle>{this.state.deletesnackbar}</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={this.onCancelDeleteClick.bind(this)}
							>
								Avbryt
							</Button>
							<Button onClick={this.onConfirmDeleteClick.bind(this)}>Ta bort</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={!!this.state.deleteconfirmeddialog}
						actions={[
							<Button
								primary="true"
								onClick={this.onConfirmedDeleteProductClick.bind(this)}
							>
								Ok
							</Button>,
						]}
						onClose={() => this.setState({ snackbar: '' })}
					>
						<DialogTitle>{this.state.deleteconfirmeddialog}</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={this.onConfirmedDeleteProductClick.bind(this)}
							>
								Ok
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={!!this.state.mergesnackbar}
						onClose={() => this.setState({ snackbar: '' })}
					>
						<DialogTitle>{this.state.mergesnackbar}</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={() => {
									this.setState({ mergesnackbar: '' });
								}}
							>
								Avbryt
							</Button>
							<Button onClick={this.onConfirmMergeClick.bind(this)}>Slå ihop</Button>
						</DialogActions>
					</Dialog>
				</Box>
				<Box className="buttons">
					<Button
						onClick={this.onCancelClick.bind(this)}
						variant="outlined"
					>
						Tillbaka
					</Button>

					<Button
						disabled={this.state.loading || this.state.savingProduct || !this.isFieldsValid()}
						onClick={this.onSaveClick.bind(this)}
						variant="contained"
					>
						{this.state.saveButtonText}
					</Button>

					{!!this.props.params.id && (
						<Button
							disabled={false}
							onClick={this.onDeleteClick.bind(this)}
							variant="contained"
						>
							Ta bort
						</Button>
					)}

					{this.canBeMerged() && (
						<Button
							variant="contained"
							color="success"
							onClick={this.onMergeProductClick.bind(this)}
						>
							Slå ihop produkt
						</Button>
					)}

					{!!this.props.params.id && (
						<Button
							variant="outlined"
							onClick={this.onSourcesClick.bind(this)}
						>
							Butikprodukter
						</Button>
					)}
				</Box>
			</ViewContainer>
		);
	}
}

export default withRouter(ProductsDuplicates);
