import { Autocomplete, Box, Button, Snackbar, TextField } from '@mui/material';
import React from 'react';
import BaseComponent from '../../../base';
import StoresChip from '../../../components/stores/StoresChip';
import Backend from '../../../services/Backend';
import BackendMPK2 from '../../../services/BackendMPK2';
import ViewContainer from '../../../view';

class NewProductBag extends BaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			productBag: {
				clientId: '',
				name: '',
				description: '',
				isPublic: false,
			},
			clients: [],
			stores: [],
			loading: false,
			invalidForm: ['client', 'name', 'description'],
			snackbar: '',
			deleteSnackbar: '',
		};

		Backend.fetchClients()
			.then((data) => {
				this.setState({
					clients: data.map((x) => ({ id: x._key, label: x.namn })),
				});
			})
			.catch(() => {
				this.setState({ snackbar: 'Gick inte att hämta klienter.' });
			});
	}

	/*


  */

	onSave() {
		this.setLoading(true);
		const produktsamling = {
			namn: this.state.productBag.name,
			info: this.state.productBag.description,
			klientKey: this.state.productBag.clientId,
		};

		const listOfStoreKeys = this.state.selectedStores.map((s) => s._key);
		/// console.log('%c⧭', 'color: #5200cc', this.state.);
		Backend.createNewProductBag(produktsamling, listOfStoreKeys)
			.then((response) => {
				if (response._key) {
					BackendMPK2.put(`produktsamling/${response._key}/butiker`, listOfStoreKeys)
						.then((response) => {
							console.log('%c⧭', 'color: #40fff2', 'RESPONSE 2: ', response);
						})
						.catch((err) => {
							console.error('Kunde inte hämta alla produktsamlingar.', err);
						});
				} else {
					console.log('%c⧭', 'color: #40fff2', 'RESPONSE 2: ', response);
				}
			})
			.finally(() => {
				this.redirect('/productmonitoring/productbags');
			});

		// Backend.createNewProductBag(produktsamling).then((response) =>
		//   this.redirect('/productmonitoring/productbags'),
		//   () => this.setState({ snackbar: 'Gick inte att spara informationen om kassen.' }),
		//   this.setLoading(false));

		//   BackendMPK2.put(`produktsamling/${produktsamling._key}`, ).then((response) => {
		//     console.log('%c⧭', 'color: #40fff2', "RESPONSE 2: ", response);
		//   });
	}

	onBagInfoUpdated(event, type) {
		let value = event;
		if (type === 'client') {
			this.setState({
				productBag: {
					...this.state.productBag,
					clientId: event && event.id,
					client: event,
				},
			});
		} else {
			value = event.target.value;
			this.setState({
				productBag: { ...this.state.productBag, [type]: value },
			});
		}
		this.isInvalid(type, value);
	}

	isInvalid(type, value) {
		if (value === 0 || (value === '' && !this.state.invalidForm.includes(type))) {
			this.setState({
				invalidForm: [...this.state.invalidForm, type],
			});
		} else if (value !== 0 || (value !== '' && this.invalidForm.includes(type))) {
			const newData = this.state.invalidForm.filter((item) => type !== item);
			this.setState({
				invalidForm: newData,
			});
		}
	}

	setLoading(value) {
		this.setState({ loading: value });
	}

	selectedStores = (stores) => {
		this.setState((prevState) => {
			return { selectedStores: stores };
		});
	};

	render() {
		return (
			<ViewContainer title="Skapa ny produktkasse">
				<Box
					className="flexColumn padding"
					sx={{ gap: '1em', display: 'flex', flexDirection: 'column', height: '100%' }}
				>
					<Box sx={{ gap: '1em', display: 'flex', flexDirection: 'column', height: '100%', width: '20%' }}>
						{this.state.clients && (
							<Autocomplete
								id="combo-box-clients"
								value={this.state.client}
								options={this.state.clients}
								onChange={(_, item) => {
									this.onBagInfoUpdated(item, 'client');
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										error={!this.state.clientId}
										label="Välj klient"
									/>
								)}
							/>
						)}
						{this.state.stores && (
							<StoresChip
								selectedstores={this.state.selectedStores || []}
								onChange={this.selectedStores}
							></StoresChip>
						)}
						<TextField
							label="Namn"
							multiLine
							value={this.state.productBag.name || ''}
							onChange={(e) => this.onBagInfoUpdated(e, 'name')}
							error={!this.state.productBag.name}
						/>
						<TextField
							label="Info"
							rows={4}
							multiline
							value={this.state.productBag.description || ''}
							onChange={(e) => this.onBagInfoUpdated(e, 'description')}
							error={!this.state.productBag.description}
						/>
					</Box>
					<Box className="buttons">
						<Button
							variant="outlined"
							onClick={() => this.redirect('/productmonitoring/productbags')}
						>
							Avbryt
						</Button>
						<Button
							variant="contained"
							primary="true"
							onClick={() => this.onSave()}
							disabled={this.state.loading || this.state.invalidForm.length > 0}
						>
							Spara
						</Button>
					</Box>
				</Box>
				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
			</ViewContainer>
		);
	}
}

export default NewProductBag;
