import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import React from 'react';
import Backend from '../../services/Backend';

class MarksList extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		marks: [],
	};

	componentDidMount() {
		this.setMarks();
	}

	setMarks() {
		this.getMarks().then((marks) => {
			this.setState({
				marks,
			});
		});
	}

	async getMarks() {
		if (this.state.marks.length > 0) {
			return this.state.marks;
		}
		return await Backend.fetchEgenskaper();
	}

	onChecked(key, checked) {
		this.setState({
			selectedMarks: this.props.selectedMarks,
		});
		this.props.onChange && this.props.onChange(key, checked);
	}

	isSelected(key) {
		if (!this.props.selectedMarks) {
			return false;
		}
		return this.props.selectedMarks.includes(key);
	}

	render() {
		return (
			<div style={{ width: '100%' }}>
				{/* <Typography sx={{ margin: '0em' }}>Egenskaper</Typography> */}
				<FormGroup sx={{ margin: '0.5em' }}>
					{this.state.marks.map((x) => {
						return (
							<FormControlLabel
								key={`marklabel_${x.key}`}
								label={x.namn}
								control={
									<Checkbox
										size="small"
										sx={{ padding: '0.2em' }}
										key={x.key}
										onChange={(e) => this.onChecked(x.key, e.target.checked)}
										checked={this.isSelected(x.key)}
									/>
								}
							/>
						);
					})}
				</FormGroup>
			</div>
		);
	}
}

export default MarksList;
