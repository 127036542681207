import { Box, Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import dateformat from 'dateformat';
import React from 'react';

import { useParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';

import DataGrid from '../../../components/datagrid/DataGrid';
import { storesTblStyle } from '../../../TableGridCssOverride';

// import './CrawlInfo.module.css';
import classes from './CrawlInfo.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class CrawlInfo extends BaseComponent {
	constructor(props) {
		super(props);
		if (props.params.id) {
			Backend.fetchCrawl(props.params.id).then((crawl) => {
				console.info(crawl);
				this.setState({
					loading: false,
					crawl,
				});
				this.fetchCrawlStores(crawl._key);
			});
		} else {
			this.state = Object.assign(this.state, {
				loading: false,
			});
		}
	}

	fetchCrawlStores(crawlKey) {
		this.setState({ loadingstores: true });
		Backend.fetchCrawlStores(crawlKey || this.state.crawl._key).then((crawlstores) => {
			console.info(crawlstores);
			this.setState({
				loadingstores: false,
				crawlstores,
			});
		});
	}

	state = {
		crawl: {},
		crawlstores: [],
		loading: true,
		loadingstores: false,
		snackbar: '',
		columns: [
			{
				id: 'butik.key',
				name: 'Key',
				resposive: false,
				selector: (row) => row.butik.key,
				sortable: true,
				grow: 1,
			},
			{
				id: 'butik.namn',
				name: 'Butik',
				selector: (row) => row.butik.namn,
				sortable: true,
				grow: 1,
			},
			{
				id: 'hits',
				name: 'Hittade butikprodukter',
				resposive: true,
				selector: (row) => row.hits,
				format: (row) => this.formatNumber(row.hits),
				sortable: true,
				grow: 0,
				width: '25%',
			},
		],
	};

	formatNumber(x) {
		if (!x) return 0;
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	render() {
		const crawl = this.state.crawl;
		const crawStoresInitated = this.state.loadingstores || this.state.crawlstores.length > 0;
		const total = (crawl.success || 0) + (crawl.errors || 0);
		storesTblStyle.table.style.width = '100%';
		return (
			<ViewContainer title="Körningsinfo">
				<LoadingContainer
					enabled={this.state.loading}
					className={classes.crawlInfoContainer}
				>
					<Box className={classes.top}>
						<Box sx={{ minWidth: '10%', maxWidth: '20%', paddingRight: '3em' }}>
							<p>
								<strong>Namn:</strong> {crawl.spindelNamn}
								<br />
								<strong>Skapad:</strong> {dateformat(crawl.created, 'yyyy-mm-dd HH:MM:ss')}
								<br />
								<strong>Uppdaterad:</strong> {dateformat(crawl.updated, 'yyyy-mm-dd HH:MM:ss')}
								<br />
								<strong>Avslutad:</strong> {dateformat(crawl.avslutad, 'yyyy-mm-dd HH:MM:ss')}
							</p>
							<p>
								<strong>Förlopp:</strong> {crawl.forloppProcent} %<br />
							</p>
							<p>
								---
								<br />
								<br />
								<strong>Hittade ButikProdukter:</strong> {this.formatNumber(crawl.totaltButikProdukter)}
								<br />
								{/* <strong>Nya ButikProdukter:</strong> {crawl.nyaButikProdukter} */}
								<br />
								<strong>Hittade priser:</strong> {this.formatNumber(crawl.totaltPriser)}
								<br />
								{/* <strong>Nya/uppdaterade priser:</strong> {crawl.nyaUppdateradePriser} */}
							</p>
							{crawl.error && (
								<p>
									<strong>Felmeddelande:</strong>
									<pre>{crawl.error}</pre>
								</p>
							)}
						</Box>
						<Box sx={{ minWidth: '45%', maxWidth: '45%', width: 'auto', height: 'auto' }}>
							<h3>{`Butiker (${this.state.crawlstores.length})`}</h3>
							{crawStoresInitated && (
								<DataGrid
									sx={{ marginTop: '-4em' }}
									title={''}
									data={this.state.crawlstores}
									columns={this.state.columns}
									defaultSortFieldId={'butik.namn'}
									customStyles={storesTblStyle}
									loading={this.state.loadingstores}
									showPagination={false}
									subHeader={false}
								/>
							)}
							{!crawStoresInitated && (
								<Button
									variant="outlined"
									primary="true"
									onClick={this.fetchCrawlStores.bind(this)}
								>
									Ladda butiker
								</Button>
							)}
						</Box>
					</Box>
				</LoadingContainer>
				<Box className="buttons">
					<Button
						variant="contained"
						primary="true"
						onClick={this.back.bind(this)}
					>
						Tillbaka
					</Button>
				</Box>

				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
			</ViewContainer>
		);
	}
}

export default withRouter(CrawlInfo);
