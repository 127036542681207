import React from 'react';
import { defaultTblStyle } from '../../../TableGridCssOverride';
import BaseComponent from '../../../base';
import DataGrid from '../../../components/datagrid/DataGrid';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
class CategoryList extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchCategoriesWithParent(false).then((data) => {
			var sorted = data.sort((a, b) =>
				a.kategoriNiva > b.kategoriNiva ? 1 : a.kategoriNiva === b.kategoriNiva ? (a.namn > b.namn ? 1 : -1) : -1,
			);
			const list = [];
			sorted
				.filter((x) => x.kategoriNiva == 1)
				.forEach((p) => {
					p.huvudkategori = p.namn;
					list.push(p);
					this.removeFromList(sorted, p);
					sorted
						.filter((c) => c.foralderKey == p._key)
						.forEach((c) => {
							c.underkategori = c.namn;
							list.push(c);
							this.removeFromList(sorted, c);
							sorted
								.filter((pg) => pg.foralderKey == c._key)
								.forEach((pg) => {
									pg.produktgrupp = pg.namn;
									list.push(pg);
									this.removeFromList(sorted, pg);
								});
						});
				});
			sorted.forEach((r) => {
				// the rest have no parent
				const names = ['-', '-', '-'];
				names[r.kategoriNiva - 1] = r.namn;
				r.huvudkategori = names[0];
				r.underkategori = names[1];
				r.produktgrupp = names[2];
				list.push(r);
			});
			this.setState({
				loading: false,
				categories: list,
			});
		});
	}

	removeFromList(list, item) {
		const index = list.indexOf(item);
		if (index >= 0) {
			list.splice(index, 1);
		}
	}

	state = {
		loading: true,
		categories: [],
		columns: [
			{
				id: '_key',
				name: 'Key',
				resposive: false,
				selector: (row) => row._key,
				wrap: true,
				sortable: true,
				width: '25%',
				maxWidth: '35%',
				grow: 1,
			},
			{
				id: 'kategoriNiva',
				name: 'Nivå',
				grow: 0,
				responseive: false,
				selector: (row) => row.kategoriNiva,
				wrap: true,
				sortable: true,
				width: '5%',
				maxWidth: '10%',
				center: true,
			},
			{
				id: 'huvudkategori',
				name: 'Huvudkategori',
				selector: (row) => row.huvudkategori,
				wrap: true,
				sortable: true,
				width: '10%',
				maxWidth: '15%',
				grow: 1,
			},
			{
				id: 'underkategori',
				name: 'Underkategori',
				selector: (row) => row.underkategori,
				wrap: true,
				sortable: true,
				grow: 1,
				width: '10%',
				maxWidth: '15%',
			},
			{
				id: 'produktgrupp',
				name: 'Produktgrupp',
				selector: (row) => row.produktgrupp,
				wrap: true,
				sortable: true,
				maxWidth: '40%',
				grow: 1,
			},
			{
				id: 'antalProdukter',
				name: 'Produkter',
				resposive: false,
				selector: (row) => row.antalProdukter,
				wrap: true,
				sortable: true,
				grow: 0,
				width: '10%',
			},
		],
	};

	onClick(category) {
		this.redirect(`/categories/${category._key}`);
	}

	render() {
		defaultTblStyle.table.style.minHeight = '70vh';

		return (
			<ViewContainer title="Kategorier">
				<DataGrid
					title={'Kategorier'}
					columns={this.state.columns}
					data={this.state.categories}
					onClick={this.onClick.bind(this)}
					loading={this.state.loading}
					showPagination={false}
					customStyles={defaultTblStyle}
					filterKey={['huvudkategori', 'underkategori', 'produktgrupp']}
				/>
			</ViewContainer>
		);
	}
}

export default CategoryList;
