import { Autocomplete, Box, Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import _ from 'lodash';
import React from 'react';
import { brandvariationsTblStyle } from '../../../TableGridCssOverride';
import BaseComponent from '../../../base';
import DataGrid from '../../../components/datagrid/DataGrid';
import Backend from '../../../services/Backend';
// import './BrandVariations.module.css';
import classes from './BrandVariations.module.css';

class BrandVariations extends BaseComponent {
	title = '';
	state = {
		loading: false,
		variationList: [],
		brandsMasterList: [],
		selectedVariation: {},
		selectedBrand: [],
		checked: false,
		open: false,
		openEditBrand: false,
		openEditVariation: false,

		columns: [
			// { key: '_key', name: 'Key', resposive: false },
			{
				id: 'varumarkeVariantNamn',
				name: 'Variationer',
				selector: (row) => row.varumarkeVariantNamn,
				sortable: true,
				cell: (row, index, column, id) => (
					<span
						data-tag="allowRowEvents"
						className={classes.variation + index}
						style={{
							display: 'inline-block',
							padding: '1em',
							width: '30%',
							maxWidth: '40%',
						}}
						onClick={() => this.edit(row, 'varumarkeVariantNamn')}
						onMouseOver={(e) => {
							const el = e.target;

							el.style.cursor = 'pointer';
							el.style.background = row.varumarkeVariantNamn ? '#FC7878F5' : '';
						}}
						onMouseOut={(e) => {
							const el = e.target;
							el.style.cursor = 'pointer';
							el.style.background = '';
						}}
					>
						{row.varumarkeVariantNamn}
					</span>
				),
			},
			{
				id: 'varumarkeParentNamn',
				name: 'Varumärken',
				selector: (row) => row.varumarkeParentNamn,
				sortable: true,
				cell: (row, index, column, id) => (
					<span
						className={classes.brand + index}
						style={{
							display: 'inline-block',
							padding: '1em',
							width: '30%',
							maxWidth: '40%',
						}}
						onClick={() => this.edit(row, 'varumarkeParentNamn')}
						onMouseOver={(e) => {
							const el = e.target;
							el.style.cursor = 'pointer';
							el.style.background = row.varumarkeParentNamn ? '#FC7878F5' : '';
						}}
						onMouseOut={(e) => {
							const el = e.target;
							el.style.cursor = 'pointer';
							el.style.background = '';
						}}
					>
						{row.varumarkeParentNamn}
					</span>
					// <Button disableElevation color="inherit" variant="string" onClick={() => this.edit(row, 'varumarkeParentNamn')}>
					//   {row.varumarkeParentNamn}
					// </Button>
				),
			},
			{
				id: 'connect',
				name: '',
				cell: (row, index, column, id) => (
					<Button
						disableElevation
						variant="text"
						size="small"
						onClick={() => this.onRowClick(row)}
					>
						Koppla
					</Button>
				),
				width: '10%',
				maxWidth: '20%',
				grow: 0,
			},
		],
	};

	constructor(props) {
		super(props);
		this.title = props.title;

		if (_.isEmpty(this.state.variationList) && !_.isEmpty(this.props.variations)) {
			this.state.variationList = this.props.variations;
		}
		if (_.isEmpty(this.state.brandsMasterList) && !_.isEmpty(this.props.brands)) {
			this.state.brandsMasterList = this.props.brands;
		}
		this.handleClick = this.handleClick.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		// this.setState({ loading: true });

		// if(_.isEmpty(this.state.brands)) {this.fetchBrandVariations();}
	}

	onSaveVariationBrandConnection = () => {
		const { selectedVariation, selectedBrand } = this.state;
		const payload = {
			varumarkeFromKeys: [],
			varumarkeToKey: '',
		};

		payload.varumarkeFromKeys.push(selectedBrand.varumarkeVariantKey);
		payload.varumarkeToKey = selectedVariation.varumarkeParentKey;
		Backend.setParentForVariant(payload).then((response) => {
			if (response.success) {
				selectedVariation.varumarkeParentKey = selectedBrand.varumarkeParentKey;
				selectedVariation.varumarkeParentNamn = selectedBrand.varumarkeParentNamn;

				this.setState({
					open: false,
					selectedVariation: [...this.state.variationList, selectedVariation],
				});
				this.onUpdateItems();
			}
		});
	};

	onUpdateItems = () => {
		this.setState((state) => {
			const list = state.variationList.map((item) => {
				if (item.varumarkeParentKey === state.selectedBrand.varumarkeParentKey) {
					item = { ...state.selectedBrand };
				}
				return item;
			});
			state.variationList = [...list];
		});
	};

	onSaveBrand = () => {
		const { selectedBrand } = this.state;
		const payload = {
			_key: selectedBrand.varumarkeParentKey,
			namn: selectedBrand.varumarkeParentNamn,
			master: true,
			MySQLOldId: 0,
		};

		Backend.updateVarumarke(payload).then((response) => {
			if (response.success) {
				// Uppdatera leverantör med
				this.setState({
					openEditBrand: false,
					selectedBrand: this.state.selectedBrand,
				});
				//  this.onUpdateItems();
			}
		});
	};

	onSaveVariation = () => {
		const { selectedVariation } = this.state;
		const payload = {
			_key: selectedVariation.varumarkeVariantKey,
			namn: selectedVariation.varumarkeVariantNamn,
			master: this.state.checked,
			MySQLOldId: 0,
		};

		Backend.updateVarumarke(payload).then((response) => {
			if (response.success) {
				this.setState({
					openEditVariation: false,
					selectedVariation: this.selectedVariation,
				});

				this.setState({ ...this.state, openEditVariation: false });
				this.onUpdateItems();
			}
		});
	};

	updateCheck() {
		this.setState((oldState) => {
			return {
				checked: !oldState.checked,
			};
		});
	}

	edit(e, key) {
		if (key === 'varumarkeVariantNamn') {
			this.editVariation(e);
		}
		if (key === 'varumarkeParentNamn') {
			this.editBrand(e);
		}
	}

	editVariation(e) {
		const data = {
			varumarkeVariantNamn: e.varumarkeVariantNamn,
			varumarkeVariantKey: e.varumarkeVariantKey,
			master: e.master,
		};
		this.openEditVariation = !this.openEditVariation;
		this.setState({
			openEditVariation: this.openEditVariation,
			selectedVariation: data,
			checked: data.master,
		});
	}

	editBrand(e) {
		const data = {
			varumarkeParentNamn: e.varumarkeParentNamn,
			varumarkeParentKey: e.varumarkeParentKey,
		};
		this.openEditBrand = !this.openEditBrand;
		this.setState({ openEditBrand: true, selectedBrand: data });
	}

	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	onRowClick = (selectedVariation) => {
		this.setState({
			open: true,
			selectedBrand: selectedVariation,
			selectedVariation,
		});
		this.openEditVariation = !this.openEditVariation;
	};

	handleClose = () => {
		this.setState({
			open: false,
			openEditBrand: false,
			openEditVariation: false,
			selectedBrand: {},
			selectedVariation: {},
		});
		this.openEditVariation = false;
	};

	setProperty(target, key, value) {
		target[key] = value;
		this.setState({ brand: target });
	}

	render() {
		const {
			variationList,
			selectedVariation,
			currentPage,
			selectedBrand,
			brandsMasterList,
			itemsPerPage,
			openEditBrand,
			openEditVariation,
		} = this.state;
		const indexOfLastItem = currentPage * itemsPerPage;
		const indexOfFirstItem = indexOfLastItem - itemsPerPage;

		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: (option) => option.namn,
		});

		return (
			<div className={classes.variations}>
				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
				>
					<DialogTitle>Koppla varumärke till variation</DialogTitle>
					<DialogContent>
						<Box>
							<InputLabel id="variationinput">Variation</InputLabel>

							<TextField
								labelid="variationinput"
								sx={{ width: '100%' }}
								value={selectedVariation.varumarkeVariantNamn || ''}
								id="variationName"
								onChange={(evt) => {
									selectedVariation.varumarkeVariantNamn = evt.target.value;
									this.setState({ selectedVariation });
									evt.preventDefault();
								}}
								variant="standard"
								errortext={selectedVariation.varumarkeVariantNamn ? '' : 'Namnet får inte vara tomt'}
							/>
						</Box>
						<Box sx={{ marginTop: '10px' }}>
							<InputLabel id="varumarkeselect">Varumärke</InputLabel>

							<Autocomplete
								labelId="varumarkeselect"
								sx={{ width: '100%' }}
								value={selectedBrand.varumarkeParentNamn}
								options={brandsMasterList.map((option) => {
									return {
										label: option.namn,
										namn: option.namn,
										key: option._key,
									};
								})}
								filterOptions={filterOptions}
								onChange={(e, value) => {
									selectedBrand.varumarkeParentNamn = value && value.namn;
									selectedBrand.varumarkeParentKey = value && value.key;
									this.setState({ selectedBrand });
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										type="search"
									/>
								)} // TODO:Fixa sparning
							/>
						</Box>
					</DialogContent>
					{}
					<DialogActions>
						<Button onClick={this.handleClose}>Avbryt</Button>

						<Button
							variant="contained"
							color="primary"
							disabled={_.isEmpty(selectedBrand.varumarkeParentNamn)}
							onClick={this.onSaveVariationBrandConnection}
						>
							Koppla Variation/Varumärke
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={openEditVariation}
					onClose={this.handleClose}
				>
					<DialogTitle>Redigera variation</DialogTitle>
					<DialogContent>
						<TextField
							value={selectedVariation.varumarkeVariantNamn || ''}
							id="editVarumarkeVariant"
							onChange={(evt) => {
								//  selectedVariation.varumarkeVariantNamn = e.target.value;

								selectedVariation.varumarkeVariantNamn = evt.target.value;
								this.setState({ selectedVariation });
								variationList.find(
									(v) => v.varumarkeVariantKey === selectedVariation.varumarkeVariantKey,
								).varumarkeVariantNamn = evt.target.value;
								evt.preventDefault();
							}}
							label={'Variation'}
							variant="standard"
							errortext={selectedVariation.varumarkeVariantNamn ? '' : 'Namnet får inte vara tomt'}
						/>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.checked}
										onChange={this.updateCheck.bind(this)}
									/>
								}
								label="Lägg till i masterlistan"
							/>
						</FormGroup>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>Avbryt</Button>

						<Button
							variant="contained"
							disabled={_.isEmpty(selectedVariation.varumarkeVariantNamn)}
							onClick={this.onSaveVariation}
						>
							Spara
						</Button>
					</DialogActions>
				</Dialog>

				{
					<Dialog
						open={openEditBrand}
						onClose={this.handleClose}
					>
						<DialogTitle>Redigera varumärke</DialogTitle>
						<DialogContent>
							<TextField
								value={selectedBrand.varumarkeParentNamn || ''}
								id="brandname"
								onChange={(evt) => {
									selectedBrand.varumarkeParentNamn = evt.target.value;
									this.setState({ selectedBrand });
									variationList.find(
										(v) => v.varumarkeParentKey === selectedBrand.varumarkeParentKey,
									).varumarkeParentNamn = evt.target.value;
									evt.preventDefault();
								}}
								label={'Varumärke'}
								variant="standard"
								errortext={selectedBrand.varumarkeParentNamn ? '' : 'Namnet får inte vara tomt'}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								primary="true"
								onClick={this.handleClose}
							>
								Avbryt
							</Button>

							<Button
								variant="contained"
								disabled={_.isEmpty(selectedBrand.varumarkeParentNamn)}
								onClick={this.onSaveBrand}
							>
								Spara
							</Button>
						</DialogActions>
					</Dialog>
				}

				<DataGrid
					columns={this.state.columns}
					data={variationList}
					pagination
					defaultSortFieldId={'varumarkeVariantNamn'}
					sortDirection={'asc'}
					loading={this.state.loading}
					filterKey={['varumarkeVariantNamn', 'varumarkeParentNamn']}
					// filterKey={['varumarkeVariantNamn', 'varumarkeParentNamn']}
					title={'Variation varumärke koppling'}
					customStyles={brandvariationsTblStyle}
				></DataGrid>
			</div>
		);
	}
}

export default BrandVariations;
