import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React from 'react';

import { Box, Select } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import CategoryTree from '../../../components/categorytree/CategoryTree';
import ResultList from '../../../components/result/ResultList';
import SortButtons from '../../../components/sortbuttons/SortButtons';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
import classes from './ProductSearch.module.css';

const withRouter = (WrappedComponent) => (props) => {
	let [searchParams, setSearchParams] = useSearchParams();

	let params = {
		q: searchParams.get('q'),
		m: searchParams.get('m'),
		c: searchParams.get('c'),
		sp: searchParams.get('sp'),
		so: searchParams.get('so'),
		owpg: searchParams.get('owpg'),
	};

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class ProductSearch extends BaseComponent {
	constructor(props) {
		super(props);
		this.debounceTime = 1000;
		this.state = {
			searchText: props.params.q || '',
			searchManufacturer: props.params.m || '',
			searchChain: 0,
			searchResult: [],
			searchPage: 1,
			progressVisible: false,
			allResultsFetched: false,
			categoryId: null,
			chains: [],
			sortProperties: ['Namn', 'Senast sedd'],
			currentSortProperty: -1,
			sortOrder: props.params.so || 0,
			onlyWithoutProduktGrupp: props.params.owpg || false,
		};
		Backend.fetchBlock().then((chainNames) => {
			const blockNames = chainNames.map((cn) => cn.namn);
			this.setState({
				chains: chainNames,
			});
		});

		// NOTE: this is a hack to make sure the search is performed after the component has been mounted and the query parameters are set in the URL (if any) - otherwise the search will be performed before the query parameters are set and the search will be empty (since the query parameters are used in the search) - this is a hack since the search should be performed in the componentDidUpdate method instead of here (but that method is not called when the component is mounted)
		// setTimeout(() => {
		//   this.searchProducts(props.location.query.q || '', props.location.query.m || '', props.location.query.c, this.state.currentSortProperty, this.state.sortOrder, props.location.query.owpg);
		// });

		setTimeout(() => {
			this.searchProducts(
				props.params.q || '',
				props.params.m || '',
				props.params.c,
				this.state.currentSortProperty,
				this.state.sortOrder,
				props.params.owpg,
			);
		});
	}

	/*
    old debounce didnt seem to work, changed to lodash debounce instead as a quickfix
  */
	searchProducts = debounce(
		(freeText, manufacturerText, chainValue, newSortProperty, newSortOrder, onlyWithoutProduktGrupp, page = 1) => {
			this.setState({
				searchText: freeText || '',
				searchManufacturer: manufacturerText,
				searchChain: !isNaN(chainValue) ? parseInt(chainValue) : chainValue,
				progressVisible: true,
				currentSortProperty: newSortProperty,
				sortOrder: newSortOrder,
				onlyWithoutProduktGrupp: !!onlyWithoutProduktGrupp,
				searchResult:
					page < 2 ||
					freeText !== this.state.searchText ||
					manufacturerText !== this.state.searchManufacturer ||
					chainValue !== this.state.searchChain ||
					newSortProperty !== this.state.currentSortProperty ||
					newSortOrder !== this.state.sortOrder
						? [...this.state.searchResult]
						: this.state.searchResult,
			});

			const chainIndex = this.state.searchChain > 0 ? this.state.searchChain - 1 : null;
			const chain = chainIndex != null && this.state.chains.length > 0 ? this.state.chains[chainIndex].kod : null;

			if (!freeText && !manufacturerText && !this.state.categoryId && !onlyWithoutProduktGrupp) {
				this.setState({
					searchResult: [],
					searchPage: 1,
					progressVisible: false,
					onlyWithoutProduktGrupp: false,
				});

				return;
			}

			Backend.fetchSearchResults(
				freeText || '',
				manufacturerText,
				this.state.categoryId,
				null,
				chain,
				true,
				page || 1,
				newSortProperty,
				newSortOrder,
				!!onlyWithoutProduktGrupp,
			)
				.then((response) => {
					if (response.length === 0 && page) {
						this.setState({
							progressVisible: false,
							allResultsFetched: true,
						});
					} else {
						const searchResult = this.state.searchResult.concat(response);
						this.setState({
							searchResult,
							searchPage: page || 1,
							progressVisible: false,
							allResultsFetched: false,
						});
					}
				})
				.catch((err) => {
					this.setState({
						progressVisible: false,
					});
					console.error(err);
				});
		},
		300,
	);

	onScrollToBottom() {
		if (!this.state.allResultsFetched) {
			const newPage = this.state.searchPage + 1;
			this.searchProducts(
				this.state.searchText,
				this.state.searchManufacturer,
				this.state.searchChain,
				this.state.currentSortProperty,
				this.state.sortOrder,
				this.state.onlyWithoutProduktGrupp,
				newPage,
			);
		}
	}

	onSearchFieldUpdated(event, newValue) {
		this.setState(
			{
				searchResult: [],
				searchText: event.target.value,
				searchPage: 1,
			},
			() => {
				this.updateUrl();
				this.searchProducts(
					this.state.searchText,
					this.state.searchManufacturer,
					this.state.searchChain,
					this.state.currentSortProperty,
					this.state.sortOrder,
					this.state.onlyWithoutProduktGrupp,
				);
			},
		);
	}

	onManufacturerUpdated(event, newValue) {
		this.setState(
			{
				searchText: this.state.searchText || '',
				searchResult: [],
				searchManufacturer: event.target.value,
				searchPage: 1,
			},
			() => {
				this.updateUrl();
				this.searchProducts(
					this.state.searchText,
					this.state.searchManufacturer,
					this.state.searchChain,
					this.state.currentSortProperty,
					this.state.sortOrder,
					this.state.onlyWithoutProduktGrupp,
				);
			},
		);
	}

	onChainUpdate(event, newValue) {
		this.setState(
			{
				searchResult: [],
				searchChain: event.target.value,
				searchPage: 1,
			},
			() => {
				this.updateUrl();
				this.searchProducts(
					this.state.searchText,
					this.state.searchManufacturer,
					this.state.searchChain,
					this.state.currentSortProperty,
					this.state.sortOrder,
					this.state.onlyWithoutProduktGrupp,
				);
			},
		);
	}

	onSortUpdate(event) {
		this.setState(
			{
				searchResult: [],
				searchPage: 1,
				currentSortProperty: event.currentSortProperty,
				sortOrder: event.sortOrder,
			},
			() => {
				this.updateUrl();
				this.searchProducts(
					this.state.searchText,
					this.state.searchManufacturer,
					this.state.searchChain,
					event.currentSortProperty,
					event.sortOrder,
					this.state.onlyWithoutProduktGrupp,
				);
			},
		);
	}

	updateUrl() {
		const path = '/products/search?';
		const query = [];
		if (this.state.searchText && this.state.searchText != '') {
			query.push(`q=${this.state.searchText}`);
		}

		if (this.state.searchManufacturer && this.state.searchManufacturer != '') {
			query.push(`m=${this.state.searchManufacturer}`);
		}

		if (this.state.searchChain && this.state.searchChain > 0) {
			query.push(`c=${this.state.searchChain}`);
		}

		if (this.state.currentSortProperty >= 0) {
			query.push(`sp=${this.state.currentSortProperty}`);
			if (this.state.sortOrder > 0) {
				query.push(`so=${this.state.sortOrder}`);
			}
		}

		if (this.state.onlyWithoutProduktGrupp) {
			query.push('owpg=1');
		}

		// console.log('%c⧭', 'color: #40fff2', path + query.join('&'));
		window.history.pushState({}, '', path + query.join('&'));
		// this.redirect(path + query.join('&'));
	}

	onProductSelected(product) {
		console.log(product);
		const type = product.Produkt.produktTyp;
		const key = product.Produkt._key;
		if (type == 2) {
			this.redirect(`/products/duplicates/${key}`);
		} else if (type == 3) {
			this.redirect(`/products/combine/${key}`);
		} else {
			this.redirect(`/products/${key}`);
		}
	}

	onCategoryChanged(categoryId) {
		this.setState({ categoryId, searchResult: [] }, () => {
			this.searchProducts(
				this.state.searchText,
				this.state.searchManufacturer,
				this.state.searchChain,
				this.state.currentSortProperty,
				this.state.sortOrder,
				this.state.onlyWithoutProduktGrupp,
			);
		});
	}

	onCheckChanged(checked) {
		this.setState(
			{
				onlyWithoutProduktGrupp: !!checked,
			},
			() => {
				this.updateUrl();
				this.searchProducts(
					this.state.searchText,
					this.state.searchManufacturer,
					this.state.searchChain,
					this.state.currentSortProperty,
					this.state.sortOrder,
					this.state.onlyWithoutProduktGrupp,
				);
			},
		);
	}

	render() {
		return (
			<ViewContainer
				title="Sök produkt"
				className={classes.productSearch}
			>
				<Box className={classes.tree}>
					<CategoryTree
						selectedId={this.state.categoryId}
						onChange={this.onCategoryChanged.bind(this)}
					/>
				</Box>
				<Box className={classes.results}>
					<div className={classes.filter}>
						<TextField
							variant="standard"
							label="Fritextsök"
							sx={{ width: '30em' }}
							value={this.state.searchText}
							onChange={this.onSearchFieldUpdated.bind(this)}
						/>
						<TextField
							variant="standard"
							label="Varumärke"
							sx={{ width: '30em' }}
							value={this.state.searchManufacturer}
							onChange={this.onManufacturerUpdated.bind(this)}
						/>

						<Select
							label="Block"
							sx={{ width: '30em', margin: '1% 0' }}
							value={this.state.searchChain || 0}
							onChange={this.onChainUpdate.bind(this)}
							variant="standard"
						>
							<MenuItem
								key={'-1'}
								value={0}
							>
								Alla
							</MenuItem>
							{this.state.chains.map((chain, index) => (
								<MenuItem
									key={`${index}`}
									value={index + 1}
								>
									{chain.namn}
								</MenuItem>
							))}
						</Select>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.onlyWithoutProduktGrupp}
										onChange={(e) => {
											this.onCheckChanged(e.target.checked);
										}}
									/>
								}
								label="Endast produkter utan produktgrupp"
							/>
						</FormGroup>

						<SortButtons
							sortProperties={this.state.sortProperties}
							onChange={this.onSortUpdate.bind(this)}
						/>
					</div>
					<Box sx={{ height: '80%', overflow: 'auto' }}>
						<ResultList
							products={this.state.searchResult}
							progressVisible={this.state.progressVisible}
							handleScrollToBottom={this.onScrollToBottom.bind(this)}
							handleProductSelected={this.onProductSelected.bind(this)}
							emptyStateText="Inga resultat hittades"
							subheaderVisible
						/>
					</Box>
				</Box>
			</ViewContainer>
		);
	}
}

export default withRouter(ProductSearch);
