import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import React from 'react';

import LoadingContainer from '../../../../components/loading/LoadingContainer';
import Backend from '../../../../services/Backend';

// import './MappingEdit.module.css';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import BaseComponent from '../../../../base';
import ViewContainer from '../../../../view';
import classes from './MappingEdit.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class CategoryMappingEdit extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchCrawlerCategory(props.params.id)
			.then((category) => {
				this.setState({
					butikkategori: category.butikKategori,
					kategoriKey: (category.kategori && category.kategori._key) || '',
				});
			})
			.then(
				Backend.fetchCategoriesWithParent().then((categories) => {
					const options = categories
						.filter((c) => c.kategoriNiva > 1)
						.map((c) => ({
							id: c._key,
							label: this.getFullCategoryName(c.namn, c, categories),
						}))
						.sort((a, b) => (a.label > b.label ? 1 : -1));
					this.setState({
						categories: options,
						loading: false,
					});
				}),
			);
	}

	getFullCategoryName(categoryName, category, categories) {
		if (category.foralderKey) {
			const parent = categories.find((x) => x._key === category.foralderKey);
			return this.getFullCategoryName(`${parent.namn} → ${categoryName}`, parent, categories);
		}
		return categoryName;
	}

	state = {
		butikkategori: {},
		kategoriKey: '',
		kategori: {},
		chip: [],
		categories: [],
		loading: true,
		snackbar: '',
	};

	onSave() {
		Backend.saveCrawlerCategoryCategory(this.state.butikkategori._key, this.state.kategoriKey).then(
			() => {
				this.back();
			},
			() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen',
				});
			},
		);
	}

	onCancel() {
		this.back();
	}

	setProperty(key, value) {
		const category = this.state.category;
		category[key] = value;
		this.setState({ category });
	}

	render() {
		const butikkategori = this.state.butikkategori;
		const kategoriKey = this.state.kategoriKey;
		const kategori = this.state.categories.find((x) => x.id === kategoriKey);
		return (
			<ViewContainer title="Ändra ihopkoppling">
				<LoadingContainer
					className={classes.crawlerEditContainer}
					enabled={this.state.loading}
				>
					<Box className={classes.top}>
						<TextField
							value={butikkategori.namn || ''}
							label={'Namn'}
							sx={{ width: '50em', paddingBottom: '1em' }}
							disabled
						/>
						{this.state.categories && (
							<Autocomplete
								id="combo-box-cats"
								value={kategori}
								sx={{ width: '50em', paddingBottom: '1em' }}
								options={this.state.categories}
								onChange={(_, item) => {
									this.setState({ kategoriKey: item && item.id });
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Välj kategori"
									/>
								)}
							/>
						)}
					</Box>
					<Box className="buttons">
						<Button
							variant="outlined"
							onClick={this.onCancel.bind(this)}
						>
							Avbryt
						</Button>
						<Button
							variant="contained"
							primary="true"
							onClick={this.onSave.bind(this)}
						>
							Spara
						</Button>
					</Box>
				</LoadingContainer>

				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
			</ViewContainer>
		);
	}
}

export default withRouter(CategoryMappingEdit);
