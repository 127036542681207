import { createBrowserHistory } from 'history';
import React from 'react';

import './base.css';

class BaseComponent extends React.Component {
	constructor(props) {
		super(props);
		this.debounceTimeout = null;
	}
	browserHistory = createBrowserHistory();

	redirect(uri) {
		this.browserHistory.push(uri, { from: 'redirect' });
		this.browserHistory.go();
		// useHref(uri);
	}

	back() {
		this.browserHistory.back();
	}

	isResponsive() {
		const responsiveWidth = parseInt(this.props.responsiveWidth, 10) || 800;
		return window.innerWidth > responsiveWidth;
	}

	debounce(func, wait, immediate) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const context = this;

		return function _() {
			const args = arguments;
			const later = () => {
				context.debounceTimeout = null;
				if (!immediate && func) {
					func.apply(this, args);
				}
			};

			const callNow = immediate && !context.debounceTimeout;
			clearTimeout(context.debounceTimeout);

			context.debounceTimeout = setTimeout(later, wait);
			if (callNow && func) {
				func.apply(this, args);
			}
		};
	}
}

export default BaseComponent;
