import { Edit, OpenInNew } from '@mui/icons-material';
import React from 'react';
import BaseComponent from '../../base';
// import OpenInNew from '@mui/material/svg-icons/action/open-in-new';
// import Edit from '@mui/material/svg-icons/editor/mode-edit';

class ContextMenu extends BaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			targetKey: this.props.key,
			type: this.props.tblType,
			metadata: {},
		};
	}

	componentDidMount() {
		document.addEventListener('contextmenu', this._handleContextMenu, true);
		document.addEventListener('scroll', this._handleScroll);
	}

	componentWillUnmount() {
		document.removeEventListener('contextmenu', this._handleContextMenu, true);
		document.removeEventListener('scroll', this._handleScroll);
	}

	_handleContextMenu = (event) => {
		event.preventDefault();

		let isUnmatchedContext =
			document.getElementById('unmatchedholder') && document.getElementById('unmatchedholder').contains(event.target);

		let isResultsContext = false;
		document.querySelectorAll('ul#resultlist').forEach((x) => {
			if (x.contains(event.target)) {
				isResultsContext = true;
			}
		});

		let isDataTableContext =
			document.getElementById('kombined-data-table') &&
			document.getElementById('kombined-data-table').contains(event.target);

		if (isUnmatchedContext) {
			this.setState({
				type: 'unmatched',
				visible: this.state.visible && this.state.type !== 'unmatched' ? true : !this.state.visible,
				x: event.clientX - 5,
				y: event.clientY + 5,
				targetKey: event.target.getAttribute('data-id'),
			});
		}

		if (isResultsContext || isDataTableContext) {
			let parent = event.target;

			while (parent?.getAttribute('data-for') == null) {
				parent = parent?.parentElement;
				if (parent == null) return;
			}

			this.setState({
				type: 'search',
				visible: this.state.visible && this.state.type !== 'search' ? true : !this.state.visible,
				x: event.clientX - 5,
				y: event.clientY + 5,
				targetKey: parent?.getAttribute('data-for'),
				metadata: parent?.getAttribute('data-produkt-typ'),
			});
		}

		if (!isUnmatchedContext && !isResultsContext && !isDataTableContext) {
			this.setState({ visible: false });
		}
	};

	getProductUrlByType(pType, id) {
		switch (pType) {
			case '0':
			case '1':
				return `/products/${id}`;
			case '2':
				return `/products/duplicates/${id}`;
			case '3':
				return `/products/combine/${id}`;
			default:
				return `/products/unmatched/${id}`;
		}
	}

	_handleClick = (event, type) => {
		let url = '';
		switch (type) {
			case 'unmatched1':
				url = `/products/unmatched`;
				break;
			case 'unmatched2':
				url = `/products/unmatched/${this.state.targetKey}`;
				break;
			case 'unverified1':
				url = `/products/${this.state.targetKey}`;
				break;
			case 'search':
				url = `/products/search`;
				break;
			case 'search1':
				url = this.getProductUrlByType(this.state.metadata, this.state.targetKey);
				break;
			default:
				break;
		}

		if (
			event.target.className === 'context-item' ||
			(event.target.className.baseVal === 'context-icon' && sessionStorage.getItem('TokenKeyForAuthorization'))
		) {
			//	const token = `?token=${sessionStorage.getItem('TokenKeyForAuthorization')}`;
			// window.open(`${url}${token}`);
			window.open(`${url}`);
		}
	};

	_handleScroll = () => {
		const { visible } = this.state;

		if (visible) {
			this.setState({ visible: false });
		}
	};

	renderTContextOptions(type) {
		switch (type) {
			case 'unmatched':
				return (
					<div
						style={{ top: this.state.y, left: this.state.x }}
						className="contextMenu"
					>
						<div className="contextMenu--option">
							<span
								className="context-item"
								onClick={(e) => {
									this._handleClick(e, 'unmatched1');
								}}
							>
								<OpenInNew className={'context-icon'} /> Öppna omatchad i ny flik
							</span>
							<span
								className="context-item"
								onClick={(e) => {
									this._handleClick(e, 'unmatched2');
								}}
							>
								<Edit className={'context-icon'} /> Öppna produkt i ny flik
							</span>
						</div>
					</div>
				);
			case 'search':
				return (
					<div
						style={{ top: this.state.y, left: this.state.x }}
						className="contextMenu"
					>
						<div className="contextMenu--option">
							<span
								className="context-item"
								onClick={(e) => {
									this._handleClick(e, 'search1');
								}}
							>
								<OpenInNew className={'context-icon'} /> Öppna produkt i ny flik
							</span>
						</div>
					</div>
				);
		}
	}

	render() {
		const { visible, type } = this.state;

		return (visible || false) && this.renderTContextOptions(type);
	}
}
export default ContextMenu;
