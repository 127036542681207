import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import BaseComponent from '../../base';

// TODO: Fix this location reload
class ErrorView extends BaseComponent {
	handleClose = () => {
		this.redirect('/');
		// .reload();
	};

	static errors = {
		401: 'Obehörig åtkomst',
		403: 'Du saknar rättigheter att se den här sidan',
		0: 'Testa att ladda om sidan eller försök igen senare',
	};

	render() {
		const actions = [
			<Button
				label={'Ok'}
				primary="true"
				onClick={this.handleClose}
			/>,
		];

		const statusCode = parseInt(this.props.location?.query.code || '0', 10);
		return (
			<div>
				<Dialog
					actions={actions}
					open
				>
					<h3>Ett fel har uppstått</h3>
					{ErrorView.errors[statusCode] || ErrorView.errors[0]}
				</Dialog>
			</div>
		);
	}
}

export default ErrorView;
