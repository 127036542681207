import { ContentCopy, Edit, IosShare } from '@mui/icons-material';
import { Autocomplete, Box, Button, Snackbar, TextField } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { productbagsTblStyle } from '../../../TableGridCssOverride';
import BaseComponent from '../../../base';
import DataGrid from '../../../components/datagrid/DataGrid';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';

const EXPORT_SCHEMA = {
	0: 'Ej Schemalagd',
	1: 'Månadsvis (1:a)',
	2: 'Veckovis (Måndag)',
	3: 'Varje måndag och torsdag',
	4: 'Veckovis (Onsdag)',
	5: 'Veckovis (Tisdag)',
};

class Clients extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchProductBags()
			.then((productBags) => {
				Backend.fetchClients()
					.then((clients) => {
						const allClientsObj = { _key: -1, namn: 'Alla' };
						const withKlient = productBags.map((data) => {
							var ps = data.produktsamling;
							ps.clientKey = data.klient._key;
							ps.clientName = data.klient.namn;
							return ps;
						});
						this.setState({
							clients: [allClientsObj].concat(clients).map((x) => ({ id: x._key, label: x.namn })),
							// loading: false,
							allProductBags: withKlient,
							productBags: withKlient,
						});
					})
					.then(() => {
						Backend.fetchStores().then((stores) => {
							const allStoresObj = { _key: -1, namn: 'Alla' };
							//   const withButik = productBags.map((data) => {
							//     var ps = data.produktsamling;
							//     ps.storeKey = data.butik._key;
							//     ps.storeName = data.butik.namn;
							//     return ps;
							//   });
							this.setState({
								stores: [allStoresObj].concat(stores).map((x) => ({ id: x._key, label: x.namn })),
								loading: false,
							});
						});
					});
			})
			.catch((err) => {
				this.setState({ loading: false });
				console.error('Kunde inte hämta alla produktsamlingar.', err);
			});
	}

	state = {
		loading: true,
		snackbar: '',
		productBags: [],
		allProductBags: [],
		clients: [],
		stores: [],
		searchClient: { id: -1, label: 'Alla' },

		columns: [
			{
				id: '_key',
				name: 'Key',
				selector: (row) => row._key,
				resposive: false,
			},
			{
				id: 'clientName',
				name: 'Klient',
				grow: 1,
				width: '150px',
				minWidth: '150px',
				maxWidth: '200px',
				selector: (row) => row.clientName,
				resposive: false,
			},
			{ id: 'namn', name: 'Namn', selector: (row) => row.namn },
			{
				id: 'info',
				name: 'Beskrivning',
				resposive: false,
				selector: (row) => row.info,
			},
			{
				id: 'exporteras',
				name: 'Exporteras',
				width: '100px',
				resposive: false,
				format: (value) => (value.exporteras ? '✓' : '⤬'),
				selector: (row) => row.exporteras,
				style: { fontWeight: 'bold', fontSize: '1rem' },
				center: true,
				conditionalCellStyles: [
					{
						when: (row) => row.exporteras,
						style: {
							color: 'green',
						},
					},
					{
						when: (row) => !row.exporteras,
						style: {
							color: 'red',
						},
					},
				],
			},
			{
				id: 'exportSchema',
				name: 'Schema',
				width: '200px',
				minWidth: '200px',
				maxWidth: '400px',
				resposive: false,
				selector: (row) => row.exportSchema,
				format: (value) => EXPORT_SCHEMA[value.exportSchema],
			},
			{
				id: 'senastExport',
				name: 'Senast Exp.',
				width: '200px',
				minWidth: '200px',
				maxWidth: '400px',
				resposive: false,
				format: (value) => value.senastExport && moment(value.senastExport).format('YY-MM-DD HH:mm:ss'),
				selector: (row) => row.senastExport,
			},
			{
				id: 'export',
				cell: (productBag) => (
					<Button
						size="small"
						variant="text"
						onClick={() => {
							this.onExport(productBag);
						}}
					>
						<Box sx={{ display: 'flex', gap: '0.1em', alignItems: 'center' }}>
							<IosShare sx={{ height: '0.7em' }} />
							<Box sx={{ marginTop: '0.2em' }}>Exp. nu</Box>
						</Box>
					</Button>
				),
				width: '120px',
				ignoreRowClick: true,
				allowOverflow: false,
				button: true,
			},
			{
				id: 'copy',
				cell: (productBag) => (
					<Button
						size="small"
						variant="text"
						onClick={() => this.copyProductBag(productBag)}
					>
						<Box sx={{ display: 'flex', gap: '0.1em', alignItems: 'center' }}>
							<ContentCopy sx={{ height: '0.7em' }} />
							<Box sx={{ marginTop: '0.2em' }}>Kopiera</Box>
						</Box>
					</Button>
				),
				width: '120px',
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},
			{
				id: 'edit',
				cell: (productBag) => (
					<Button
						size="small"
						variant="text"
						onClick={() => this.onClick(productBag)}
					>
						<Box sx={{ display: 'flex', gap: '0.1em', alignItems: 'center' }}>
							<Edit sx={{ height: '0.7em' }} />
							<Box sx={{ marginTop: '0.2em' }}>Editera</Box>
						</Box>
					</Button>
				),
				width: '120px',
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},
		],
	};

	copyProductBag(productBag) {
		let baseUrl = window.location.pathname;
		if (baseUrl.endsWith('/')) {
			baseUrl = baseUrl.substring(0, baseUrl.length - 1);
		}
		this.redirect(`${baseUrl}/${productBag._key}/copy`);
	}
	updateFilter(client) {
		this.setState({ searchClient: client });
		if (client.id === -1) {
			this.setState({
				productBags: this.state.allProductBags,
			});
		} else {
			this.setState({
				productBags: this.state.allProductBags.filter((productBag) => productBag.clientKey === client.id),
			});
		}
	}

	onClick(productBag) {
		console.log('%c⧭', 'color: #7f2200', productBag);
		let baseUrl = window.location.pathname;
		if (baseUrl.endsWith('/')) {
			baseUrl = baseUrl.substring(0, baseUrl.length - 1);
		}
		this.redirect(`${baseUrl}/${productBag._key}`);
	}

	onExport(productBag) {
		console.log(productBag);
		Backend.triggerProductBagExport(productBag._key);
		this.setState({
			snackbar: `Export triggad för ${productBag.namn}!`,
		});
	}

	render() {
		return (
			<ViewContainer title="Produktsamlingar">
				<div className="padding flexSpaceBetween">
					{this.state.clients && (
						<Autocomplete
							id="combo-box-clients"
							disableClearable={true}
							value={this.state.searchClient}
							options={this.state.clients}
							sx={{ width: '20em' }}
							onChange={(_, item) => {
								this.updateFilter(item);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Filtrera på klient"
								/>
							)}
						/>
					)}
					<Button
						variant="contained"
						primary="true"
						onClick={() => this.redirect('/productmonitoring/productBag/new')}
					>
						Skapa ny
					</Button>
				</div>
				<LoadingContainer enabled={this.state.loading}>
					<DataGrid
						data={this.state.productBags}
						columns={this.state.columns}
						onClick={this.onClick.bind(this)}
						sortBy="clientName"
						sortDirection={'asc'}
						loading={this.state.loading}
						customStyles={productbagsTblStyle}
					/>
					{/* <SortableTable columns={this.state.columns} rows={this.state.productBags} sortBy="clientName" /> */}
				</LoadingContainer>
				<Snackbar
					open={!!this.state.snackbar}
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					message={this.state.snackbar}
					autoHideDuration={5000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
			</ViewContainer>
		);
	}
}

export default Clients;
