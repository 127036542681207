import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BaseComponent from './base';

import { createBrowserHistory } from 'history';
import { AuthProvider, UserManager } from 'oidc-react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CategoryEdit from './containers/categories/edit/CategoryEdit';
import CategoryList from './containers/categories/list/CategoryList';
import CategoryMappingEdit from './containers/categories/mapping/edit/MappingEdit';
import CategoryMappingList from './containers/categories/mapping/list/MappingList';
import CrawlInfo from './containers/crawls/info/CrawlInfo';
import CrawlList from './containers/crawls/list/CrawlList';
import BrandList from './containers/customermanagment/brands/BrandList';
import BrandVariationList from './containers/customermanagment/brandVariations/BrandVariationList';
import ErrorView from './containers/error/Error';
import Home from './containers/home/Home';
import ProductCombine from './containers/product/combine/ProductCombine';
import ProductCombineList from './containers/product/combinelist/ProductCombineList';
import ProductsDuplicates from './containers/product/duplicates/ProductsDuplicates';
import ProductEdit from './containers/product/edit/ProductEdit';
import PotentialDuplicates from './containers/product/potentialduplicates/PotenitalDuplicates';
import ProductSearch from './containers/product/search/ProductSearch';
import ProductSources from './containers/product/sources/ProductSources';
import ProductsUnmatched from './containers/product/unmatched/ProductsUnmatched';
import UnverifiedProducts from './containers/product/unverified/UnverifiedProducts';
import ClientEdit from './containers/productmonitoring/clients/ClientEdit';
import Clients from './containers/productmonitoring/clients/Clients';
import NewProductBag from './containers/productmonitoring/newProductBag/NewProductBag';
import ProductBag from './containers/productmonitoring/productBag/ProductBag';
import ProductBags from './containers/productmonitoring/productBags/ProductBags';
import ScanImportFromFile from './containers/scan/import/ScanImportFromFile';
import ScanChooseStore from './containers/scan/stores/ScanChooseStore';
import StoreEdit from './containers/stores/edit/StoreEdit';
import StoreList from './containers/stores/list/StoreList';
import './index.css';
import BackendMPK2 from './services/BackendMPK2';

let history = createBrowserHistory();

const theme = createTheme({
	palette: {
		primary: {
			main: '#c20016',
		},
		secondary: {
			main: '#888583',
		},
		warning: {
			main: '#f1a44f',
		},
		success: {
			main: '#0b6e4f',
		},
		info: {
			main: '#006693',
		},
		danger: {
			main: '#c75313',
		},
	},
	typography: {
		fontFamily: 'Montserrat, sans-serif',
	},
});

const oidcConfig = {
	authority: 'https://id.matpriskollen.io',
	client_id: BackendMPK2.getAuthClientId(),
	redirect_uri: window.location.origin,
	scope: 'openid email api offline_access',
	automaticSilentRenew: true,
	loadUserInfo: true,
	// silentRequestTimeout: 1000,
	// accessTokenExpiringNotificationTime: 10,
	monitorSession: true,
	autoSignIn: true,
	storeUser: true,
	response_type: 'code',
};

window.userManager = new UserManager(oidcConfig);

if (window.userManager !== undefined) {
	window.userManager.events.addUserLoaded((user) => {
		if (user) {
			window.location.href = localStorage.getItem('redirectUrl') || '/';
		}
		// window.location.href = '/products/search';
	});
}

const routes = createBrowserRouter(
	[
		{
			path: '/',

			children: [
				{ index: true, element: <Home /> },
				{
					path: '/crawls',
					children: [
						{ index: true, element: <CrawlList /> },
						{ path: ':id', element: <CrawlInfo /> },
					],
				},
				{
					path: '/stores',
					children: [
						{ index: true, element: <StoreList /> },
						{ path: 'new', element: <StoreEdit /> },
						{ path: ':id', element: <StoreEdit /> },
					],
				},
				{
					path: '/customermanagment',
					children: [
						{ path: 'brands', element: <BrandList /> },
						{ path: 'brandvariations', element: <BrandVariationList /> },
					],
				},
				{
					path: '/categories',
					children: [
						{ index: true, element: <CategoryList /> },
						{ path: 'mapping', element: <CategoryMappingList /> },
						{ path: 'mapping/:id', element: <CategoryMappingEdit /> },
						{ path: ':id', element: <CategoryEdit /> },
					],
				},

				{
					path: '/scan/fromFile',
					children: [
						{ index: true, element: <ScanChooseStore fromFile /> },
						{ path: ':storeId/import', element: <ScanImportFromFile /> },
					],
				},

				{
					path: '/products',
					children: [
						{ index: true, element: <ProductSearch /> },
						{
							path: 'search',
							element: <ProductSearch />,
						},
						{ path: 'new', element: <ProductEdit /> },
						{ path: 'combine/:id', element: <ProductCombine /> },
						{ path: 'combine', element: <ProductCombine /> },
						{ path: 'combinelist', element: <ProductCombineList /> },
						{ path: 'unverifiedproducts', element: <UnverifiedProducts /> },
						{ path: 'unmatched', element: <ProductsUnmatched /> },
						{ path: 'unmatched/:crawlproductid', element: <ProductEdit /> },
						{ path: 'duplicates', element: <ProductsDuplicates /> },
						{
							path: 'duplicates/:id',
							element: <ProductsDuplicates />,
						},
						{ path: 'potentialduplicates', element: <PotentialDuplicates /> },
						{ path: ':id', element: <ProductEdit /> },
						{ path: ':id/sources', element: <ProductSources /> },
					],
				},
				{
					path: '/productmonitoring',
					children: [
						{ path: 'clients', element: <Clients /> },
						{ path: 'client/new', element: <ClientEdit /> },
						{ path: 'clients/:id', element: <ClientEdit /> },
						{ path: 'productbags', element: <ProductBags /> },
						{ path: 'productbags/:id', element: <ProductBag /> },
						{ path: 'productbags/:id/copy', element: <ProductBag /> },
						{ path: 'productbag/new', element: <NewProductBag /> },
					],
				},
			],
		},
		{
			path: '/error',
			element: <ErrorView />,
		},
	],
	history,
);

class AppWrapper extends BaseComponent {
	render() {
		return (
			<AuthProvider userManager={window.userManager}>
				<CssBaseline />
				<ThemeProvider theme={theme}>
					<RouterProvider router={routes}></RouterProvider>
				</ThemeProvider>
			</AuthProvider>
		);
	}
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<AppWrapper />);
