import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import React from 'react';

import SortableTable from '../../../components/sortabletable/SortableTable';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, FormControlLabel, FormGroup } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useSearchParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import ContextMenu from '../../../components/contextmenu/ContextMenu';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
// import './ProductsUnmatched.module.css';

import classes from './ProductsUnmatched.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	const searchParams = useSearchParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
			searchParams={searchParams[0]}
		/>
	);
};

class ProductsUnmatched extends BaseComponent {
	constructor(props) {
		super(props);

		this.debounceTime = '20em';

		const query = props.searchParams;
		// default ica maxi kungsbacka if no filter is chosen ...
		const storeQuery = (query && query.get('s')) || (query.size === 0 && '840642aa-3820-4bba-9445-08d4985ce060') || '';
		this.state = Object.assign(this.state, {
			notstore: (query && query.get('ns')) || '',
			notcats: (query && query.get('nc')) || '',
			store: storeQuery,
			manufacturer: (query && query.get('m')) || '',
			showGtin: (query && query.get('gtin')) || '',
			showHidden: (query && query.get('hidden')) === 'true',
			searchName: (query && query.get('name')) || '',
		});
	}
	ref = null;

	state = {
		page: 0,
		showGtin: false,
		showHidden: false,
		only2Weeks: false,
		crawledIn30Days: true,
		loading: false,
		store: null,
		notStore: null,
		notCats: null,
		manufacturer: '',
		searchName: '',
		products: [],
		butiker: [],
		categories: [],
		totalSearchItems: 0,
		columns: [
			{
				key: 'butikNamn',
				name: 'Butik',
				width: '20em',
				resposive: false,
				padding: '1px',
			},
			{
				key: 'bildUrl',
				name: 'Bild',
				image: true,
				width: '4em',
				padding: '1px',
			},
			{ key: 'namn', name: 'Namn', width: '20em', padding: '1px' },
			{
				key: 'produktinfo',
				name: 'Information',
				resposive: false,
				padding: '1px',
			},
			{ key: 'varumarke', name: 'Varumärke', resposive: false, padding: '1px' },
			{ key: 'gtin', name: 'GTIN', resposive: false, padding: '1px' },
			{
				key: 'Created',
				name: 'Hittad först',
				resposive: false,
				padding: '1px',
			},
			{
				key: 'currentPrisSpindlingDatum',
				name: 'Senast spindeldatum',
				resposive: false,
				padding: '1px',
			},
			{ key: 'hide', name: '', resposive: false, padding: '1px' },
		],
	};
	componentDidMount() {
		Backend.fetchCategoriesWithParent().then((categories) => {
			const cats = categories
				.map((x) => ({
					...x,
					label: this.getFullCategoryName(x.namn, x, categories),
					id: x._key,
				}))
				.sort((a, b) => (a.label < b.label ? -1 : 1));
			this.setState({
				categories: cats,
			});
		});

		Backend.fetchButiker()
			.then((bs) => {
				const paButiker = bs.filter((b) => b.synligPrisanalys);
				this.setState({
					butiker: paButiker,
				});
			})
			.then(() => {
				this.fetchProducts(this.state.page);
			});
	}

	onHideCrawlProduct(event, product) {
		event.preventDefault();
		event.stopPropagation();

		Backend.setCrawlProductHidden(product._key, !product.synlig).then(() => {
			this.setState({
				products: this.state.products.filter((p) => p._key !== product._key),
			});

			if (this.state.products.length < 20) {
				this.onScroll();
			}
		});
	}

	fetchProducts(page) {
		let params =
			`gtin=${this.state.showGtin}` +
			`&hidden=${this.state.showHidden}&name=${this.state.searchName}&only2Weeks=${this.state.only2Weeks}` +
			`&crawledIn30Days=${this.state.crawledIn30Days}` +
			`&m=${this.state.manufacturer || ''}
			&s=${this.state.store || ''}
			&ns=${this.state.notStore || ''}
			&nc=${this.state.notCats || ''}
			`;

		window.history.replaceState(null, null, `/products/unmatched?${params}`);

		if (this.state.loading) {
			return;
		}
		this.setState({
			loading: true,
		});
		const search = _.debounce(() => {
			console.log('search ' + this.state.searchName);
			const promise = Backend.getUnmatchedProducts(
				page || 1,
				this.state.showGtin,
				this.state.store,
				this.state.notStore,
				this.state.notCats,
				this.state.manufacturer,
				true,
				this.state.showHidden,
				this.state.only2Weeks,
				this.state.searchName,
				this.state.crawledIn30Days,
			);

			promise.then((result) => {
				let products = !page ? [] : this.state.products;
				products = [...products, ...result.items];

				products = products.map((product) => {
					product.hide = (
						<Button
							variant="outlined"
							onClick={(e) => this.onHideCrawlProduct(e, product)}
						>
							{product.synlig ? 'Dölj' : 'Visa'}
						</Button>
					);

					product.currentPrisSpindlingDatum = product.currentPrisSpindlingDatum.substring(0, 10);
					product.Created = product.Created.substring(0, 10);

					const butik = this.state.butiker.find((b) => b._key == product.butikKey);
					if (butik) {
						product.butikNamn = butik.namn;
					}
					return product;
				});
				this.setState({
					loading: false,
					page: page || 1,
					products,
					totalSearchItems: result.totalItemCount,
				});
			});
		}, 1000);
		search();
	}

	setStore(stores) {
		const store = stores[stores.length - 1] || '';
		this.setState(
			{
				store,
			},
			() => {
				this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
			},
		);
	}

	setManufacturer(manufacturer) {
		this.setState(
			{
				manufacturer,
			},
			() => {
				this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
			},
		);
	}

	setShowGtin(checked) {
		this.setState(
			{
				showGtin: checked,
			},
			() => {
				this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
			},
		);
	}

	setCrawledIn30Days(checked) {
		this.setState(
			{
				crawledIn30Days: checked,
			},
			() => {
				this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
			},
		);
	}

	setOnly2Weeks(checked) {
		this.setState(
			{
				only2Weeks: checked,
			},
			() => {
				this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
			},
		);
	}

	onSearchFieldUpdated(event, text) {
		this.setState(
			{
				searchName: text,
			},
			() => {
				this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
			},
		);
	}

	setHidden(checked) {
		this.setState(
			{
				showHidden: checked,
			},
			() => {
				this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
			},
		);
	}

	onClick(product) {
		this.redirect(`/products/unmatched/${product._key}`);
	}

	onScroll() {
		this.debounce(this.fetchProducts.bind(this), this.debounceTime)(this.state.page + 1);
	}

	getFullCategoryName(categoryName, category, categories) {
		if (category.foralderKey) {
			const parent = categories.find((x) => x._key === category.foralderKey);
			if (parent) return this.getFullCategoryName(`${parent.namn} → ${categoryName}`, parent, categories);
		}
		return categoryName;
	}

	render() {
		const { loading, searchName } = this.state;
		const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
		const checkedIcon = <CheckBoxIcon fontSize="small" />;

		return (
			<ViewContainer
				title="Omatchade produkter"
				className={classes.unmatchedContainer}
			>
				<Box
					sx={{
						width: { sm: '100%', md: '100%', lg: '100%', xl: '100%' },
						display: 'flex',
					}}
				>
					<Box
						sx={{
							width: { sm: '100%', md: '100%', lg: '100%', xl: '100%' },
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{this.state.butiker.length > 0 && (
							<Autocomplete
								sx={{
									width: { sm: '100%', md: '70%', lg: '70%', xl: '80%' },
								}}
								id="storeFilter"
								options={this.state.butiker}
								value={this.state.store ? this.state.butiker.find((b) => b._key == this.state.store) : null}
								onChange={(val, selectedValue) => {
									this.setState(
										{
											store: selectedValue ? selectedValue._key : null,
										},
										() => {
											this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
										},
									);
								}}
								getOptionLabel={(option) => option.namn}
								isOptionEqualToValue={(option, value) => option._key === value._key}
								variant="standard"
								renderInput={(params) => (
									<TextField
										key={params._key}
										variant="standard"
										error={null}
										{...params}
										label="Filtrera på butik"
									/>
								)}
							/>
						)}
						<Autocomplete
							sx={{
								width: { sm: '100%', md: '70%', lg: '70%', xl: '80%' },
							}}
							multiple
							variant="standard"
							id="checkboxes-tags-demo"
							limitTags={2}
							options={this.state.categories.filter((c) => c.kategoriNiva === 1)}
							isOptionEqualToValue={(option, value) => option._key === value._key}
							disableCloseOnSelect
							value={this.state.notCats ? this.state.categories.filter((x) => this.state.notCats.includes(x._key)) : []}
							onChange={(event, newValue) => {
								this.setState(
									{
										notCats: newValue.map((x) => x._key),
									},
									() => {
										this.debounce(this.fetchProducts.bind(this), this.debounceTime)();
									},
								);
							}}
							renderOption={(props, option, { selected }) => (
								<li
									key={option._key}
									{...props}
								>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.namn}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									variant="standard"
									{...params}
									label="Exkludera kategorier"
									placeholder=""
								/>
							)}
						/>
					</Box>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						sx={{
							width: { sm: '100%', md: '100%', lg: '100%', xl: '100%' },
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<TextField
							sx={{
								width: { sm: '100%', md: '70%', lg: '70%', xl: '80%' },
							}}
							label="Varumärke"
							variant="standard"
							value={this.state.manufacturer}
							onChange={(e, value) => {
								this.setManufacturer(e.target.value);
							}}
							type="search"
						/>

						<TextField
							sx={{
								width: { sm: '100%', md: '70%', lg: '70%', xl: '80%' },
							}}
							label="Fritextsök"
							variant="standard"
							value={this.state.searchName}
							onChange={(e, value) => {
								this.onSearchFieldUpdated(e, e.target.value);
							}}
							type="search"
						/>
						<Box sx={{ paddingTop: '1em' }}>Totalt i söket: {this.state.totalSearchItems.toLocaleString()}</Box>
					</Box>

					<Box
						sx={{
							width: { sm: 'auto', md: 'auto', lg: 'auto', xl: '100%' },
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<FormGroup sx={{ padding: 0, width: '20em' }}>
							<FormControlLabel
								sx={{ width: '100%' }}
								label="Endast produkter med GTIN"
								control={
									<Checkbox
										onChange={(_, checked) => this.setShowGtin(checked)}
										checked={this.state.showGtin ? true : false}
									/>
								}
							/>

							<FormControlLabel
								label="Spindlad inom 30 dagar"
								control={
									<Checkbox
										onChange={(_, checked) => this.setCrawledIn30Days(checked)}
										checked={this.state.crawledIn30Days ? true : false}
									/>
								}
							/>

							<FormControlLabel
								label="Visa enbart dolda"
								control={
									<Checkbox
										onChange={(_, checked) => this.setHidden(checked)}
										checked={this.state.showHidden ? true : false}
									/>
								}
							/>

							<FormControlLabel
								label="Visa enbart nya (14 dagar)"
								control={
									<Checkbox
										onChange={(_, checked) => this.setOnly2Weeks(checked)}
										checked={this.state.only2Weeks ? true : false}
									/>
								}
							/>
						</FormGroup>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', height: '90%' }}>
					<CircularProgress
						style={this.state.loading ? { position: 'absolute', left: '50%', top: '50%' } : { display: 'none' }}
						sx={{}}
					/>
					<Box
						id="unmatchedholder"
						sx={{ height: '80%', overflow: 'auto' }}
					>
						<SortableTable
							ref={(ref) => (this.ref = ref)}
							loading={false}
							columns={this.state.columns}
							rows={this.state.products}
							onClick={this.onClick.bind(this)}
							onScroll={this.onScroll.bind(this)}
							tblType={'unmatched'}
						/>
					</Box>
				</Box>
				<ContextMenu tblType={'sidebar'} />
			</ViewContainer>
		);
	}
}

export default withRouter(ProductsUnmatched);
