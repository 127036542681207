import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	TextField,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { brandvariationsTblStyle } from '../../../TableGridCssOverride';
import BaseComponent from '../../../base';
import DataGrid from '../../../components/datagrid/DataGrid';
import Backend from '../../../services/Backend';
import './brands.module.css';

class Brands extends BaseComponent {
	title = '';
	CACHE = {};
	mounted = false; // ugly fix for mounted warning supression
	brandsCopy = [];
	state = {
		loading: false,
		brandsList: [],
		selectedRow: [],
		selectedBrandKey: [],
		distributors: [],
		distributorSelectedDisabled: false,
		currentPage: 1,
		brandsPerPage: 90,
		brandsTotalCount: 0,
		openEditBrand: false,
		openEditDistributor: false,
		snackbar: '',

		columns: [
			{
				id: 'varumarkeNamn',
				name: 'Varumärke',
				selector: (row) => {
					return (
						<span
							data-tooltip-id="my-tooltip"
							data-tooltip-content={row.varumarkeNamn}
						>
							{' '}
							{row.varumarkeNamn}{' '}
						</span>
					);
				},
				sortable: true,
				width: '30%',
			},
			{
				id: 'leverantorNamn',
				name: 'Leverantör',
				selector: (row) => row.leverantorNamn,
				sortable: true,
				width: '30%',
			},
			// {
			// 	id: 'editDistributor',
			// 	name: 'Redigera leverantör',
			// 	cell: (row) => (
			// 		<Button
			// 			size="medium"
			// 			variant="text"
			// 			onClick={() => this.editDistributor(row)}
			// 		>
			// 			Redigera leverantör
			// 		</Button>
			// 	),
			// },
			{
				id: 'editBrand',
				name: 'Redigera varumärke',
				cell: (row) => (
					<Button
						variant="text"
						onClick={() => this.editBrand(row)}
					>
						Redigera varumärke
					</Button>
				),
			},
		],
	};

	constructor(props) {
		super(props);
		this.title = props.title;
		this.handleClick = this.handleClick.bind(this);
		// this.onSelectRow = this.onSelectRow.bind(this);
		if (_.isEmpty(this.state.brandsList) && !_.isEmpty(this.props.data)) {
			this.state.brandsList = this.props.data;
			this.brandsCopy = this.state.brandsList;
		}
		if (_.isEmpty(this.state.distributors) && !_.isEmpty(this.props.distributors)) {
			this.state.distributors = this.props.distributors;
		}
	}

	componentDidMount() {
		this.mounted = true;
	}

	editDistributor(distributor) {
		this.setState({ openEditDistributor: true, selectedRow: distributor });
	}

	updateDistributorList = (updatedList) => {
		this.setState({ distributors: [this.state.distributors, ...updatedList] });
	};

	search = _.debounce((value, type) => {
		let filteredBrands = null;
		if (type === 'varumarke') {
			filteredBrands = this.state.brandsList.filter((item) => {
				return Object.keys(item).some((k) => item.varumarkeNamn.toLowerCase().includes(value.toLowerCase()));
			});
		}

		if (type === 'leverantor') {
			filteredBrands = this.state.brandsList.filter((item) => {
				return Object.keys(item).some((k) => item.leverantorNamn.toLowerCase().includes(value.toLowerCase()));
			});
		}

		if (!_.isEmpty(value) && this.mounted) {
			this.setState({ brandsList: filteredBrands });
		}
		if (_.isEmpty(value) && this.mounted) {
			this.setState({ brandsList: this.brandsCopy });
		}
	}, 500);

	onSaveBrand = () => {
		const { selectedRow } = this.state;
		const payload = {
			_key: selectedRow.varumarkeKey,
			namn: selectedRow.varumarkeNamn,
			master: true,
			MySQLOldId: 0,
		};

		Backend.updateVarumarke(payload).then((response) => {
			if (response.success) {
				// Uppdatera leverantör med
				Backend.updateVarumarkeLeverantor(selectedRow.varumarkeKey, selectedRow.leverantorKey).then((r) => {
					if (r.success) {
						this.setState({ openEditBrand: false });
					}
				});
			}
		});
	};

	onSaveDistributor = () => {
		const { selectedRow } = this.state;
		const payload = {
			_key: selectedRow.leverantorKey,
			namn: selectedRow.leverantorNamn,
			MySQLOldId: 0,
		};

		Backend.updateDistributor(payload).then(
			(response) => {
				if (response.success) {
					this.setState({ openEditBrand: false, openEditDistributor: false });
				}
			},
			() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen av leverantören',
				});
			},
		);
	};

	editBrand(selectedRow) {
		this.setState({ openEditBrand: true, selectedRow });
		// this.setState({ open: true, selectedBrand: selectedVariation, selectedVariation });

		console.log('%c⧭', 'color: #ace2e6', selectedRow);
	}

	handleClick(event) {
		this.setState({
			currentPage: Number(event.target.id),
		});
	}

	handleClose = () => {
		this.setState({ openEditBrand: false, openEditDistributor: false });
	};

	onUpdateItems = () => {
		this.setState((state) => {
			const list = state.brandsList.map((item) => {
				if (item.varumarkeKey === state.selectedRow.varumarkeKey) {
					item = { ...state.selectedRow };
				}
				return item;
			});
			state.brandsList = [...list];
		});
	};

	render() {
		const {
			brandsList,
			currentPage,
			brandsPerPage,
			selectedRow,
			distributors,
			selectedBrandKey,
			distributorSelectDisabled,
			openEditDistributor,
			openEditBrand,
			columns,
		} = this.state;
		const indexOfLastBrand = currentPage * brandsPerPage;
		const indexOfFirstBrand = indexOfLastBrand - brandsPerPage;
		const pageNumbers = [];

		// const distributorActions = [
		//   <Button label="Avbryt" primary="true" onClick={this.handleClose} />,
		//   <Button label="Spara leverantör" primary="true" disabled={_.isEmpty(selectedRow.leverantorNamn)} onClick={this.onSaveDistributor} />,
		// ];
		if (!_.isEmpty(brandsList)) {
			for (let i = 1; i <= Math.ceil(brandsList.length / brandsPerPage); i++) {
				pageNumbers.push(i);
			}
		}

		return (
			<div className="brands">
				<Dialog
					open={openEditBrand}
					onClose={this.handleClose}
					fullWidth={true}
				>
					<DialogTitle>Redigera varumärke</DialogTitle>
					<DialogContent>
						<div>
							<InputLabel id="brandname">Varumärke</InputLabel>

							<TextField
								sx={{ width: '40%' }}
								value={selectedRow.varumarkeNamn || ''}
								id="brandname"
								onChange={(e) => {
									selectedRow.varumarkeNamn = e.target.value;
									this.setState({ selectedRow });
									this.onUpdateItems();
								}}
								errortext={selectedRow.varumarkeNamn ? '' : 'Namnet får inte vara tomt'}
								variant="standard"
							/>
						</div>
						<div>
							<InputLabel id="leverantorsSelect">Leverantör</InputLabel>

							<Autocomplete
								labelId="leverantorsSelect"
								sx={{ width: '40%' }}
								options={distributors.map((option) => {
									return {
										label: option.namn,
										namn: option.namn,
										key: option.key,
									};
								})}
								onChange={(event, newValue) => {
									selectedRow.leverantorNamn = newValue && newValue.namn;
									selectedRow.leverantorKey = newValue && newValue.key;
									this.setState({ selectedRow });
								}}
								value={selectedRow.leverantorNamn}
								renderInput={(params) => (
									<TextField
										{...params}
										label=""
										variant="standard"
									/>
								)} // TODO:Fixa sparning
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>Avbryt</Button>
						<Button
							primary="true"
							variant="contained"
							disabled={_.isEmpty(selectedRow.varumarkeNamn) || _.isEmpty(selectedRow.leverantorKey)}
							onClick={this.onSaveBrand}
						>
							Spara varumärke
						</Button>{' '}
					</DialogActions>
				</Dialog>

				<Dialog
					open={openEditDistributor}
					onClose={this.handleClose}
					fullWidth
				>
					<DialogTitle>Redigera leverantör</DialogTitle>
					<DialogContent>
						<div>
							<TextField
								value={selectedRow.leverantorNamn || ''}
								id="distributorname"
								onChange={(evt) => {
									selectedRow.leverantorNamn = evt.target.value;
									this.setState({ selectedRow });
									this.onUpdateItems();
								}}
								label={'Leverantör'}
								errortext={selectedRow.leverantorNamn ? '' : 'Namnet får inte vara tomt'}
								variant="standard"
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>Avbryt</Button>
						<Button
							primary="true"
							variant="contained"
							disabled={_.isEmpty(selectedRow.leverantorNamn)}
							onClick={this.onSaveDistributor}
						>
							Spara leverantör
						</Button>{' '}
					</DialogActions>
				</Dialog>

				<DataGrid
					columns={columns}
					data={this.state.brandsList}
					rowHeight={53}
					tableHeight={'70vh'}
					defaultSortFieldId={'varumarkeNamn'}
					sortDirection={'asc'}
					loading={this.state.loading}
					filterKey={['varumarkeNamn', 'leverantorNamn']}
					title={'Varumärke leverantör koppling'}
					customStyles={brandvariationsTblStyle}
				></DataGrid>
			</div>
		);
	}
}

export default Brands;
