import React from 'react';
// import FileFolder from '@mui/material/svg-icons/file/folder';
// import EditorInsertDriveFile from '@mui/material/svg-icons/editor/insert-drive-file';

import { Folder, InsertDriveFile } from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import BaseComponent from '../../base';
import LoadingContainer from '../../components/loading/LoadingContainer';
import Backend from '../../services/Backend';
// import './CategoryTree.module.css';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import classes from './CategoryTree.module.css';

const treeTheme = createTheme({
	components: {
		MuiListItemButton: {
			styleOverrides: {
				root: {
					MuiListItemButton: {
						'&.Mui-selected': {
							backgroundColor: '#d5d4d2',
							'&:hover': {
								backgroundColor: '#d5d4d2',
							},
						},
						'&:hover': {
							backgroundColor: '#d5d4d2',
						},
						'&:focus': {
							backgroundColor: '#d5d4d2',
						},
					},
					'&.Mui-selected': {
						backgroundColor: '#d5d4d2',
						'&:hover': {
							backgroundColor: '#d5d4d2',
						},
					},
					'&:hover': {
						backgroundColor: '#d5d4d2',
					},
					'&:focus': {
						backgroundColor: '#d5d4d2',
					},
				},
				dense: {
					paddingTop: 6,
					paddingBottom: 6,
				},
				divider: {
					'&:before': {
						display: 'none',
					},
				},
			},
		},
	},
});

class CategoryTree extends BaseComponent {
	state = {
		loading: true,
		open: [],
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		Backend.fetchKategoriTrad()
			.then((categories) => {
				var tree = {
					children: categories,
					id: '00000000-0000-0000-0000-000000000000',
					isVisible: false,
					namn: 'Alla Kategorier',
				};
				this.setState({
					categories: tree,
					loading: false,
				});
			})
			.catch((e) => {
				console.error(e);
			});
	}

	handleTouchTap(selected) {
		if (this.props.onChange) {
			if (selected.namn !== 'Alla Kategorier' && this.props.selectedId !== selected.id) {
				if (this.props.subscribeCategories) {
					this.props.onChange(selected);
				} else {
					console.log('%c⧭', 'color: #73998c', 'qqqq');
					this.props.onChange(selected.id);
				}
			} else {
				this.props.onChange(null);
			}
		}
	}

	isItemSelected(item, selectedId) {
		const isSelected = item.id === selectedId;
		const childSelected = item.children.some((child) => this.isItemSelected(child, selectedId));
		return isSelected || childSelected;
	}

	isChildChecked(item) {
		return item.children.some((child) =>
			this.props.subscribeCategories.some((category) => category.categoryId === child.id),
		);
	}

	handleClick = (id) => {
		const open = this.state.open;
		if (open.includes(id)) {
			let idx = open.findIndex((i) => i === id);
			open.splice(idx, 1);
		} else {
			open.push(id);
		}

		this.setState((prevState) => ({
			...prevState,
			open: open,
		}));
	};

	isOpen(id) {
		return this.state.open.includes(id);
	}

	renderListItem(child, index) {
		let open = this.state.open;

		return (
			<>
				<ListItemButton
					key={child.id}
					sx={{ pl: 4 }}
					selected={this.isItemSelected(child, this.props.selectedId) ? true : false}
					onClick={(event) => {
						this.handleClick(child.id);
						if (open.includes(child.id)) {
							this.handleTouchTap(child);
						}

						// this.handleTouchTap(child);
					}}
				>
					<ListItemIcon>{child.children.length ? <Folder /> : <InsertDriveFile />}</ListItemIcon>
					<ListItemText primary={child.namn} />
					{open.includes(child.id) ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>

				{child.children &&
					child.children.length &&
					child.children.map((grandchild, index) => {
						return (
							<Collapse
								in={open.includes(child.id)}
								timeout="auto"
								unmountOnExit
							>
								<List
									dense={true}
									key={grandchild.id}
									sx={{ pl: 4 }}
									component="div"
									disablePadding
								>
									<ListItemButton
										key={grandchild.name}
										selected={this.isItemSelected(grandchild, this.props.selectedId) ? true : false}
										onClick={(event) => {
											this.handleClick(grandchild.id);
											if (open.includes(grandchild.id)) {
												this.handleTouchTap(grandchild);
											}
											//    this.handleTouchTap(grandchild);
										}}
									>
										<ListItemIcon>{grandchild.children.length ? <Folder /> : <InsertDriveFile />}</ListItemIcon>
										<ListItemText primary={grandchild.namn} />
										{open.includes(grandchild.id) ? <ExpandLess /> : <ExpandMore />}
									</ListItemButton>
									{grandchild.children &&
										grandchild.children.length > 0 &&
										grandchild.children.map((grandGrandchild, index) => {
											return (
												<Collapse
													in={open.includes(grandchild.id)}
													timeout="auto"
													unmountOnExit
												>
													<List
														dense={true}
														key={grandGrandchild.id}
														sx={{ pl: 4 }}
														component="div"
														disablePadding
													>
														<ListItemButton
															key={grandGrandchild.name}
															selected={this.isItemSelected(grandGrandchild, this.props.selectedId) ? true : false}
															onClick={(event) => {
																this.handleTouchTap(grandGrandchild);
															}}
														>
															<ListItemIcon>
																{grandGrandchild.children.length ? <Folder /> : <InsertDriveFile />}
															</ListItemIcon>
															<ListItemText primary={grandGrandchild.namn} />
														</ListItemButton>
													</List>
												</Collapse>
											);
										})}
								</List>
							</Collapse>
						);
					})}
			</>
		);
	}

	render() {
		const categories = this.state.categories;
		return (
			<LoadingContainer enabled={this.state.loading}>
				{categories && (
					<ThemeProvider theme={treeTheme}>
						<List
							dense={true}
							component="nav"
							className={classes.categoryTree}
						>
							<ListItemButton key={categories.id}>
								<ListItemIcon>{categories.children.length > 0 ? <Folder /> : <InsertDriveFile />}</ListItemIcon>
								<ListItemText primary={categories.namn} />
							</ListItemButton>
							{categories.children &&
								categories.children.length &&
								categories.children.map((child, index) => {
									return this.renderListItem(child, index);
								})}
						</List>
					</ThemeProvider>
				)}
			</LoadingContainer>
		);
	}
}

export default CategoryTree;
