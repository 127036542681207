import React from 'react';

import { ArrowDownward, ArrowUpward, SwapVert } from '@mui/icons-material';
import { Box, CircularProgress, List, ListItem, ListItemButton, ListSubheader } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Backend from '../../services/Backend';
import Utils from '../../services/Utils';
import './ResultList.module.css';
import classes from './ResultList.module.css';

import ContextMenu from '../contextmenu/ContextMenu';

class ResultList extends React.Component {
	static scrollTriggerPercentage = 0.3;
	sortOrderEnum = { none: 0, asc: 1, desc: 2 };

	constructor(props) {
		super(props);
		this.state = {
			sortProductsEnabled: !!this.props.sortProductsEnabled,
			sortOrder: this.sortOrderEnum.none,
			directSource: this.props.directSource,
		};
	}

	componentDidMount = () => {
		window.addEventListener('scroll', this.props.handleScrollToBottom, true);
	};

	onScrollEvent() {
		if (this.ref && this.props.handleScrollToBottom) {
			const elementHeight = this.ref.innerHeight || this.ref.offsetHeight;
			const scrollTopTrigger = this.ref.scrollTop + elementHeight * ResultList.scrollTriggerPercentage;
			if (scrollTopTrigger >= this.ref.scrollHeight - elementHeight) {
				this.props.handleScrollToBottom();
			}
		}
	}

	handleProductSelected(product) {
		this.props.handleProductSelected(product);
	}

	handleProductImageSelected(product) {
		if (this.props.handleProductImageSelected) {
			this.props.handleProductImageSelected(product);
		} else {
			this.props.handleProductSelected(product);
		}
	}

	renderProgress(visible) {
		if (visible) {
			return (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<CircularProgress className={classes.progress} />
				</Box>
			);
		}
		return null;
	}

	getSortIcon() {
		switch (this.state.sortOrder) {
			case this.sortOrderEnum.none:
				return <SwapVert />;
			case this.sortOrderEnum.asc:
				return <ArrowUpward />;
			case this.sortOrderEnum.desc:
				return <ArrowDownward />;
		}
	}

	renderSubHeader() {
		if (this.props.subheaderVisible) {
			return (
				<ListSubheader>
					{this.state.sortProductsEnabled ? (
						<Box
							className={classes.tableHeader}
							style={{ cursor: 'default' }}
							onClick={this.setNextSortOrder.bind(this)}
						>
							{'Sortera efter namn'}
							{this.getSortIcon()}
						</Box>
					) : (
						'Sökresultat'
					)}
				</ListSubheader>
			);
		}
		return null;
	}

	setNextSortOrder() {
		const getNextSortOrder = (previousSortOrder) => {
			switch (previousSortOrder) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 0;
			}
		};

		this.setState({
			sortOrder: getNextSortOrder(this.state.sortOrder),
		});
	}

	renderSecondaryText(product) {
		const produkt = product.Produkt;
		const amountPerUnit = produkt.mangd / produkt.antal;
		const volume = Utils.formatProductVolume(amountPerUnit, produkt.enhet);
		const infos = [];
		if (produkt.antal > 1 && produkt.enhet !== 3) {
			infos.push(`${produkt.antal} x ${volume}`);
		} else {
			infos.push(volume);
		}
		if (produkt.gtin) {
			infos.push(produkt.gtin);
		}
		if (produkt.extra && produkt.extra.length > 0) {
			// not sure if this is a thing?
			infos.push(produkt.extra);
		}

		return (
			<span>
				{infos.join(' - ')} <br />
				{!product.SenastSpindlad ? 'Äldre än 4 månader' : product.SenastSpindlad.substring(0, 10)}
				<span style={{ display: 'none' }}>{produkt._key}</span>
			</span>
		);
	}

	getSortedProducts() {
		const products = (this.props.products || []).slice();

		switch (this.state.sortOrder) {
			case this.sortOrderEnum.none:
				return products;
			case this.sortOrderEnum.asc:
				return products.sort((a, b) => a.Produkt.namn.localeCompare(b.name, 'sv-SE'));
			case this.sortOrderEnum.desc:
				return products.sort((a, b) => b.Produkt.namn.localeCompare(a.name, 'sv-SE'));
		}
	}

	secondLineRows(product) {
		let lines = 2;
		if (product.Produkt.extra) {
			lines++;
		}

		// seems as 2 is max?
		return Math.min(2, lines);
	}

	render() {
		const products = this.getSortedProducts();
		const progressVisible = this.props.progressVisible;
		const emptyStateText = this.props.emptyStateText;
		const BASE_URL = Backend.getBaseURL();
		const max = this.props.maxHeight || '';
		const imgSize = this.props.imgSize || 50;
		let selectedIndex = this.props.selectedIndex || null;

		return (
			<Box
				ref={(node) => {
					this.ref = node;
				}}
				onScroll={this.onScrollEvent.bind(this)}
			>
				<List
					id="resultlist"
					sx={{ maxHeight: max }}
				>
					{this.renderSubHeader()}

					{!products.length && <Box className={classes.noResults}>{emptyStateText}</Box>}

					{this.renderProgress(progressVisible)}

					{products.map((product, index) => (
						<ListItemButton
							selected={selectedIndex === index}
							onClick={(event) => {
								selectedIndex = index;
								this.handleProductSelected(products[index]);
							}}
							key={product.Produkt._key}
						>
							<ListItem
								alignItems="center"
								id={product.Produkt._key}
								data-tip
								data-for={product.Produkt._key}
								data-produkt-typ={product.Produkt.produktTyp}
								style={product.Produkt.synlig === false ? { backgroundColor: '#ffb9c2' } : {}}
								value={index}
							>
								<Box
									className={classes.productImageHolder}
									onClick={(evt) => {
										evt.stopPropagation();
										selectedIndex = index;
										this.handleProductImageSelected(products[index]);
									}}
								>
									<img
										className={classes.productImage}
										src={
											(product.Produkt.produktBildThumbnail && product.Produkt.produktBildThumbnail.url) ||
											product.Produkt.bildUrl ||
											''
										}
									/>
								</Box>

								{/* <ListItemAvatar
									className={classes.avatarHolder}
									sx={{
										width: '100px',
										height: '100px',
									}}
								>
									<Avatar
										sizes="100px"
										variant="square"
										onError={(e) => {
											e.target.src =
												'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
										}}
										src={
											(product.Produkt.produktBildThumbnail && product.Produkt.produktBildThumbnail.url) ||
											product.Produkt.bildUrl ||
											''
										}
									/>
								</ListItemAvatar> */}

								<ListItemText
									className={classes.productSearchText}
									primary={product.Produkt.namn}
									sx={{
										display: 'flex',
										padding: 0,
										flexDirection: 'column',
									}}
									secondary={
										<React.Fragment>
											{this.renderSecondaryText(product)} <br />
											{product.Varumarken && product.Varumarken.length > 0 ? product.Varumarken[0].namn : ''}
										</React.Fragment>
									}
								/>
							</ListItem>
						</ListItemButton>
					))}
				</List>
				<ContextMenu />
			</Box>
		);
	}
}

export default ResultList;
