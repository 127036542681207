import React from 'react';
import Backend from '../../services/Backend';
import { Autocomplete, TextField } from '@mui/material';

class StoresChip extends React.Component {
	state = {
		stores: [],
		selectedStores: this.props.selectedStores || [],
	};

	componentDidMount() {
		this.setStores();
	}

	setStores() {
		if (this.props.stores) {
			this.setState({ stores: this.props.stores });
			return;
		}

		this.getStores().then((stores) => {
			console.log('stores ship req!');
			let res = stores;
			if (this.props.onlyPrisanalys) {
				res = res.filter((s) => s.synligPrisanalys);
			}

			var dupeCount = {};
			res.forEach((x) => {
				if (!dupeCount[x.namn]) dupeCount[x.namn] = 0;
				dupeCount[x.namn]++;
			});

			res.forEach((x) => {
				if (dupeCount[x.namn] > 1) x.namn = `${x.namn} (${x.externId || x._key})`;
			});

			this.setState({
				stores: res.sort((a, b) => (a.name > b.name ? 1 : -1)),
				selectedStores: this.props.selectedStores || [],
			});
		});
	}

	//   componentDidUpdate(prevProps) {

	// console.log('%c⧭', 'color: #997326', this.props);

	//     // if(this.props.selectedStores.length > 0){
	//     //   this.setState({selectedStores: this.props.selectedStores})
	//     // }
	//     // else{
	//     //   this.setState({selectedStores: []})
	//     // }
	//     // if ((prevProps.stores.length || 0) !== (this.props.stores.length || 0)) {
	//       //   this.setStores();
	//       // }

	//     //   if(prevProps.stores != undefined || this.props.stores != undefined && prevProps.stores !== this.props.stores){
	//     //   console.log('%c⧭', 'color: #d0bfff', this.props.stores);
	//     //   this.setStores();
	//     // }
	//   }

	getStores() {
		if (!this.props.stores) {
			return Backend.fetchButiker().then((stores) => {
				return stores;
			});
		}

		return new Promise((resolve, reject) => {
			resolve(this.props.stores);
		});
	}

	focus() {
		if (this.refs.searchChip) {
			this.refs.searchChip.focus();
		}
	}

	render() {
		return (
			<Autocomplete
				label={this.props.label || 'Sök på butik'}
				// onChange={this.props.onChange}
				onChange={(event, value) => {
					this.props.onChange(value);
					this.setState((prevState) => {
						return { selectedStores: value };
					});
				}}
				value={this.state.selectedStores || []}
				options={this.state.stores}
				getOptionLabel={(option) => option.namn}
				multiple
				limitTags={5}
				fullWidth={this.props.fullWidth || false}
				ChipProps={{ color: 'primary', size: 'small' }}
				getLimitTagsText={(more) => `+${more} till`}
				renderInput={(params) => (
					<TextField
						{...params}
						size="small"
						label={'Välj butik(er)'}
						error={this.state.selectedStores === undefined || this.state.selectedStores.length == 0 ? true : false}
					/>
				)}
			/>
		);
	}
}

export default StoresChip;
