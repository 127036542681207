import { Edit } from '@mui/icons-material';
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Snackbar, TextField, Typography } from '@mui/material';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { combinedTblStyle } from '../../../TableGridCssOverride';
import ContextMenu from '../../../components/contextmenu/ContextMenu';
import DataGrid from '../../../components/datagrid/DataGrid';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
import classes from './ProductCombineList.module.css';

export default function ProductCombineList(props) {
	const browserHistory = createBrowserHistory();
	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [bags, setBags] = useState([]);
	const [filterBags, setFilterBags] = useState([]);
	const [message, setMessage] = useState('');
	const [filterOnlyPublicProducts, setFilterOnlyPublicProducts] = useState(false);

	const noBagItem = { key: '0', name: '<Ingen>' };

	useEffect(() => {
		Backend.fetchCombinedProductsList().then(setProducts);
	}, []);

	useEffect(() => {
		setLoading(products.length == 0);

		const bagsBase = {};
		products.forEach((x) =>
			x.Produktsamlingar.forEach((ps) => (bagsBase[ps.key] = { key: ps.key, name: `${ps.namn} (${ps.klientNamn})` })),
		);
		setBags([noBagItem, ...Object.values(bagsBase)]);
	}, [products]);

	useEffect(() => {
		let filteredProducts = products;
		if (filterOnlyPublicProducts) {
			filteredProducts = filteredProducts.filter((x) => x.Produkt.publikKombinerad);
		}
		if (filterBags.length > 0) {
			const noBags = filterBags.some((x) => x.key === noBagItem.key);
			filteredProducts = filteredProducts.filter((x) =>
				noBags
					? x.Produktsamlingar.length === 0
					: x.Produktsamlingar.some((y) => filterBags.some((z) => z.key == y.key)),
			);
		}
		setFilteredProducts(filteredProducts);
	}, [filterBags, filterOnlyPublicProducts, products]);

	const redirect = (uri) => {
		browserHistory.push(uri, { from: 'redirect' });
		browserHistory.go();
	};

	const getCategoryNameAtLevel = (row, level) => {
		var k = row.Produkt.kategorier.filter((x) => x.kategoriNiva == level);
		return k.length > 0 && k[0].namn;
	};

	const getProduktsamlingar = (row) =>
		(row.Produktsamlingar && row.Produktsamlingar.map((x) => `${x.namn} (${x.klientNamn})`)) || [];

	const columns = [
		{
			id: 'produktsamlingar',
			name: 'Produktsamlingar',
			selector: (row) => row.Produktsamlingar,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					<ul className={classes.bagList}>
						{getProduktsamlingar(row).map((r) => (
							<li>{r}</li>
						))}
					</ul>
				</Box>
			),
			sortable: true,
			wrap: true,
		},
		{
			id: 'img',
			name: 'Bild',
			selector: (row) => row.Produkt.produktBildThumbnail && row.Produkt.produktBildThumbnail.url,
			format: (row) => (
				<Box
					component={row.Produkt.produktBildThumbnail && 'img'}
					sx={{ width: '3em', height: '3em', objectFit: 'contain' }}
					src={row.Produkt.produktBildThumbnail && row.Produkt.produktBildThumbnail.url}
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				/>
			),
			maxWidth: '3em',
		},
		{
			id: 'namn',
			name: 'Namn',
			selector: (row) => row.Produkt.namn,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					{row.Produkt.namn}
				</Box>
			),
			sortable: true,
			wrap: true,
		},
		{
			id: 'produktinfo',
			name: 'Info',
			selector: (row) => row.Produkt.produktinfo,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					{row.Produkt.produktinfo}
				</Box>
			),
			sortable: true,
			wrap: true,
		},
		{
			id: 'jmfpris',
			name: 'Jmfpris',
			selector: (row) => row.Produkt.visaSomJmfPris,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					<Checkbox
						size="small"
						checked={row.Produkt.visaSomJmfPris}
						onChange={(event) => {
							const checked = event.target.checked;
							row.Produkt.visaSomJmfPris = checked;
							Backend.putProductProperty(row.Produkt._key, 'VisaSomJmfPris', checked).then((res) => {
								setMessage(res.success ? 'Produkten uppdaterad' : 'Ett fel uppstod: ' + res.message);
							});
						}}
					/>
				</Box>
			),
			maxWidth: '3em',
		},
		{
			id: 'synlig',
			name: 'Synlig',
			selector: (row) => row.Produkt.synlig,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					<Checkbox
						size="small"
						checked={row.Produkt.synlig}
						disabled={true}
					/>
				</Box>
			),
			sortable: true,
			wrap: true,
			maxWidth: '3em',
		},
		{
			id: 'publik',
			name: 'Publik',
			selector: (row) => row.Produkt.publikKombinerad,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					<Checkbox
						size="small"
						checked={row.Produkt.publikKombinerad}
						disabled={true}
					/>
				</Box>
			),
			sortable: true,
			wrap: true,
			maxWidth: '3em',
		},
		{
			id: 'key',
			name: 'Key',
			selector: (row) => row.Produkt._key,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					{row.Produkt._key}
				</Box>
			),
			wrap: true,
			minWidth: '15em',
		},
		{
			id: 'kategorier',
			name: 'Produktgrupp',
			selector: (row) => row.Produkt.kategorier,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					{getCategoryNameAtLevel(row, 3)}
				</Box>
			),
			sortable: true,
			wrap: true,
		},
		{
			id: 'missingprices',
			name: 'Saknade priser',
			selector: (row) => row.Produkt.missingPriceInStores,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					{row.Produkt.missingPriceInStores}
				</Box>
			),
			sortable: true,
		},
		{
			id: 'updated',
			name: 'Uppdaterad',
			selector: (row) => row.Produkt.Updated,
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					{moment(row.Produkt.Updated).format('YYYY-MM-DD')}
				</Box>
			),
			sortable: true,
		},
		{
			id: 'edit',
			cell: (row) => (
				<Box
					data-for={row.Produkt._key}
					data-produkt-typ={row.Produkt.produktTyp}
				>
					<Button
						size="small"
						variant="text"
						onClick={() => redirect(`/products/combine/${row.Produkt._key}`)}
					>
						<Box sx={{ display: 'flex', gap: '0.1em', alignItems: 'center' }}>
							<Edit sx={{ height: '0.7em' }} />
							<Box sx={{ marginTop: '0.2em' }}>Editera</Box>
						</Box>
					</Button>
				</Box>
			),
			width: '120px',
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	return (
		<ViewContainer
			title="Kombinerade produkter"
			className={classes.viewContainer}
		>
			<Box className={classes.header}>
				<Box>
					<Typography variant="h5">{`Kombinerade produkter (${filteredProducts.length}/${products.length})`}</Typography>
				</Box>
				<Box className="buttons">
					<Button
						variant="contained"
						primary="true"
						onClick={() => redirect(`/products/combine`)}
					>
						Skapa ny
					</Button>
				</Box>
			</Box>
			<Box className={classes.subheader}>
				<Autocomplete
					sx={{ width: '40%' }}
					label={'Filter på produktsamling'}
					onChange={(event, value) => {
						setFilterBags(value.some((x) => x.key === noBagItem.key) ? [noBagItem] : value);
					}}
					value={filterBags || []}
					options={bags}
					getOptionLabel={(option) => option.name}
					isOptionEqualToValue={(o, v) => o.key == v.key}
					multiple
					limitTags={5}
					fullWidth={true}
					ChipProps={{ color: 'primary', size: 'small' }}
					getLimitTagsText={(more) => `+${more} till`}
					renderInput={(params) => (
						<TextField
							{...params}
							size="small"
							label={'Filter på produktsamling'}
						/>
					)}
				/>
			</Box>
			<Box className={classes.subheader}>
				<FormControlLabel
					control={
						<Checkbox
							onChange={() => setFilterOnlyPublicProducts(!filterOnlyPublicProducts)}
							checked={filterOnlyPublicProducts}
						/>
					}
					label="Endast publika"
				/>
			</Box>
			<Box id="kombined-data-table">
				<DataGrid
					title={''}
					columns={columns}
					data={filteredProducts}
					loading={loading}
					customStyles={combinedTblStyle}
					filterKey={['Produkt.namn']}
					showPagination={false}
					tableHeight={'75vh'}
				/>
			</Box>
			<ContextMenu tblType={'sidebar'} />
			<Snackbar
				open={!!message}
				message={message}
				autoHideDuration={3000}
				onClose={() => setMessage('')}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>
		</ViewContainer>
	);
}
