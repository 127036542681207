import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import React from 'react';

import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
// import './clientEdit.module.css';
import { useParams } from 'react-router-dom';
import classes from './clientEdit.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class ClientEdit extends BaseComponent {
	constructor(props) {
		super(props);
		if (this.state.newClient) {
			setTimeout(() => {
				this.setState({
					loading: false,
					client: {
						info: '',
						namn: '',
					},
					title: 'Skapa ny klient',
				});
			}, 100);
		} else {
			Backend.fetchClient(props.params.id).then((client) => {
				// console.log(client);
				this.setState({
					loading: false,
					client,
					title: 'Ändra klient',
				});
			});
		}
	}

	state = {
		client: {},
		loading: true,
		snackbar: '',
		newClient: this.props.params.id === 'new',
		deletesnackbar: '',
		deleteClientId: null,
	};

	onSave() {
		const client = this.state.client;
		const data = client._key
			? client
			: {
					namn: client.namn,
					info: client.info,
			  };

		Backend.saveClient(data).then(
			() => {
				this.redirect('/productmonitoring/clients');
			},
			() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid sparningen av klienten',
				});
			},
		);
	}

	onDelete(client) {
		this.setState({
			deletesnackbar: `Är du säker på att du vill ta bort ${client.namn}?`,
			deleteClientId: client._key,
		});
	}

	onConfirmDeleteClick() {
		// eslint-disable-next-line
		Backend.deleteClient(this.state.deleteClientId).then(
			() => {
				this.redirect('/productmonitoring/clients');
			},
			() => {
				this.setState({
					snackbar: 'Ett fel uppstod vid borttagningen av klienten',
				});
			},
		);
	}

	onCancelDeleteClick() {
		this.setState({ deletesnackbar: '', deleteClientId: null });
	}

	setProperty(key, value) {
		const client = this.state.client;
		client[key] = value;
		this.setState({ client });
	}

	render() {
		const client = this.state.client;
		return (
			<ViewContainer
				title={this.state.title}
				className={classes.clientEditContainer}
			>
				<LoadingContainer
					className={classes.top}
					enabled={this.state.loading}
				>
					<div className={classes.fields}>
						<TextField
							value={client.namn || ''}
							onChange={(evt) => this.setProperty('namn', evt.target.value)}
							label={'Namn'}
							error={!client.namn}
						/>
						<TextField
							value={client.info || ''}
							onChange={(evt) => this.setProperty('info', evt.target.value)}
							label={'Info'}
							error={!client.info}
						/>
					</div>
				</LoadingContainer>

				<Snackbar
					open={!!this.state.snackbar}
					message={this.state.snackbar}
					autoHideDuration={3000}
					onClose={() => this.setState({ snackbar: '' })}
				/>
				<Dialog
					open={!!this.state.deletesnackbar}
					onClose={() => this.setState({ deletesnackbar: '' })}
				>
					<DialogTitle>{this.state.deletesnackbar}</DialogTitle>
					<DialogActions>
						<Button
							primary="true"
							onClick={() => this.onCancelDeleteClick()}
						>
							Avbryt
						</Button>
						<Button
							variant="contained"
							onClick={() => this.onConfirmDeleteClick()}
						>
							Ta bort
						</Button>
					</DialogActions>
				</Dialog>
				<div className="buttons">
					<Button
						sx={{ marginRight: '1em;' }}
						variant="outlined"
						onClick={this.back.bind(this)}
					>
						Avbryt
					</Button>
					<Button
						sx={{ marginRight: '1em;' }}
						variant="outlined"
						onClick={() => this.onDelete(client)}
						disabled={this.state.newClient}
					>
						Ta bort
					</Button>
					<Button
						variant="contained"
						primary="true"
						onClick={this.onSave.bind(this)}
						disabled={!client.info || !client.namn}
					>
						Spara
					</Button>
				</div>
			</ViewContainer>
		);
	}
}

export default withRouter(ClientEdit);
