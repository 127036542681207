import { UploadFile } from '@mui/icons-material';
import CategoryIcon from '@mui/icons-material/Category';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FactoryIcon from '@mui/icons-material/Factory';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import MergeIcon from '@mui/icons-material/Merge';
import PeopleIcon from '@mui/icons-material/People';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import StoreIcon from '@mui/icons-material/Store';
import UpdateIcon from '@mui/icons-material/Update';
import {
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { withAuth } from 'oidc-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import BaseComponent from '../../base';
import classes from './Sidenav.module.css';

const MyNavLink = React.forwardRef((props, ref) => (
	<NavLink
		ref={ref}
		{...props}
		className={({ isActive }) => `${props.className} ${isActive ? props.activeclassname : ''}`}
		end
	/>
));
const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	background: '#c20016',
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

class SidebarContent extends BaseComponent {
	state = {
		open: true,
		contextMenu: null,
		isContextMenuOpen: false,
	};

	open(url) {
		this.redirect(url);
	}

	openInNewTab(url) {
		window.open(url, '_blank');
	}

	logout() {
		this.props.signOutRedirect();
		sessionStorage.removeItem('TokenKeyForAuthorization');
	}

	handleContextMenu = (event) => {
		event.preventDefault();

		this.setState({
			contextMenu: this.state.contextMenu
				? null
				: {
						mouseX: event.clientX - 2,
						mouseY: event.clientY - 4,
						navurl: event.target.parentNode.attributes['data-navurl'].value,
						name: event.target.textContent.toLowerCase(),
				  },
		});
	};

	// handleClose = () => {
	// 	// setContextMenu(null);
	// 	this.setState({ contextMenu: null });
	// };

	handleDrawerOpen = () => {
		this.setState({ open: true });
	};

	handleDrawerClose = () => {
		this.setState({ open: false });
	};

	render() {
		// const DrawerHeader = styled('div')(({ theme }) => ({
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	background: '#c20016',

		// 	padding: theme.spacing(0, 1),
		// 	// necessary for content to be below app bar
		// 	...theme.mixins.toolbar,

		// 	justifyContent: 'flex-end',
		// }));

		const open = this.state.open;
		const drawerWidth = 260;

		let title = '';
		const host = window.location.hostname;
		if (host === 'localhost') {
			title = 'local';
		} else if (host === 'pricesad.test.matpriskollen.io') {
			title = 'dev';
		}

		return (
			<Box sx={{ display: 'flex' }}>
				<AppBar
					position="fixed"
					open={open}
					elevation={0}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={() => {
								if (this.props.onChanged) {
									this.props.onChanged(true);
								}
								this.setState({ open: true });
							}}
							edge="start"
							sx={{ mr: 2, ...(open && { display: 'none' }) }}
						>
							<MenuIcon />
						</IconButton>

						<Box sx={{}}>
							<Typography
								variant="h6"
								noWrap
								component="div"
							>
								{this.state.pagename}
							</Typography>
						</Box>
						<Box
							sx={{
								position: 'fixed',
								color: 'black',
								zIndex: 99999,
								padding: '10px',
								right: 0,
							}}
						>
							<Button
								variant="contained"
								color="warning"
								onClick={() => this.logout()}
							>
								Logga ut
							</Button>
						</Box>
					</Toolbar>
				</AppBar>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: drawerWidth,
							boxSizing: 'border-box',
						},
					}}
					variant="persistent"
					anchor="left"
					open={open}
				>
					<DrawerHeader>
						<Box sx={{ width: '100%', textAlign: 'center', color: 'white' }}>
							<Typography
								variant="h6"
								noWrap
								component="div"
							>
								{title}
							</Typography>
						</Box>
						<IconButton
							onClick={() => {
								if (this.props.onChanged) {
									this.props.onChanged(false);
								}
								this.setState({ open: false });
							}}
						>
							{open ? <ChevronLeftIcon sx={{ color: 'white' }} /> : <ChevronRightIcon sx={{ color: 'white' }} />}
						</IconButton>
					</DrawerHeader>
					<Divider />
					<List
						id="statistik"
						onContextMenu={(e) => this.handleContextMenu(e)}
					>
						<ListSubheader>Statistik</ListSubheader>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<FormatListNumberedRtlIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/">Spindlade produkter</ListItemText>
						</ListItem>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/crawls"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<UpdateIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/crawls">Körningar</ListItemText>
						</ListItem>
					</List>
					<Divider />
					<List onContextMenu={(e) => this.handleContextMenu(e)}>
						<ListSubheader>Produkter</ListSubheader>
						<ListItem
							key={'search'}
							component={MyNavLink}
							to="/products/search"
							id="search"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<SearchIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/products/search">Sök</ListItemText>
						</ListItem>
						<ListItem
							key={'unmatched'}
							className={classes.navItem}
							component={MyNavLink}
							to="/products/unmatched"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<HelpOutlineIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/products/unmatched">Omatchade</ListItemText>
						</ListItem>

						<ListItem
							key={'duplicates'}
							className={classes.navItem}
							component={MyNavLink}
							to="/products/duplicates"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<MergeIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/products/duplicates">Matcha dubbletter</ListItemText>
						</ListItem>
						<ListItem
							key={'combinelist'}
							className={classes.navItem}
							component={MyNavLink}
							to="/products/combinelist"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<CategoryIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/products/combinelist">Kombinerade produkter</ListItemText>
						</ListItem>
						<ListItem
							key={'potentialduplicates'}
							className={classes.navItem}
							component={MyNavLink}
							to="/products/potentialduplicates"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<ContentCopyIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/products/potentialduplicates">Potentiella duplikat</ListItemText>
						</ListItem>
						<ListItem
							key={'unverifiedproducts'}
							className={classes.navItem}
							component={MyNavLink}
							to="/products/unverifiedproducts"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<RemoveDoneIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/products/unverifiedproducts">Overifierade</ListItemText>
						</ListItem>
					</List>
					<Divider />
					<List onContextMenu={(e) => this.handleContextMenu(e)}>
						<ListSubheader>Produktbevakning</ListSubheader>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/productmonitoring/clients"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<PeopleIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/productmonitoring/clients">Klienter</ListItemText>
						</ListItem>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/productmonitoring/productbags"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<ShoppingBagIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/productmonitoring/productbags">Produktsamlingar</ListItemText>
						</ListItem>
					</List>
					<Divider />
					<List onContextMenu={(e) => this.handleContextMenu(e)}>
						<ListSubheader>Butiker och kedjor</ListSubheader>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/stores"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<StoreIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/stores">Butiker</ListItemText>
						</ListItem>
					</List>
					<Divider />
					<List onContextMenu={(e) => this.handleContextMenu(e)}>
						<ListSubheader>Kategorier</ListSubheader>
						<ListItem
							ref={this.categoryRef}
							className={classes.navItem}
							component={MyNavLink}
							to="/categories"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<FormatListBulletedIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/categories">Alla</ListItemText>
						</ListItem>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/categories/mapping"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<ChecklistRtlIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/categories/mapping">Ihopkopplade</ListItemText>
						</ListItem>
					</List>
					<Divider />
					<List onContextMenu={(e) => this.handleContextMenu(e)}>
						<ListSubheader>Kundhantering</ListSubheader>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/customermanagment/brands"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<FactoryOutlinedIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/customermanagment/brands">Varumärken-Leverantörskoppling</ListItemText>
						</ListItem>
						<ListItem
							className={classes.navItem}
							component={MyNavLink}
							to="/customermanagment/brandvariations"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<FactoryIcon />
							</ListItemIcon>
							<ListItemText data-navurl="/customermanagment/brandvariations">Variationer-Varumärken</ListItemText>
						</ListItem>
					</List>
					<Divider />
					<List
						onContextMenu={(e) => this.handleContextMenu(e)}
						key={'scanning'}
					>
						<ListSubheader>Scanning</ListSubheader>
						<ListItem
							key={'fromfile'}
							className={classes.navItem}
							component={MyNavLink}
							to="/scan/fromFile"
							activeclassname={classes.active}
						>
							<ListItemIcon>
								<UploadFile />
							</ListItemIcon>
							<ListItemText data-navurl="/scan/fromFile">Scanna från fil</ListItemText>
						</ListItem>
					</List>
				</Drawer>
				<Menu
					open={this.state.contextMenu !== null}
					onClose={this.handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						this.state.contextMenu !== null
							? {
									top: this.state.contextMenu.mouseY,
									left: this.state.contextMenu.mouseX,
							  }
							: undefined
					}
				>
					<MenuItem onClick={() => this.openInNewTab(this.state.contextMenu.navurl)}>
						Öppna {this.state.contextMenu ? this.state.contextMenu.name : ''} i ny flik
					</MenuItem>
				</Menu>
			</Box>
		);
	}
}

export default withAuth(SidebarContent);
