import { Button, Dialog, TextField } from '@mui/material';
import React from 'react';
import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
import BrandVariations from './BrandVariations';
// import './BrandVariationList.module.css';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import _ from 'lodash';
import classes from './BrandVariationList.module.css';
class BrandVariationList extends BaseComponent {
	jj = 0;
	state = {
		loading: false,
		variationList: [],
		selectedVariation: [],
		distributorList: [],
		brandsMasterList: [],
		selectedBrand: [],
		selectedDistributor: '',
		selectedDistributorKey: [],
		snackbar: '',
	};

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.mounted = true;
		if (_.isEmpty(this.state.variationList) && this.mounted) {
			this.fetchBrandVariations();
		}
		if (_.isEmpty(this.state.brandsMasterList) && this.mounted) {
			this.fetchMasterBrandList();
		}
		if (_.isEmpty(this.state.distributorList) && this.mounted) {
			this.fetchAllDistributors();
		}
	}

	fetchMasterBrandList = async () => {
		Backend.fetchMasterBrandList().then((response) => {
			this.setState({
				loading: false,
				brandsMasterList: response.sort((a, b) => (a.namn > b.namn ? 1 : b.namn > a.namn ? -1 : 0)),
			});
		});
	};

	fetchBrandVariations = async () => {
		Backend.fetchVariationerWithVarumarken().then((variations) => {
			this.setState({
				loading: false,
				variationList: variations.sort((a, b) =>
					a.varumarkeVariantNamn > b.varumarkeVariantNamn
						? 1
						: b.varumarkeVariantNamn > a.varumarkeVariantNamn
						? -1
						: 0,
				),
			});
		});
	};

	fetchAllDistributors = async () => {
		Backend.fetchDistributors().then((distributors) => {
			this.setState({
				distributorList: distributors.sort((a, b) => (a.namn > b.namn ? 1 : b.namn > a.namn ? -1 : 0)),
				loading: false,
			});
		});
	};

	save = _.debounce(() => {
		const { selectedBrand } = this.state;
		const payload = {
			varumarke: {
				namn: selectedBrand.varumarkeNamn,
				granskatLeverantor: false,
				master: true,
			},
			leverantorKey: '2df89f7d-834c-4a49-b440-e36e8add7832', // alltid Övriga så man inte kan skapa VM utan leverantör just nu
		};

		Backend.createNewVarumarke(payload).then(
			() => {
				this.setState({ open: false });
				this.fetchMasterBrandList();
			},
			() => {
				this.setState({
					open: true,
					snackbar: 'Ett fel uppstod vid sparningen av varumärmket',
				});
			},
		);
	});

	selectBrand(brand) {
		this.setState({ selectedBrand: brand, modalTitle: 'Redigera varumärke' });
	}

	onNewClicked() {
		this.setState({ open: true, modalTitle: 'Skapa varumärke' });
	}

	setProperty(target, key, value) {
		target[key] = value;
		this.setState({ brand: target });
	}

	handleClose = () => {
		this.setState({ open: false });
	};

	handleSave = () => {
		this.save();
	};

	render() {
		const { variationList, brandsMasterList, selectedBrand } = this.state;
		return (
			<ViewContainer
				title="Variationer"
				className="padding"
			>
				<div className="buttonRight">
					<Button
						variant="contained"
						onClick={this.onNewClicked.bind(this)}
					>
						Skapa varumärke
					</Button>
				</div>
				{this.state.open ? (
					<Dialog
						open={this.state.open}
						onClose={this.handleClose}
					>
						<DialogTitle>Skapa nytt varumärke</DialogTitle>
						<DialogContent>
							<div>
								<TextField
									variant="standard"
									value={selectedBrand.varumarkeNamn || ''}
									id="brandname"
									onChange={(evt) => this.setProperty(selectedBrand, 'varumarkeNamn', evt.target.value)}
									label={'Varumärke'}
									errortext={selectedBrand.varumarkeNamn ? '' : 'Namnet får inte vara tomt'}
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose}>Cancel</Button>
							<Button
								variant="contained"
								disabled={_.isEmpty(selectedBrand.varumarkeNamn)}
								onClick={this.handleSave}
							>
								Spara
							</Button>
						</DialogActions>
					</Dialog>
				) : null}
				<LoadingContainer
					enabled={this.state.loading}
					className={classes.variationListHolder}
				>
					{!_.isEmpty(variationList) && !_.isEmpty(brandsMasterList) ? (
						<BrandVariations
							title="Variationer"
							variations={variationList}
							brands={brandsMasterList}
							onSelectVariation={this.selectBrand}
						/>
					) : null}
				</LoadingContainer>
			</ViewContainer>
		);
	}
}

export default BrandVariationList;
