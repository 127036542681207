import React from 'react';
import Button from '@mui/material/Button';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import './SortButtons.module.css';
import { SwapVert } from '@mui/icons-material';
import BaseComponent from '../../base';
class SortButtons extends BaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			sortProperties: this.props.sortProperties,
			sortOrder: 0,
			currentSortProperty: -1,
		};
	}

	getButtonIcon(index) {
		if (this.state.currentSortProperty === index) {
			if (this.state.sortOrder === 0) {
				return <ArrowDownwardIcon />;
			}
			if (this.state.sortOrder === 1) {
				return <ArrowUpwardIcon />;
			}
		}

		return <SwapVert />;
	}

	handleClick(index) {
		var newSortProperty = this.state.currentSortProperty;
		var newSortOrder = this.state.sortOrder;

		if (this.state.currentSortProperty !== index) {
			newSortProperty = index;
			newSortOrder = 0;
		} else if (this.state.sortOrder === 1) {
			newSortProperty = -1;
			newSortOrder = 0;
		} else {
			newSortOrder++;
		}

		this.setState({
			currentSortProperty: newSortProperty,
			sortOrder: newSortOrder,
		});

		if (this.props.onChange) {
			this.props.onChange({
				currentSortProperty: newSortProperty,
				sortOrder: newSortOrder,
			});
		}
	}

	render() {
		return (
			<div>
				{this.props.sortProperties.map((text, index) => (
					<Button
						sx={{ minWidth: '88px', color: 'black', fontSize: '12px' }}
						size="small"
						variant="text"
						key={index}
						className={'sortButton'}
						onClick={() => {
							this.handleClick.bind(this)(index);
						}}
					>
						{text} {this.getButtonIcon(index)}
					</Button>
				))}
			</div>
		);
	}
}

export default SortButtons;
