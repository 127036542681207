import React from 'react';

import Button from '@mui/material/Button';
import { useParams } from 'react-router';
import BaseComponent from '../../../base';
import DataGrid from '../../../components/datagrid/DataGrid';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import Backend from '../../../services/Backend';
import Utils from '../../../services/Utils';
import ViewContainer from '../../../view';
// import './PotentialDuplicates.module.css';

import { potentialDuplicatesTblStyle } from '../../../TableGridCssOverride';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class PotentialDuplicates extends BaseComponent {
	constructor(props) {
		super(props);
	}

	state = {
		loading: true,
		duplicates: [],
		currentPage: 1,
		itemsPerPage: 100,
		columns: [
			{
				id: '_key',
				sortable: true,
				name: 'Key',
				wrap: true,
				minWidth: '10%',
				maxWidth: '20%',
				grow: 1,
				selector: (row) => row._key,
			},
			{
				id: 'Created',
				sortable: true,
				name: 'Created',
				wrap: true,
				minWidth: '10%',
				maxWidth: '20%',
				grow: 1,
				selector: (row) => row.Created.substring(0, 10),
			},
			{
				id: 'gtin',
				sortable: true,
				name: 'GTIN',
				responseive: false,
				selector: (row) => row.gtin,
				minWidth: '10%',
				maxWidth: '15%',
			},
			{
				id: 'ptype',
				sortable: true,
				name: 'ProduktTyp',
				responseive: false,
				selector: (row) => row.produktTyp || 1,
				maxWidth: '15%',
			},
			{
				id: 'namn',
				sortable: true,
				name: 'Namn',
				responseive: false,
				selector: (row) => row.namn,
				grow: 1,
				wrap: true,
				minWidth: '15%',
				maxWidth: '20%',
			},
			{
				id: 'varumarke',
				sortable: true,
				name: 'Varumärke',
				minWidth: '10%',
				maxWidth: '15%',
				responseive: false,
				selector: (row) => row.varumarke,
			},
			{
				id: 'mangd',
				sortable: true,
				name: 'Mängd',
				minWidth: '7%',
				maxWidth: '8%',
				grow: 1,
				responseive: false,
				selector: (row) => Utils.formatProductVolume(row.mangd),
			},
			{
				id: 'enhet',
				sortable: true,
				name: 'Enhet',
				minWidth: '7%',
				maxWidth: '8%',
				grow: 1,
				resposive: false,
				selector: (row) => Utils.getUnitName(row.enhet),
			},
			{
				id: 'produktinfo',
				sortable: true,
				name: 'Produktinfo',
				wrap: true,
				resposive: false,
				selector: (row) => row.produktinfo,
				minWidth: '15%',
				maxWidth: '20%',
				grow: 1,
			},
			{
				cell: (row) => (
					<Button
						size="small"
						variant="text"
						onClick={(e) => this.onNotADuplicate(e, row)}
					>
						⤫ Ej Duplikat
					</Button>
				),
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
				minWidth: '5%',
			},
			{
				cell: (row) => (
					<Button
						size="small"
						variant="text"
						onClick={(e) => this.onMatch(e, row)}
					>
						✓ Matcha
					</Button>
				),
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
				minWidth: '5%',
			},
			//  { id: 'matchButton', name: 'Matcha', responsive: false, selector: (row) => row.matchButton }
		],
	};

	async componentDidMount() {
		await this.getData();
	}

	async getData() {
		Backend.fetchPotentialDuplicates().then((duplicatesBase) => {
			const duplicates = [];
			let useAltStyle = false;
			// flatten
			duplicatesBase.forEach((l) => {
				const dupeKeys = l.map((p) => p._key);
				l.forEach((p) => {
					p.rowColor = useAltStyle ? 'rgba(0, 0, 0, 0.00)' : 'rgba(0, 0, 0, 0.08)';
					p.dupeKeys = dupeKeys;
					duplicates.push(p);
				});
				useAltStyle = !useAltStyle;
			});

			this.setState({
				loading: false,
				duplicates,
			});
		});
	}

	onClick(produkt) {
		const type = produkt.produktTyp;
		const key = produkt._key;
		if (type == 2) {
			this.redirect(`/products/duplicates/${key}`);
		} else if (type == 3) {
			this.redirect(`/products/combine/${key}`);
		} else {
			this.redirect(`/products/${key}`);
		}
	}

	onMatch(e, produkt) {
		const dupeKeys = produkt.dupeKeys;
		let query = `?children=${dupeKeys[0]}`;
		dupeKeys.shift();
		dupeKeys.forEach((k) => {
			query += `&children=${k}`;
		});
		this.redirect(`/products/duplicates${query}`);
	}

	onNotADuplicate(e, produkt) {
		const dupeKeys = produkt.dupeKeys;
		const duplicates = this.state.duplicates.filter((p) => !dupeKeys.includes(p._key));
		if (duplicates.length > 0) {
			let useAltStyle = false;
			let prevKeys = duplicates[0].dupeKeys;
			duplicates.forEach((p) => {
				if (prevKeys !== p.dupeKeys) {
					useAltStyle = !useAltStyle;
				}
				prevKeys = p.dupeKeys;
				p.rowColor = useAltStyle ? 'rgba(0, 0, 0, 0.00)' : 'rgba(0, 0, 0, 0.08)';
			});
		}

		Backend.setNotADuplicateProducts(dupeKeys).then(() => {
			this.setState({
				duplicates,
			});
		});
	}

	handlePageClick(event) {
		this.setState({
			currentPage: event,
		});
	}

	render() {
		const conditionalRowStyles = [
			{
				when: (row) => row.rowColor,
				style: (row) => {
					return {
						backgroundColor: row.rowColor,
					};
				},
			},
		];

		const pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(this.state.duplicates && this.state.duplicates.length / this.state.itemsPerPage);
			i++
		) {
			pageNumbers.push(i);
		}
		return (
			<ViewContainer title="Potentiella duplikat">
				<LoadingContainer enabled={this.state.loading}>
					<div style={{ display: 'flex', width: '100%' }} />
					<DataGrid
						columns={this.state.columns}
						data={this.state.duplicates}
						onClick={(p) => this.onClick(p)}
						defaultSortFieldId="dupeKeys"
						customStyles={potentialDuplicatesTblStyle}
						showPagination={true}
						Height="80vh"
						conditionalRowStyles={conditionalRowStyles}
					></DataGrid>
				</LoadingContainer>
			</ViewContainer>
		);
	}
}

export default withRouter(PotentialDuplicates);
