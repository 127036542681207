import TextField from '@mui/material/TextField';
import React from 'react';

export default function FilterComponent({ filterText, onFilter, onClear }) {
	return (
		<TextField
			type="search"
			placeholder="Sök..."
			id="variation-search-input"
			variant="standard"
			onChange={onFilter}
		/>
	);
}
