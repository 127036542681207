import React from 'react';

import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import SortableTable from '../../../components/sortabletable/SortableTable';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';

import { useParams } from 'react-router-dom';
import './ProductSources.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class ProductSources extends BaseComponent {
	constructor(props) {
		super(props);
		this.fetchCrawlProducts(props.params.id);
	}

	columns = [
		{ key: '_key', name: 'Key' },
		{ key: 'store', name: 'Butik' },
		{ key: 'namn', name: 'Namn', resposive: false },
		{ key: 'produktinfo', name: 'Information', resposive: false },
		{ key: 'varumarke', name: 'Varumärke', resposive: false },
		{ key: 'categories', name: 'Kategorier' },
		{ key: 'lastSeen', name: 'Senast spindeldatum' },
		{
			name: 'Explore',
			button: true,
			onClick: (product) => {
				this.onClick(product);
			},
			text: 'Show',
		},
	];

	state = {
		crawlproducts: [],
		loading: true,
	};

	fetchCrawlProducts(productId) {
		Backend.fetchProductCrawlProducts(productId, true).then((crawlproducts) => {
			this.setState({
				loading: false,
				crawlproducts: crawlproducts.map((cp) => {
					const bp = cp.butikProdukt;
					bp.store = cp.butik.namn || '';
					bp.categories = cp.kategorier
						.map((c) => {
							return c.namn || '';
						})
						.join(', ');
					bp.lastSeen = cp.senastSedd ? cp.senastSedd.substring(0, 10) : 'Äldre än 4 månader';
					return bp;
				}),
			});
		});
	}

	getCategoriesMap(categories) {
		let categoriesDict = {};
		if (!categories) {
			return categoriesDict;
		}

		categories.forEach((category) => {
			categoriesDict[category.id] = category;
			categoriesDict = Object.assign(categoriesDict, this.getCategoriesMap(category.children));
		});

		return categoriesDict;
	}

	onClick(product) {
		this.redirect(`/products/unmatched/${product._key}`);
	}

	render() {
		return (
			<ViewContainer
				title="Butikprodukter"
				className="productSourcesContainer"
			>
				<LoadingContainer enabled={this.state.loading}>
					<SortableTable
						columns={this.columns}
						rows={this.state.crawlproducts}
					/>
				</LoadingContainer>
			</ViewContainer>
		);
	}
}

export default withRouter(ProductSources);
