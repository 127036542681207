import {
	Autocomplete,
	Badge,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Snackbar,
	Switch,
	TextField,
} from '@mui/material';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
// import './ProductBag.module.css';
import Grid from '@mui/material/Grid';
import { productTblStyle } from '../../../TableGridCssOverride';
import DataGrid from '../../../components/datagrid/DataGrid';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import SchedulerSettings from '../../../components/scheduler-settings/SchedulerSettings';
import StoresChip from '../../../components/stores/StoresChip';
import classes from './ProductBag.module.css';
import ProductSearch from './ProductSearch';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

const WrappedInputField = (props) => {
	const [value, setValue] = useState(props.value);

	const onChange = (e) => setValue(e.target.value);
	const onBlur = (e) => props.onBlur && props.onBlur(value);

	return (
		<TextField
			variant="standard"
			size="small"
			sx={{ width: 'auto' }}
			hiddenLabel
			onChange={onChange}
			onBlur={onBlur}
			value={value}
		/>
	);
};

class ProductBag extends BaseComponent {
	constructor(props) {
		super(props);
		const isCopyMode = window.location.href.endsWith('/copy');

		this.state = {
			productBag: {
				_key: props.params.id,
				clientId: '',
				name: '',
				exporteras: false,
				exportSchema: 0,
				description: '',
				isPublic: false,
			},
			copyBag: isCopyMode,
			allCategories: [],
			allProducts: [],
			allProductsFromStart: null,
			allClients: [],
			filteredProducts: [],
			showProducts: [],
			numbersOfProductsToShow: 60,
			loading: true,
			showArtIdInput: false,
			sortProperties: ['categoryNames', 'subCategorynames', 'namn'],
			columns: [
				{
					id: 'kategori',
					name: 'Kategori',
					selector: (row) => row.categoryNames,
					sortable: true,
					grow: 1,
					wrap: true,
				},
				{
					name: 'Underkategori',
					selector: (row) => row.subCategorynames,
					sortable: true,
					id: 'underkategori',
					grow: 1,
					wrap: true,
				},
				{
					id: 'produktgrupp',
					name: 'Produktgrupp',
					selector: (row) => row.productGroup,
					sortable: true,
					grow: 1,
					wrap: true,
				},
				{
					id: 'namn',
					name: 'Namn',
					selector: (row) => row.namn,
					sortable: true,
					grow: 2,
					wrap: true,
				},
				{
					id: 'information',
					name: 'Information',
					selector: (row) => row.produktinfo,
					sortable: true,
					grow: 2,
					wrap: true,
				},
				{
					id: 'varumarke',
					name: 'Varumärke',
					selector: (row) => row.manufacturer,
					sortable: true,
					grow: 1,
					wrap: true,
				},
				{
					id: 'mangd',
					name: 'Mängd',
					selector: (row) => row.mangd,
					sortable: true,
					grow: 1,
				},
				{
					id: 'gtin',
					name: 'GTIN',
					selector: (row) => row.gtin,
					sortable: true,
					grow: 1,
					width: '170px',
				},
				{
					id: 'referens',
					name: 'Referens',
					selector: (row) => row.referens,
					sortable: true,
					grow: 0,

					cell: (row, index, column, id) => {
						return (
							<WrappedInputField
								onBlur={(e) => this.onProductReferenceUpdated(e, row)}
								value={row.referens}
							/>
						);
					},

					ignoreRowClick: true,
					allowOverflow: false,
					button: false,
				},
				{
					id: 'crawldate',
					name: 'Senast spindeldatum',
					selector: (row) => row.lastCrawled,
					sortable: true,
					format: (val) => moment(val.lastCrawled).format('YYYY-MM-DD'),
				},
			],

			snackbar: '',
			deleteSnackbar: '',
			filterName: '',
			stores: [],
			selectedStores: [],
			title: isCopyMode ? 'Kopiera produktsamling' : 'Editera produktsamling',
		};

		Backend.fetchButiker()
			.then((stores) => {
				let res = stores;
				if (this.props.onlyPrisanalys) {
					res = res.filter((s) => s.synligPrisanalys);
				}

				var dupeCount = {};
				res.forEach((x) => {
					if (!dupeCount[x.namn]) dupeCount[x.namn] = 0;
					dupeCount[x.namn]++;
				});

				res.forEach((x) => {
					if (dupeCount[x.namn] > 1) x.namn = `${x.namn} (${x.externId || x._key})`;
				});
				res = res.sort((a, b) => (a.name > b.name ? 1 : -1));
				return res;
			})
			.then((stores) => {
				Backend.fetchProductbagStores(this.state.productBag._key)
					.then((bagStores) => {
						let s = [];
						bagStores.forEach((store) => {
							s.push(stores.find((x) => x._key === store.butik._key));
						});

						this.setState({
							stores,
							selectedStores: s,
							//	loading: false,
						});
					})
					.catch(() =>
						this.setState({
							snackbar: 'Gick inte att hämta butiker i samlingen.',
						}),
					);
			});

		Backend.fetchProductBagWithKlient(this.state.productBag._key)
			.then((productBag) => {
				const pb = productBag.produktsamling;
				const k = productBag.klient;

				this.setState({
					productBag: pb,
					klientKey: k._key,
					klient: { id: k._key, label: k.namn },
				});
			})
			.catch(() =>
				this.setState({
					snackbar: 'Gick inte att hämta infomration om samlingen.',
				}),
			);

		Backend.fetchProductBagProducts(this.state.productBag._key)
			.then((products) => {
				const productsWithCategories = products.map((product) => {
					const p = product.produkt;
					if (product.kategorier) {
						const cat = product.kategorier.find((k) => k.kategoriNiva == 1);
						p.categoryNames = cat ? cat.namn : '';
						const subCat = product.kategorier.find((k) => k.kategoriNiva == 2);
						p.subCategorynames = subCat ? subCat.namn : '';
						const pg = product.kategorier.find((k) => k.kategoriNiva == 3);
						p.productGroup = pg ? pg.namn : '';
					}
					if (product.varumarke) {
						p.manufacturer = product.varumarke.namn;
					}
					p.lastCrawled = product.senastSedd;
					p.referens = product.referens;
					return this.setProductCategories(p, []);
				});
				this.sortAllProducts(productsWithCategories);
			})
			.catch(() =>
				this.setState({
					snackbar: 'Gick inte att hämta produkterna till samlingen.',
				}),
			);

		Backend.fetchClients()
			.then((data) => {
				data.sort((a, b) => a.namn.localeCompare(b.namn));
				this.setState({
					allClients: data.map((x) => ({
						id: x._key,
						label: x.namn,
					})),
				});
			})
			.catch(() =>
				this.setState({
					snackbar: 'Gick inte att hämta klienter.',
					loading: false,
				}),
			);
	}

	setProductCategories(product, categories) {
		return product;
	}

	sortAllProducts(allProducts) {
		const sortedAllProducts = allProducts;
		let sortedFilteredProducts = sortedAllProducts;
		let allProductsFromStart = this.state.allProductsFromStart;
		if (this.state.allProductsFromStart === null && this.state.copyBag) {
			allProductsFromStart = sortedAllProducts;
		}
		if (this.state.filterName) {
			sortedFilteredProducts = this.getFilteredProducts(this.state.filterName, allProducts);
		}
		const showProducts = sortedFilteredProducts;
		// const showProducts = sortedFilteredProducts.slice(0, this.state.numbersOfProductsToShow);
		this.setState({
			allProducts: sortedAllProducts,
			allProductsFromStart: allProductsFromStart,
			filteredProducts: sortedFilteredProducts,
			showProducts,
			loading: false,
		});
	}

	addProductToBag(product) {
		const alreadyThere = this.state.allProducts.some((item) => item._key === product._key);
		if (!alreadyThere) {
			const produkt = product.Produkt;
			const productWithCategories = this.setProductCategories(produkt, this.state.allCategories);
			const newProductList = [...this.state.allProducts, productWithCategories];
			this.setState({
				allProducts: newProductList,
			});
			this.sortAllProducts(newProductList);
		}
	}

	removeProductFromBag(product) {
		this.onDelete(`Är du säker på att du vill ta bort ${product.namn} ur samlingen?`);
		this.setState({
			removeProduct: product,
		});
	}

	onBagInfoUpdated(event, type) {
		if (type === 'client') {
			this.setState({
				klientKey: event && event.id,
				klient: event,
			});
		} else if (type === 'store') {
			if (event.length > 0) {
				this.setState({
					selectedStores: event,
				});
			}
		} else {
			this.setState({
				productBag: {
					...this.state.productBag,
					[type]: event.target.value,
				},
			});
		}
	}

	onProductReferenceUpdated(value, row) {
		const newRowReferens = this.state.showProducts.map((p) => (p._key === row._key ? { ...p, referens: value } : p));
		this.setState({ showProducts: newRowReferens });
	}

	onSave() {
		this.setLoading(true);
		const saveObj = cloneDeep(this.state.productBag);
		saveObj.klientKey = this.state.klientKey;

		const productIds = this.state.showProducts.map((product) => ({
			key: product._key,
			referens: product.referens,
			// statisk: product.statisk,
		}));

		const stores = [];

		this.state.selectedStores.forEach((store) => {
			stores.push(store._key);
		});

		if (this.state.copyBag) {
			delete saveObj._key;
		}

		Backend.saveProductBag(saveObj)
			.then((newBag) => {
				if (!saveObj._key) {
					saveObj._key = newBag._key;
				}
				Backend.setProductbagStores(saveObj._key, stores).then((r) => {
					// empty function
				});
				Backend.saveProductBagProducts(saveObj._key, productIds)
					.then(() => {
						if (this.state.copyBag) {
							this.redirect(`/productmonitoring/productbags/${saveObj._key}`);
							window.location.reload(true);
						} else {
							setTimeout(() => {
								this.setLoading(false);
							}, 100);
						}
					})
					.catch(() => {
						this.setState({
							snackbar: 'Gick inte att spara produkterna i samlingen.',
						});
						setTimeout(() => {
							this.setLoading(false);
						}, 100);
					});
			})
			.catch(() => {
				this.setState({
					snackbar: 'Gick inte att spara informationen om samlingen.',
				});
			});
	}

	setLoading(value) {
		this.setState({ loading: value });
	}

	onCancelDeleteClick() {
		this.setState({ deleteSnackbar: '' });
	}
	selectedStores = (stores) => {
		this.setState((prevState) => {
			return { selectedStores: stores };
		});
	};

	onConfirmDeleteClick() {
		this.setState({ deleteSnackbar: '' });
		this.setLoading(true);
		if (this.state.removeProduct) {
			const newAllProducts = this.state.allProducts.filter((item) => item._key !== this.state.removeProduct._key);
			this.sortAllProducts(newAllProducts);
			this.setLoading(false);
			this.setState({ removeProduct: '' });
		} else {
			Backend.deleteProductBag(this.state.productBag._key)
				.then(() => this.redirect('/productmonitoring/productbags'))
				.catch(() => {
					this.setState({
						snackbar: 'Det gick inte att radera produktsamlingen.',
					});
					this.setLoading(false);
				});
		}
	}

	handleExportToSqlChange = (event) => {
		//  this.setState({ exportToSql: event.target.checked });
		this.setState((prevState) => {
			return {
				productBag: {
					...prevState.productBag,
					exporteras: event.target.checked,
				},
			};
		});
	};

	handleScheduleTypeChange = (value) => {
		this.setState((prevState) => {
			return {
				productBag: { ...prevState.productBag, exportSchema: value },
			};
		});
	};

	onDelete(text) {
		this.setState({
			deleteSnackbar: text,
		});
	}

	productsNotChangedIfCopyBag() {
		if (this.state.copyBag) {
			return isEqual(this.state.allProducts, this.state.allProductsFromStart);
		}
		return false;
	}
	render() {
		const { productBag } = this.state;
		productTblStyle.table.style.height = '70vh';
		return (
			<ViewContainer title={this.state.title}>
				<LoadingContainer
					enabled={this.state.loading}
					className={classes.productBagContainer}
				>
					<Box className={classes.top}>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={4}
							>
								<TextField
									size="small"
									label="Namn"
									fullWidth
									InputLabelProps={{ shrink: true }}
									value={this.state.productBag.namn}
									onChange={(e) => this.onBagInfoUpdated(e, 'namn')}
								/>
							</Grid>
							<Grid
								item
								xs={4}
							>
								<TextField
									size="small"
									fullWidth
									label="Beskrivning"
									InputLabelProps={{ shrink: true }}
									value={this.state.productBag.info}
									onChange={(e) => this.onBagInfoUpdated(e, 'info')}
								/>
							</Grid>
							<Grid
								item
								xs={4}
							>
								{this.state.allClients && this.state.klient && (
									<Autocomplete
										id="combo-box-clients"
										disableClearable={true}
										value={this.state.klient}
										options={this.state.allClients}
										disabled={!this.state.copyBag}
										size="small"
										onChange={(_, item) => {
											this.onBagInfoUpdated(item, 'client');
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Klient"
											/>
										)}
									/>
								)}
							</Grid>
							<Grid
								item
								xs={7}
							>
								<Box sx={{ width: '100%' }}>
									{this.state.stores.length > 0 && (
										<StoresChip
											fullWidth
											label="Valda butiker"
											onChange={(_, item) => this.onBagInfoUpdated(_, 'store')}
											stores={this.state.stores}
											selectedStores={this.state.selectedStores}
										></StoresChip>
									)}
								</Box>
							</Grid>
							<Grid
								item
								xs={3}
							>
								<Box>
									<SchedulerSettings
										value={productBag.exportSchema}
										onChange={this.handleScheduleTypeChange}
									></SchedulerSettings>
								</Box>
							</Grid>
							<Grid
								item
								xs={2}
							>
								<Box>
									<FormControl>
										<FormControlLabel
											label="Exportera till SQL"
											control={
												<Switch
													checked={this.state.productBag.exporteras}
													onChange={this.handleExportToSqlChange}
												/>
											}
										/>
									</FormControl>
								</Box>
							</Grid>
						</Grid>

						<Box sx={{ display: 'flex', width: '100%', height: '90%' }}>
							<DataGrid
								defaultKey={'_key'}
								columns={this.state.columns}
								onChange={this.onProductReferenceUpdated}
								data={this.state.showProducts}
								defaultRowsPerPage={this.state.showProducts.length}
								onClick={(product) => this.removeProductFromBag(product)}
								defaultSortFieldId={'namn'}
								sortDirection={'asc'}
								loading={this.state.loading}
								customStyles={productTblStyle}
								filterKey={['gtin', 'namn']}
							></DataGrid>

							<Box className={classes.searchForProductBag}>
								<ProductSearch
									allProducts={this.state.allProducts}
									addProductToBag={(product) => this.addProductToBag(product)}
									location={this.props.location}
									includeNonPublicCombined={true}
								/>
							</Box>
						</Box>
					</Box>

					<Snackbar
						open={!!this.state.snackbar}
						message={this.state.snackbar}
						autoHideDuration={3000}
						onClose={() => this.setState({ snackbar: '' })}
					/>

					<Dialog
						open={!!this.state.deleteSnackbar}
						onClose={() => this.setState({ deleteSnackbar: '' })}
					>
						<DialogTitle>{this.state.deleteSnackbar}</DialogTitle>
						<DialogActions>
							<Button
								primary="true"
								onClick={() => this.onCancelDeleteClick()}
							>
								Avbryt
							</Button>
							<Button
								variant="contained"
								onClick={() => this.onConfirmDeleteClick()}
							>
								Ta bort
							</Button>
						</DialogActions>
					</Dialog>
				</LoadingContainer>
				<Box className="buttons">
					<Button
						variant="outlined"
						onClick={() => this.redirect('/productmonitoring/productbags')}
					>
						Avbryt
					</Button>
					{!this.state.copyBag && (
						<Button
							sx={{ marginLeft: '1em' }}
							variant="outlined"
							onClick={() => this.onDelete('Är du säker på att du vill ta bort hela produktsamlingenn?')}
						>
							Ta bort produktsamling
						</Button>
					)}
					<Button
						sx={{ marginLeft: '1em' }}
						variant="contained"
						primary="true"
						onClick={this.onSave.bind(this)}
						disabled={this.state.loading || this.productsNotChangedIfCopyBag()}
					>
						Spara
						<Badge
							sx={{ position: 'relative', top: '-1.5em', left: '0.5em' }}
							badgeContent={this.state.allProducts.length}
							max={10_000}
							size="large"
							color="info"
							primary="true"
						/>
					</Button>
				</Box>
			</ViewContainer>
		);
	}
}

export default withRouter(ProductBag);
