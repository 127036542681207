export const unitDict = {
	0: '-',
	1: 'mg',
	2: 'ml',
	3: 'st',
	4: 'mm',
};

class Utils {
	static typeDict = {
		nara: 'Nära',
		supermarket: 'Supermarket',
		kvantum: 'Kvantum',
		maxi: 'Maxi',
	};

	static unitsDisplayFormat = [
		{},
		{
			kg: 1000000,
			gram: 1000,
			mg: 1,
		},
		{
			liter: 1000,
			cl: 10,
			ml: 1,
		},
		{
			stycken: 1,
		},
		{
			meter: 1000,
			dm: 100,
			cm: 10,
			mm: 1,
		},
	];

	static getChainTypeDict() {
		return Utils.typeDict;
	}

	static getChainType(type) {
		return Utils.typeDict[type] || type;
	}

	static getDate(date) {
		const local = new Date(date);
		local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
		return local.toJSON().slice(0, 10);
	}

	static getUnitNames() {
		const result = [];
		for (const unitId in Utils.unitsDisplayFormat) {
			const unitNames = Utils.unitsDisplayFormat[unitId || 0] || {};
			for (const unitName in unitNames) {
				result.push(unitName);
			}
		}

		return result;
	}

	static getUnitName(unitId) {
		const unitNames = Utils.unitsDisplayFormat[unitId || 0] || {};
		for (const unitName in unitNames) {
			if (unitNames[unitName] === 1) {
				return unitName;
			}
		}

		return null;
	}

	static getUnit(name) {
		for (const unitId in Utils.unitsDisplayFormat) {
			const unitNames = Utils.unitsDisplayFormat[unitId || 0] || {};
			for (const unitName in unitNames) {
				if (unitName === name) {
					return [parseInt(unitId, 10), unitNames[unitName]];
				}
			}
		}

		return null;
	}

	static formatProductVolume(volume, unitId) {
		const volumeInt = parseInt(volume, 10);
		const displayFormat = Utils.unitsDisplayFormat[unitId || 0] || {};

		for (const unitName in displayFormat) {
			const unitVolume = volumeInt / displayFormat[unitName];

			if (unitVolume >= 1) {
				return `${unitVolume} ${unitName}`;
			}
		}

		return volume;
	}

	static slugify(string) {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\\-]+/g, '') // Remove all non-word characters
			.replace(/\\-\\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	}
}

export default Utils;
