import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import ResultList from '../../../components/result/ResultList';
import Backend from '../../../services/Backend';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();
	const searchParams = useSearchParams();
	return (
		<WrappedComponent
			{...props}
			params={params}
			searchParams={searchParams[0]}
		/>
	);
};

class ProductSearch extends BaseComponent {
	constructor(props) {
		super(props);
		this.debounceTime = 300;
		const allChainsOption = { id: -1, label: 'Alla' };
		this.state = {
			searchText: '',
			searchManufacturer: '',
			searchChain: 0,
			searchResult: [],
			searchPage: 1,
			progressVisible: false,
			allResultsFetched: false,
			chains: [],
			selectedChain: allChainsOption,
			sortProperties: ['categoryNames', 'subCategorynames', 'name'],
			currentSortProperty: -1,
			sortOrder: 0,
		};

		Backend.fetchBlock().then((block) => {
			this.setState({
				chains: [
					allChainsOption,
					...block.map((x) => {
						return { ...x, id: x._key, label: x.namn };
					}),
				],
			});
		});

		setTimeout(() =>
			this.searchProducts(
				props.searchParams.get('q') || '',
				props.searchParams.get('m') || '',
				props.searchParams.get('c') || 0,
				this.state.currentSortProperty,
				this.state.sortOrder,
			),
		);
	}

	onSearchFieldUpdated(event) {
		const newValue = event.target.value;
		this.setState(
			{
				searchResult: [],
				searchText: newValue,
				searchPage: 1,
			},
			() => {
				this.updateUrl();
				this.debounce(
					() =>
						this.searchProducts(
							newValue,
							this.state.searchManufacturer,
							this.state.searchChain,
							this.state.currentSortProperty,
							this.state.sortOrder,
						),
					this.debounceTime,
				)();
			},
		);
	}

	onManufacturerUpdated(event) {
		const newValue = event.target.value;
		this.setState(
			{
				searchResult: [],
				searchManufacturer: newValue,
				searchPage: 1,
			},
			() => {
				this.updateUrl();
				this.debounce(
					this.searchProducts(
						this.state.searchText,
						newValue,
						this.state.searchChain,
						this.state.currentSortProperty,
						this.state.sortOrder,
					),
					this.debounceTime,
				)();
			},
		);
	}

	onChainUpdate(newValue) {
		this.setState(
			{
				searchResult: [],
				searchChain: (newValue && newValue.id) || 0,
				selectedChain: newValue,
				searchPage: 1,
			},
			() => {
				this.updateUrl();
				this.debounce(
					() =>
						this.searchProducts(
							this.state.searchText,
							this.state.searchManufacturer,
							newValue,
							this.state.currentSortProperty,
							this.state.sortOrder,
						),
					this.debounceTime,
				)();
			},
		);
	}

	onScrollToBottom() {
		if (!this.state.allResultsFetched) {
			const newPage = this.state.searchPage + 1;
			this.debounce(
				() =>
					this.searchProducts(
						this.state.searchText,
						this.state.searchManufacturer,
						this.state.searchChain,
						this.state.currentSortProperty,
						this.state.sortOrder,
						newPage,
					),
				this.debounceTime,
			)();
		}
	}

	searchProducts(freeText, manufacturerText, chainValue = 0, newSortProperty, newSortOrder, page = 1) {
		if (this.state.progressVisible) {
			return;
		}

		if (!freeText && !manufacturerText) {
			this.setState({
				searchResult: [],
				searchPage: 1,
				progressVisible: false,
			});

			return;
		}

		const includeNonPublicCombined = this.props.includeNonPublicCombined || false;
		this.setState(
			{
				searchText: freeText,
				searchManufacturer: manufacturerText,
				searchChain: !isNaN(chainValue) ? parseInt(chainValue) : chainValue,
				progressVisible: true,
				currentSortProperty: newSortProperty,
				sortOrder: newSortOrder,
				searchResult:
					page < 2 ||
					freeText !== this.state.searchText ||
					manufacturerText !== this.state.searchManufacturer ||
					chainValue !== this.state.searchChain ||
					newSortProperty !== this.state.currentSortProperty ||
					newSortOrder !== this.state.sortOrder
						? []
						: this.state.searchResult,
			},
			() => {
				const chain = this.state.selectedChain.kod || '';
				Backend.fetchSearchResults(
					freeText,
					manufacturerText,
					null,
					null,
					chain,
					false,
					page || 1,
					newSortProperty,
					newSortOrder,
					undefined,
					undefined,
					includeNonPublicCombined,
				)
					.then((response) => {
						if (response.length === 0 && page) {
							this.setState({
								progressVisible: false,
								allResultsFetched: true,
							});
						} else {
							const searchResult = this.state.searchResult.concat(response);
							this.setState({
								searchResult,
								searchPage: page || 1,
								progressVisible: false,
								allResultsFetched: false,
							});
						}
					})
					.catch((err) => {
						this.setState({
							progressVisible: false,
						});
						console.error(err);
					});
			},
		);
	}

	updateUrl() {
		const path = `${window.location.pathname}?`;
		const query = [];
		if (this.state.searchText && this.state.searchText !== '') {
			query.push(`q=${this.state.searchText}`);
		}

		if (this.state.searchManufacturer && this.state.searchManufacturer !== '') {
			query.push(`m=${this.state.searchManufacturer}`);
		}

		if (this.state.searchChain && this.state.searchChain >= 0) {
			query.push(`c=${this.state.searchChain}`);
		}

		if (this.state.currentSortProperty >= 0) {
			query.push(`sp=${this.state.currentSortProperty}`);
			if (this.state.sortOrder > 0) {
				query.push(`so=${this.state.sortOrder}`);
			}
		}
		window.history.pushState({}, '', path + query.join('&'));
	}
	render() {
		return (
			<div className="search">
				<TextField
					label="Fritextsök"
					variant="filled"
					size="small"
					fullWidth
					value={this.state.searchText || ''}
					onChange={this.onSearchFieldUpdated.bind(this)}
				/>
				<TextField
					label="Varumärke"
					variant="filled"
					size="small"
					value={this.state.searchManufacturer || ''}
					fullWidth
					onChange={this.onManufacturerUpdated.bind(this)}
				/>
				{this.state.chains && (
					<Autocomplete
						id="combo-box-search-filter-block"
						value={this.state.selectedChain}
						options={this.state.chains}
						size="small"
						disableClearable
						sx={{ marginTop: '1em' }}
						onChange={(_, item) => this.onChainUpdate(item)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Block"
							/>
						)}
					/>
				)}
				<ResultList
					products={this.state.searchResult.filter((searchProd) =>
						this.props.allProducts.every((prod) => searchProd.Produkt._key !== prod._key),
					)}
					progressVisible={this.state.progressVisible}
					handleScrollToBottom={this.onScrollToBottom.bind(this)}
					handleProductSelected={(product) => this.props.addProductToBag(product)}
					emptyStateText="Inga resultat hittades"
					subheaderVisible
					maxHeight={'60vh'}
				/>
			</div>
		);
	}
}

export default withRouter(ProductSearch);
