import React from 'react';

import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Snackbar, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import BaseComponent from '../../../base';
import LoadingContainer from '../../../components/loading/LoadingContainer';
import SortableTable from '../../../components/sortabletable/SortableTable';
import Backend from '../../../services/Backend';
import ViewContainer from '../../../view';
// import './StoreList.module.css';
import classes from './StoreList.module.css';

const withRouter = (WrappedComponent) => (props) => {
	const params = useParams();

	return (
		<WrappedComponent
			{...props}
			params={params}
		/>
	);
};

class StoreList extends BaseComponent {
	constructor(props) {
		super(props);
		Backend.fetchStores().then((data) => {
			const chainNames = new Set();
			const stores = data.map((store) => {
				store.id = store.butik._key;
				store.name = store.butik.namn;
				store.blockName = (store.block && store.block.namn) || '';
				store.chainName = (store.kedja && store.kedja.namn) || '';
				chainNames.add(store.chainName);
				store.created = store.butik.Created.substring(0, 16).replace('T', ' ');
				store.synligPrisanalys = store.butik.synligPrisanalys;
				store.synlig = store.butik.synlig ? '✓' : '⤬';
				store.active = store.butik.hasActivePrices ? '✓' : '⤬';
				store.coords = store.butik.longitud > 0 && store.butik.latitud > 0 ? '✓' : '⤬';
				return store;
			});
			const storeNames = [];
			const options = stores
				.filter((s) => s.synligPrisanalys)
				.sort((a, b) => (a.name > b.name ? 1 : -1))
				.map((store) => {
					// for some reason the auto complete needs unique names as well as the id
					storeNames.push(store.name);
					const count = storeNames.filter((x) => x === store.name).length;
					const prefix = count > 1 ? ` (${count})` : '';
					return { label: `${store.name}${prefix}`, id: store.id };
				});
			this.setState({
				loading: false,
				stores,
				options,
				chainNames: Array.from(chainNames).map((x) => {
					return { id: x, label: x };
				}),
			});
		});
		// });
	}

	state = {
		store1: null,
		store2: null,
		snackbar: null,
		loading: true,
		loadingCompare: false,
		onlyWithPrices: false,
		stores: [],
		chainNames: [],
		filterChain: null,
		options: [],
		columns: [
			{ key: 'id', name: 'Key', resposive: false },
			{ key: 'name', name: 'Namn' },
			{ key: 'blockName', name: 'Block', resposive: false },
			{ key: 'chainName', name: 'Kedja', resposive: false },
			{ key: 'coords', name: 'Koord.' },
			{ key: 'synlig', name: 'Synlig', resposive: false },
			{ key: 'created', name: 'Skapad', resposive: false },
			{ key: 'active', name: 'Aktiva priser', resposive: false },
		],
	};

	onClick(store) {
		this.redirect(`/stores/${store.id}`);
	}

	onNewClicked() {
		this.redirect('/stores/new');
	}

	onCompareClicked() {
		this.setState(
			{
				loadingCompare: true,
			},
			() => {
				Backend.fetchCompareStores(this.state.store1, this.state.store2).then(
					(data) => {
						const domLink = document.createElement('a');
						const url = window.URL.createObjectURL(data);

						domLink.href = url;
						domLink.target = '_blank';
						domLink.download = 'Compare.xlsx';

						document.body.appendChild(domLink);
						domLink.click();

						window.URL.revokeObjectURL(url);

						this.setState({
							loadingCompare: false,
						});
					},
					() => {
						this.setState({
							loadingCompare: false,
							snackbar: 'Det gick inte att jämföra butikerna, vänlig försök igen.',
						});
					},
				);
			},
		);
	}

	renderFilter() {
		return (
			<div>
				{this.state.chainNames.length > 0 && (
					<Autocomplete
						id="combo-box-chain"
						value={this.state.filterChain}
						options={this.state.chainNames}
						sx={{ marginBottom: '1em', width: '30em' }}
						onChange={(_, item) => {
							this.setState({ filterChain: item });
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Filtrera kedja"
							/>
						)}
					/>
				)}
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.onlyWithPrices}
								onChange={(e) => {
									this.setState({
										onlyWithPrices: e.target.checked,
									});
								}}
							/>
						}
						label="Visa bara butiker med aktiva priser"
					/>
				</FormGroup>
			</div>
		);
	}

	renderCompare() {
		const options = this.state.options;
		return (
			<div className={classes.compareContainer}>
				<Autocomplete
					disablePortal
					id="combo-box-1"
					options={options}
					size="small"
					sx={{ width: 300 }}
					onChange={(_, item) => {
						this.setState({ store1: item && item.id });
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Butik 1"
						/>
					)}
				/>
				<Autocomplete
					id="combo-box-2"
					options={options}
					size="small"
					sx={{ width: 300 }}
					onChange={(_, item) => {
						this.setState({ store2: item && item.id });
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Butik 2"
						/>
					)}
				/>
				<Button
					primary="true"
					variant="outlined"
					disabled={
						this.state.loadingCompare ||
						!this.state.store1 ||
						!this.state.store2 ||
						this.state.store1 === this.state.store2
					}
					onClick={this.onCompareClicked.bind(this)}
				>
					{this.state.loadingCompare ? 'Laddar...' : 'Jämför'}
				</Button>
			</div>
		);
	}

	getFilteredStores() {
		let stores = this.state.stores;
		if (this.state.onlyWithPrices) {
			stores = stores.filter((s) => s.butik.hasActivePrices);
		}
		if (this.state.filterChain) {
			stores = stores.filter((s) => s.chainName === this.state.filterChain.id);
		}
		return stores;
	}

	render() {
		return (
			<ViewContainer title="Butiker">
				<LoadingContainer enabled={this.state.loading}>
					<div className={classes.storeList}>
						<div className={classes.actions}>
							{this.renderFilter()}
							<Button
								primary="true"
								variant="contained"
								onClick={this.onNewClicked.bind(this)}
							>
								Skapa ny
							</Button>
						</div>
					</div>
					<SortableTable
						width="100%"
						columns={this.state.columns}
						rows={this.getFilteredStores()}
						onClick={this.onClick.bind(this)}
					/>
					<Snackbar
						open={!!this.state.snackbar}
						message={this.state.snackbar || ''}
						autoHideDuration={3000}
						onClose={() => this.setState({ snackbar: '' })}
					/>
				</LoadingContainer>
			</ViewContainer>
		);
	}
}

export default withRouter(StoreList);
