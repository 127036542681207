import { SwapVert } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import equal from 'fast-deep-equal';
import React from 'react';
import { Tooltip } from 'react-tooltip';
// import './SortableTable.module.css';
import classes from './SortableTable.module.css';
// Fix for browsers which dont have localeCompare
if (typeof String.prototype.localeCompare === 'undefined') {
	String.prototype.localeCompare = (str) => {
		// eslint-disable-line no-extend-native
		return this === str ? 0 : this > str ? 1 : -1;
	};
}

class SortableTable extends React.Component {
	static scrollTriggerPercentage = 0.3;

	constructor(props) {
		super(props);

		this.tableRef = React.createRef();
		this.containerRef = React.createRef();
		this.rowRef = React.createRef();

		this.state = {
			tblType: this.props.tblType || 'none',
			sortHeader: this.props.sortBy ? this.props.sortBy : '',
			storesMap: {},
			isAsc: !!this.props.sortBy,
			columnStyle: {
				height: this.props.rowHeight || '48px',
			},
			rowStyle: {
				height: this.props.rowHeight || '48px',
			},
			rowIndex: 0,
			key: '',
			loading: this.props.loading || false,
		};
	}

	componentDidMount() {
		this.forceUpdate();
		if (this.props.sortBy) {
			this.sortByColumn(this.state.sortHeader);
		}
	}

	componentDidUpdate(prevProps) {
		if (!!this.props.sortBy && !equal(this.props.rows, prevProps.rows)) {
			this.sortByColumn(this.state.sortHeader);
		}
	}

	isColumnResponsive(column) {
		const ref = this.tableRef.current;
		if (column.resposive === false && ref && ref.tableBody) {
			const responsiveWidth = parseInt(this.props.responsiveWidth, 10) || 800;
			return ref.tableBody.clientWidth > responsiveWidth;
		}

		return true;
	}

	onScrollEvent() {
		const ref = this.containerRef.current;
		if (ref && this.props.onScroll) {
			const elementHeight = ref.innerHeight || ref.offsetHeight;
			const maxScroll = ref.scrollHeight - elementHeight;
			const scrollTopTrigger = ref.scrollTop + maxScroll * SortableTable.scrollTriggerPercentage;
			if (scrollTopTrigger >= maxScroll) {
				this.props.onScroll();
			}
		}
	}

	handleProductSelected(product) {
		this.props.handleProductSelected(product);
	}

	onClick(rowIndex) {
		if (!isNaN(rowIndex) && this.props.onClick) {
			this.props.onClick(this.props.rows[rowIndex]);
		}
	}

	renderRow(row, rowIndex) {
		return (
			<TableRow
				ref={this.rowRef}
				onClick={() => this.onClick(rowIndex)}
				key={rowIndex}
				sx={row.rowStyle || this.state.rowStyle}
				id={'tbl-row' + rowIndex}
				data-id={row._key}
			>
				{this.props.columns.map((column, i) => {
					if (!this.isColumnResponsive(column)) {
						return null;
					}
					let customStyle = this.state.columnStyle;

					if (row.rowStyle) {
						customStyle = {
							...customStyle,
							...row.rowStyle,
						};
					}
					const columnStyle = {
						...customStyle,
						width: column.width || 'auto',
						paddingLeft: column.padding || '24px',
						paddingRight: column.padding || '24px',
					};

					const cellValue = column.format ? column.format(row[column.key]) : row[column.key];

					return (
						<TableCell
							key={(rowIndex + 1) * this.props.columns.length + i}
							data-id={row._key}
						>
							{!column.click
								? !column.image &&
								  !column.button && (
										<span
											data-id={row._key}
											data-tip={cellValue}
										>
											{cellValue}
										</span>
								  )
								: ''}
							{column.button && (
								<Button
									size="small"
									variant="outlined"
									sx={
										{
											margin: '-0.6em',
										} /* minus margin just to make the rows a bit tighter*/
									}
									onClick={(e) => {
										if (column.onClick) {
											column.onClick(row);
										}
										if (column.stopPropagation) {
											e.stopPropagation();
										}
									}}
								>
									{column.text}
								</Button>
							)}
							{column.click && (
								<span
									className={classes.custom}
									onClick={(e) => {
										if (column.onClick) {
											column.onClick(row);
										}
										if (column.stopPropagation) {
											e.stopPropagation();
										}
									}}
								>
									{<span data-tip={'Redigera ' + cellValue}>{cellValue}</span>}
								</span>
							)}
							{column.image && !!cellValue && (
								<img
									className={classes.image}
									src={cellValue}
								/>
							)}
							{column.checkbox && (
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												className={classes.sortTableCheckbox}
												checked={cellValue}
												onClick={(e) => {
													if (column.onClick) {
														column.onClick(row);
														if (e.stopPropagation) {
															e.stopPropagation();
														}
													}
												}}
											/>
										}
										label={cellValue}
									/>
								</FormGroup>
							)}{' '}
						</TableCell>
					);
				})}
			</TableRow>
		);
	}

	sortByColumn(key, type) {
		let isAsc = this.state.isAsc;
		if (type === 'click') {
			isAsc = this.state.sortHeader === key ? !this.state.isAsc : true;
		}
		const rows = this.props.rows.sort((a, b) => {
			a[key] = a[key] === null ? '' : a[key];
			b[key] = b[key] === null ? '' : b[key];
			return a[key].toString().localeCompare(b[key]);
		});
		if (!isAsc) {
			rows.reverse();
		}
		this.setState({
			sortHeader: key,
			isAsc,
			rows,
		});
	}

	renderHeader() {
		return (
			<TableRow>
				{this.props.columns.map((column, index) => {
					if (!this.isColumnResponsive(column)) {
						return null;
					}
					const columnStyle = {
						...this.state.columnStyle,
						width: column.width || 'auto',
						paddingLeft: column.padding || '24px',
						paddingRight: column.padding || '24px',
					};
					return (
						<TableCell
							key={index}
							style={columnStyle}
						>
							<div className={classes.tableHeader}>
								{column.name}
								{!column.image && !!column.name && !column.unsortable && (
									<SwapVert
										id={index}
										className={classes.sortIcon}
										onMouseUp={() => this.sortByColumn(column.key, 'click')}
									/>
								)}
							</div>
						</TableCell>
					);
				})}
			</TableRow>
		);
	}

	render() {
		const tableHeight = this.props.height || '100%';
		const overflow = this.props.onScroll ? 'scrollY' : 'inherit';
		const { tblType, loading, targetKey } = this.state;

		return (
			<div
				ref={this.containerRef}
				className={classes.tableContainer}
				style={{ height: tableHeight, overflow: overflow }}
				onScroll={this.onScrollEvent.bind(this)}
			>
				<Table
					ref={this.tableRef}
					stickyHeader={true}
				>
					<TableHead>{this.renderHeader()}</TableHead>
					<TableBody
					// displayRowCheckbox={false}
					>
						{this.props.rows.map(this.renderRow.bind(this))}
					</TableBody>
				</Table>
				<Tooltip
					delayShow={500}
					clickable
					delayHide={1000}
					effect="solid"
					className={classes.tooltipHoverable}
				/>
			</div>
		);
	}
}

export default SortableTable;
