import React from 'react';

import { Box } from '@mui/material';
import styled from '@mui/material/styles/styled';
import BaseComponent from './base';
import SidebarContent from './containers/sidebar/Sidebar';

import './view.css';
const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

class ViewContainer extends BaseComponent {
	state = {
		open: true,
	};

	onSidebarChange(open) {
		this.setState({ open });
	}

	render() {
		const open = this.state.open;

		return (
			<Main open={open}>
				<Box
					className="approot"
					sx={{ display: 'flex' }}
				>
					<SidebarContent
						pageTitle={this.props.title || 'Administrationsverktyg'}
						open={open}
						onChanged={this.onSidebarChange.bind(this)}
					/>
					<Box
						// sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}

						open={open}
						className="viewcontainer"
						id="viewcontainer"
					>
						<Box className={`view ${this.props.className || ''}`}>{this.props.children}</Box>
					</Box>
				</Box>
			</Main>
		);
	}
}

export default ViewContainer;
