import React from 'react';

import './LoadingContainer.module.css';
import { CircularProgress } from '@mui/material';

class LoadingContainer extends React.Component {
	render() {
		return (
			<div className={this.props.className}>
				{!this.props.enabled && this.props.children}

				{this.props.enabled && (
					<div className={this.props.loadingClassName || 'loading'}>
						<CircularProgress className="animation" />
					</div>
				)}
			</div>
		);
	}
}

export default LoadingContainer;
